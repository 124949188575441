<template>
  <div class="bg-light">
    <b-modal
      ref="initiateFormModal"
      hide-footer
      title="Initiate Student Registration"
      centered
      lazy
      @hide="hideModal"
      size="lg"
    >
      <div>
        <b-alert show variant="light">
          Fill the below form to register a new student. This will send an email to the student for completing the
          registration by entering in complete details. Student will also be able to pay securely through the app and
          apply for sponsorships. Feel free to use this for walk-in registrations or initiating registrations for
          potential students.
        </b-alert>
      </div>
      <b-form class="col-md-12 px-0 page-section pt-0 pb-2" @submit.prevent="onSubmit">
        <div class="row">
          <b-form-group label="First Name*" label-for="firstname" label-class="form-label" class="col-md-6">
            <b-form-input
              id="firstname"
              :placeholder="$t('userMsgs.firstName')"
              v-model.trim="user.firstName"
              :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.firstName.required"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Last Name*" label-for="lastname" label-class="form-label" class="col-md-6">
            <b-form-input
              id="lastname"
              :placeholder="$t('userMsgs.lastName')"
              v-model.trim="user.lastName"
              :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.lastName.required">This field is required.</b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group label="Email Address*" label-for="email" label-class="form-label">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            type="email"
            v-model.trim="user.email"
            :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="!$v.user.email.email">
            Invalid email. Please make sure that it's in correct format i.e. test@example.com.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Phone" label-for="phone" label-class="form-label">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-model="user.phone"
            v-mask="'(###) ### - ####'"
            :state="$v.user.phone.$invalid && $v.user.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="$v.user.phone.$invalid && $v.user.$dirty">
            Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Birth Date" label-for="gradDate" label-class="form-label">
          <date-picker
            v-model="student.birthday"
            type="date"
            format="MM/DD/YYYY"
            value-type="YYYY-MM-DD"
            style="width: 100%"
            id="birthday"
            lang="en"
            placeholder="MM/DD/YYYY"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Gender" label-for="gender" label-class="form-label">
          <v-select
            id="gender"
            class="form-control v-select-custom"
            label="text"
            v-model="user.gender"
            :reduce="item => item.value"
            placeholder="Select your gender"
            :options="genderOptions"
          >
          </v-select>
        </b-form-group>

        <b-form-group label="Select Payment Plan for student*" label-for="payment_option" label-class="form-label">
          <v-select
            id="payment_option"
            class="form-control v-select-custom"
            label="text"
            v-model="payment_option"
            :reduce="item => item.value"
            placeholder="Select how would you like to pay the fees"
            :options="STD_INITIATE_REG_FORM_PAY_OPTIONS"
            :class="!$v.payment_option.required && $v.payment_option.$dirty ? 'form-control is-invalid' : ''"
            :clearable="false"
          >
          </v-select>
          <b-form-invalid-feedback :state="!$v.payment_option.required && $v.payment_option.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="waiveApplicationFee" v-model="waiveApplicationFee" name="waiveApplicationFee" switch>
            Waive Application Fee?
          </b-form-checkbox>
        </b-form-group>

        <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>add</span>
        </b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';

import { mapActions } from 'vuex';
import generator from 'generate-password';
import DatePicker from 'vue2-datepicker';
import { required, email } from 'vuelidate/lib/validators';
import vSelect from 'vue-select';
import {
  PHONE_FORMAT_REGEX,
  GENDER_OPTIONS,
  STD_REG_PAY_OPTIONS,
  STD_INITIATE_REG_FORM_PAY_OPTIONS
} from '../../../../common/constants';
import { formatToAPIDate, titleize } from '../../../../common/utils';

export default {
  props: {
    showModal: { type: Boolean, default: false },
    selectedClass: { type: Object, default: () => ({}) }
  },
  components: {
    DatePicker,
    vSelect
  },
  data() {
    return {
      isLoading: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: ''
      },
      student: {
        birthday: null
      },

      genderOptions: GENDER_OPTIONS,
      waiveApplicationFee: false,
      payment_option: STD_REG_PAY_OPTIONS.PAY_NOW,
      STD_INITIATE_REG_FORM_PAY_OPTIONS
    };
  },
  computed: {
    // ...mapGetters('auth', ['getLoggedInUser']),
  },
  validations() {
    return {
      student: {
        birthday: {}
      },
      payment_option: { required },
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: {
          isCorrectFormat(value) {
            return value === '' || PHONE_FORMAT_REGEX.test(value);
          }
        },
        gender: {}
      }
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.resetData();
        this.$v.$reset();
        this.$refs.initiateFormModal.show();
      }
    }
  },
  methods: {
    ...mapActions('student', ['initiateStudentRegistration']),
    get,
    hideModal() {
      this.$emit('close');
    },
    hideModalManual() {
      this.$refs.initiateFormModal.hide();
      this.hideModal();
    },
    resetData() {
      this.user.email = '';
      this.user.firstName = '';
      this.user.lastName = '';
      this.waiveApplicationFee = false;
      this.user.phone = '';
      this.user.gender = '';
      this.payment_option = STD_REG_PAY_OPTIONS.PAY_NOW;
      this.student.birthday = null;
      this.isLoading = false;
    },
    hideUpdateModal() {
      this.resetData();
      this.$refs.initiateFormModal.hide();
      this.$emit('closeSubmit');
    },
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            birthday: this.student.birthday ? formatToAPIDate(moment(this.student.birthday)) : null,
            waive_app_fee: this.waiveApplicationFee,
            registration: {
              program: this.selectedClass.program.id,
              class_enrolled: this.selectedClass.id,
              payment_option: this.payment_option
            },
            user: {
              email: this.user.email,
              first_name: titleize(this.user.firstName),
              last_name: titleize(this.user.lastName),
              password: generator.generate({ length: 10, numbers: true, strict: true }),
              phone: this.user.phone,
              gender: this.user.gender
            }
          };
          await this.initiateStudentRegistration({ ...data });
          setTimeout(
            () =>
              this.makeToast({
                variant: 'success',
                msg: 'Registeration Initiated Successfully!'
              }),
            250
          );
          this.hideUpdateModal();
        } catch (err) {
          if (err.response.status === 400) {
            this.makeToast({ variant: 'danger', msg: 'User with this email already exists.' });
            this.isLoading = false;
          } else {
            this.makeToast({
              variant: 'danger',
              msg: 'Cannot register in this class. Please contact administrator.'
            });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
