<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit User' : 'Add User'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('userMsgs.avatar')"
        label-for="avatar"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <user-avatar
            slot="aside"
            rounded="lg"
            size="lg"
            :isLoading="isUploading.avatar"
            :user="user"
            url-field="avatar_url"
            first-name-field="first_name"
            last-name-field="last_name"
          >
          </user-avatar>

          <image-uploader
            @image="(e) => setImage(e, 'avatar', 'images')"
            :isUploading="isUploading.avatar"
            :isRequired="false"
            :placeholder="$t('userMsgs.avatarDesc')"
          />
        </b-media>
      </b-form-group>
      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="user.first_name" required />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="user.last_name" required />
        </b-form-group>
      </div>

      <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          :placeholder="$t('authMsgs.emailAddr')"
          v-model="user.email"
          type="email"
          autocomplete="off"
          required
          :state="errorStates.userEmail"
        />
        <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('authMsgs.password')"
        label-for="password"
        label-class="form-label"
        v-if="!$route.params.id"
      >
        <b-alert variant="soft-primary px-12pt" show>
          <div class="d-flex align-items-center justify-content-between">
            <span class="text-black-100"
              >Auto-generated password: <strong>{{ password }}</strong></span
            >

            <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
            <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
              <md-icon>content_copy</md-icon>
            </a>
          </div>
        </b-alert>
      </b-form-group>

      <div class="row" v-else>
        <b-form-group :label="$t('authMsgs.password')" label-for="npass" label-class="form-label" class="col-md-6">
          <b-form-input
            id="npass"
            name="password"
            :placeholder="$t('authMsgs.password')"
            v-model="password"
            :required="!!repeatPassword"
            :type="fieldTypes.password"
            autocomplete="off"
            @focus="handlePassType"
            @blur="handlePassType"
            :state="errorStates.password"
          />
          <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('profileMsgs.repeatPassword')"
          label-for="repeatPassword"
          label-class="form-label"
          class="col-md-6"
        >
          <b-form-input
            id="repeatPassword"
            name="repeatPassword"
            :placeholder="$t('profileMsgs.repeatPassword')"
            v-model="repeatPassword"
            :required="!!password"
            :type="fieldTypes.repeatPassword"
            autocomplete="off"
            @focus="handlePassType"
            @blur="handlePassType"
            :state="errorStates.password"
          />
        </b-form-group>
      </div>

      <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="user.phone"
          v-mask="'(###) ### - ####'"
          :state="errorStates.phone"
        />
        <b-form-invalid-feedback>
          Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Gender" label-for="gender" label-class="form-label">
        <v-select
          id="gender"
          class="form-control v-select-custom"
          label="text"
          v-model="user.gender"
          :reduce="(item) => item.value"
          placeholder="Select User's Gender"
          :options="genderOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        label="Role"
        label-for="role"
        label-class="form-label"
        v-if="get(user, 'role') !== USER_ROLES.STUDENT"
      >
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            style="min-width: 185px"
            v-model="user.role"
            required
            :options="getLoggedInUser.role === USER_ROLES.SCHOOL_OWNER ? schoolUserOptions : userOptions"
            @change="fetchList"
          ></b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>

      <b-form-group
        :label="user.linked_entity_type === USER_ROLE_TYPES.SCHOOL ? 'School' : null"
        label-for="school"
        label-class="form-label"
        v-if="
          getLoggedInUser.role === USER_ROLES.SUPER_ADMIN &&
          [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(user.role)
        "
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          :label="user.linked_entity_type === USER_ROLE_TYPES.PARTNER ? 'title' : 'name'"
          v-model="user.linked_entity_id"
          :reduce="(school) => school.id"
          placeholder="Select School/Company"
          :options="allList"
          :loading="isListLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!user.linked_entity_id" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar
                v-if="option.logo_url"
                :title="true"
                title-class="bg-transparent"
                rounded
                no-link
                slot="aside"
                size="xs"
              >
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name || option.title }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name || option.title }}
          </template>
        </v-select>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '../../../components/Page.vue';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import generator from 'generate-password';
import { get } from 'lodash';
import {
  USER_ROLES,
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  PHONE_FORMAT_REGEX,
  USER_ROLE_TYPES,
} from '../../../common/constants';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    //  PageSeparator,
    UserAvatar,
    vSelect,
    FmvAvatar,
    ImageUploader,
  },
  extends: Page,
  data() {
    return {
      title: this.$route.params.id ? 'Edit User' : 'Add User',
      isLoading: false,
      isUploading: {
        avatar: false,
      },
      selectedUploadFile: {
        avatar: null,
      },
      uploadPercent: {
        avatar: 0,
      },
      uploadCancelTokenSource: {
        avatar: null,
      },
      genderOptions: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],
      user: {
        first_name: '',
        last_name: '',
        email: '',

        phone: '',
        avatar_url: '',
        gender: '',
        role: null,
        linked_entity_id: null,
        linked_entity_type: null,
        role_type: '',
      },
      userTitle: '',
      other_title: '',
      password: '',
      repeatPassword: '',
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      errorStates: {
        password: null,
        userEmail: null,
        cnaLicenseNo: null,
        cmaLicenseNo: null,
        phone: null,
      },
      passErrorCode: null,
      isListLoading: false,
      allList: [],
      isPassCopied: false,
      schoolUserOptions: [
        { value: null, text: 'Please Select', disabled: true },
        { value: 'school_admin', text: 'School Admin' },
        { value: 'school_owner', text: 'School Owner' },
      ],
      userOptions: [
        { value: null, text: 'Please Select', disabled: true },
        { value: 'super_admin', text: 'Super Admin' },
        {
          label: 'School',
          options: [
            { value: 'school_admin', text: 'School Admin' },
            { value: 'school_owner', text: 'School Owner' },
          ],
        },
      ],
      titleOptions: [
        { value: 'director', text: 'Director' },
        { value: 'advisor', text: 'Advisor' },
        { value: 'instructor', text: 'Instructor' },
        { value: 'other', text: 'Other' },
      ],
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Users', to: { name: 'manage-users' } },
        {
          text: this.$route.params.id ? 'Edit User' : 'Add User',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading || this.isProfileBgImgUploading || this.isSmallLogoUploading;
    },
  },
  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('user', [
      'getUserUploadPresignedUrl',
      'createUser',
      'updateUser',
      'getUser',
      'createEntityUser',
      'updateEntityUser',
      'getEntityUser',
    ]),
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('employer', ['getAllEmployers']),
    ...mapActions('partner', ['getActivePartners']),
    ...mapActions('organization', ['getAllOrganizations']),

    get,
    setImage(file, image, profileUrlField = 'logo_url') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, profileUrlField);
    },
    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },
    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    async fetchSchools() {
      this.isListLoading = true;

      const response = await this.getAllSchools({});
      this.allList = response.data;

      this.isListLoading = false;
    },
    async fetchPartners() {
      this.isListLoading = true;

      const response = await this.getActivePartners({});
      this.allList = response.data;

      this.isListLoading = false;
    },
    async fetchEmployers() {
      this.isListLoading = true;

      const response = await this.getAllEmployers({});
      this.allList = response.data;

      this.isListLoading = false;
    },
    async fetchOrg() {
      this.isListLoading = true;

      const response = await this.getAllOrganizations({});
      this.allList = response.data;

      this.isListLoading = false;
    },
    fetchList(value) {
      if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) return;
      this.user.linked_entity_id = null;
      if (value === USER_ROLES.SCHOOL_OWNER || value === USER_ROLES.SCHOOL_ADMIN) {
        this.fetchSchools();
        this.user.role_type = this.user.linked_entity_type = 'school';
      } else if (!value || this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) {
        this.allList = [];
        this.user.role_type = this.user.linked_entity_type = null;
      }
    },
    async uploadFile(fileType, uploadType = 'documents') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getUserUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') this.user.avatar_url = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },
    async onSubmit() {
      this.isLoading = true;

      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.errorStates.password =
        this.errorStates.userEmail =
        this.errorStates.cnaLicenseNo =
        this.errorStates.cmaLicenseNo =
        this.errorStates.phone =
          null;
      if (this.user.phone && !PHONE_FORMAT_REGEX.test(this.user.phone)) {
        this.errorStates.phone = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.password !== this.repeatPassword) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.password && this.password.length < 5) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.password && PASSWORD_ALL_NUMERIC_REGEX.test(this.password)) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else {
        try {
          const data = {
            ...this.user,
            ...(this.password && { password: this.password }),
          };
          if (this.$route.params.id) {
            if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) {
              await this.updateEntityUser({ id: this.$route.params.id, data });
            } else
              await this.updateUser({
                id: this.$route.params.id,
                data,
              });
            this.makeToast({ variant: 'success', msg: 'User Profile Updated!', data });
          } else {
            if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) {
              await this.createEntityUser(data);
            } else await this.createUser(data);
            this.makeToast({ variant: 'success', msg: 'User Added!' });
          }
          setTimeout(() => this.$router.push({ name: 'manage-users' }), 250);
        } catch (e) {
          if (e.response.status === 400) {
            this.makeToast({ variant: 'danger', msg: 'User with this email already exists.' });
          } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    if (!this.$route.params.id) {
      this.password = this.repeatPassword = generator.generate({ length: 10, numbers: true, strict: true });
    }
    if (this.$route.params.id) {
      let resp;
      if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) {
        resp = (await this.getEntityUser(this.$route.params.id)).data;
      } else resp = (await this.getUser(this.$route.params.id)).data;
      if ([USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(resp.role)) {
        await this.fetchSchools();
      }
      this.user.first_name = resp.first_name;
      this.user.last_name = resp.last_name;
      this.user.email = resp.email;
      this.user.phone = resp.phone;
      this.user.linked_entity_id = resp.linked_entity_id;
      this.user.avatar_url = resp.avatar_url;
      this.user.role = resp.role;
      this.user.gender = resp.gender;

      this.user.linked_entity_type = [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.STUDENT].includes(
        resp.role
      )
        ? 'school'
        : resp.linked_entity_type;
      this.user.role_type = resp.role_type;
      this.other_title = resp.other_title;
    }
  },
};
</script>

<style></style>
