var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass},[_c('page-header',{staticClass:"mb-16pt",attrs:{"title":"Configure Gallery Settings","container-class":null,"info-text":_vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.SCHOOL
        ? "Curate and edit the images displayed on your site's gallery."
        : ''}}),_c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0"},[(_vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN)?_c('b-form-group',{attrs:{"label":_vm.$t('schoolMsgs.school'),"label-for":"school","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"school","label":"name","reduce":function (sch) { return sch.id; },"placeholder":"Select School","options":_vm.allSchools,"loading":_vm.areSchoolsLoading},on:{"input":function($event){return _vm.prefillLogo(_vm.school)}},scopedSlots:_vm._u([{key:"search",fn:function(ref){
        var attributes = ref.attributes;
        var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.school}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,573013980),model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.school.required && _vm.$v.school.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),_c('page-separator',{attrs:{"title":"Photo Gallery Upload"}}),_c('b-form-group',{attrs:{"label":"","label-for":"review","label-class":"form-label"}},[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"id":"imageupinput","type":"file","name":"file","accept":"image/*"},on:{"change":_vm.upload}}),(
          (_vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN && _vm.school) ||
          _vm.get(_vm.getLoggedInUser, 'role') !== _vm.USER_ROLES.SUPER_ADMIN
        )?_c('b-button',{staticClass:"upload-button",staticStyle:{"width":"80px"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.uploader.click()}}},[(_vm.isPictureLoading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('span',[_vm._v("Upload")])]):_vm._e(),(_vm.photoGallery.length)?_c('div',{staticClass:"d-flex flex-wrap mt-2"},_vm._l((_vm.photoGallery),function(photo){return _c('div',{key:photo.id,staticClass:"mr-2 mb-2",staticStyle:{"position":"relative"}},[_c('b-img',{staticClass:"img-fluid",staticStyle:{"height":"13rem","width":"13rem","color":"white","border-radius":"0.5rem","border":"solid gray 0.1px !important","object-fit":"cover","min-width":"5rem","cursor":"pointer"},attrs:{"src":photo.image_url,"alt":"Logo"},on:{"click":function($event){$event.preventDefault();return _vm.openPhotoModal(photo.image_url)}}}),_c('a',{staticClass:"clickable-item",staticStyle:{"color":"#2a2a2a","position":"absolute","top":"0","right":"2px"},on:{"click":function($event){$event.preventDefault();return _vm.removeVisual(photo.id)}}},[_c('i',{staticClass:"fas fa-times-circle"})])],1)}),0):(
          (_vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN && _vm.school) ||
          _vm.get(_vm.getLoggedInUser, 'role') !== _vm.USER_ROLES.SUPER_ADMIN
        )?_c('b-card',{staticClass:"text-center mt-3"},[_c('i',[_vm._v(" There isn't any images added. Please add image if you have any. ")])]):_vm._e(),(_vm.photoGallery.length < _vm.totalCount && _vm.school)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-btn',{on:{"click":_vm.loadMore}},[_vm._v("Load more")])],1):_vm._e()],1)],1),_c('photo-modal',{attrs:{"show-modal":_vm.showModal,"photo":_vm.photoUrl},on:{"close":_vm.hideModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }