<template>
  <b-modal
    ref="reqAffiliationModal"
    hide-footer
    title="Request Partnership"
    centered
    lazy
    size="md"
    scrollable
    @hide="hideModal"
  >
    <p>
      Request affiliation with our training partner to refer their programs within your enrollment portal and earn
      commissions.
    </p>

    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Message" label-for="message" label-class="form-label">
        <b-form-textarea
          id="message"
          placeholder="Write a message to the training partner"
          :rows="10"
          required
          v-model="message"
        />
      </b-form-group>

      <div class="row">
        <div class="ml-auto" style="margin-right: 12px">
          <b-button @click.prevent="hideModalManual(true)" size="sm">Cancel</b-button>
          <b-button type="submit" variant="primary" size="sm" class="ml-2">Send</b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'ReqAffiliationModal',

  props: {
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      message: '',
      isLoading: false,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;

        this.$refs.reqAffiliationModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal(no = false) {
      this.message = '';
      this.$emit('close', no);
    },

    hideModalManual(no = false) {
      this.$refs.reqAffiliationModal.hide();
      this.hideModal(no);
    },

    async onSubmit() {
      this.$emit('onSubmit', this.message);
      this.hideModalManual();
    },
  },

  async mounted() {},
};
</script>
