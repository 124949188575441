<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Update School Status' : this.$t('schoolMsgs.addSchool')"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator :title="$t('schoolMsgs.schoolInfo')" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic School Info -->
      <b-form-group class="custom-select-icon" label="Status" label-class="form-label">
        <b-form-select
          class="custom-select-icon__select"
          style="min-width: 185px"
          placeholder="Select Status"
          v-model="review_status"
          :options="statusOptions"
          required
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </b-form-group>
      <b-form-group label="Review/Remarks" label-for="review_remarks" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="review_remarks"
          placeholder="Enter your remarks"
        />
        <b-form-invalid-feedback :state="!$v.review_remarks.required && $v.review_remarks.$dirty ? false : null">
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <span v-if="review_status === REVIEW_STATUSES.APPROVED">
        <page-separator title="Verify School Information" />

        <b-form-group class="row-align-items-center">
          <label class="form-label">
            Logo
            <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
              >info</i
            >
          </label>
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isLogoUploading">...</span>
              <b-img :src="school.logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="school.logoUrl" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'logo')"
              :isUploading="isLogoUploading"
              :isRequired="!school.logoUrl"
              :placeholder="$t('schoolMsgs.logoDesc')"
            />
          </b-media>
        </b-form-group>
        <b-form-group label-for="small_logo_url" class="row-align-items-center">
          <label class="form-label">
            Small Logo
            <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="
                `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
              "
              >info</i
            >
          </label>
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isSmallLogoUploading">...</span>
              <b-img
                :src="school.small_logo_url"
                class="img-fluid"
                width="40"
                alt="Logo"
                v-else-if="school.small_logo_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'smallLogo')"
              :isUploading="isSmallLogoUploading"
              :isRequired="!school.small_logo_url"
              placeholder="Select School's Small Logo Picture"
            />
          </b-media>
        </b-form-group>
        <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
          <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="school.name" required />
        </b-form-group>
        <b-form-group label="Transition School Id" label-for="transition_sch_id" label-class="form-label">
          <b-form-input
            id="transition_sch_id"
            placeholder="Enter Trasition's School Id"
            v-model="school.transition_sch_id"
            type="number"
          />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-model="school.phone"
            v-mask="'(###) ### - ####'"
            required
          />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
          <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="school.websiteUrl" />
        </b-form-group>

        <b-form-group label="Time Zone" label-for="time_zone" label-class="form-label">
          <v-select
            id="time_zone"
            class="form-control v-select-custom"
            label="text"
            v-model="school.time_zone"
            placeholder="Enter Time Zone"
            :options="allTimeZones"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.time_zone" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <!-- Address Fields -->
        <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
          <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="school.address" required />
        </b-form-group>

        <div class="row">
          <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
            <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="school.zipcode" required />
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
            <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="school.city" required />
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
            <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="school.state" required />
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
            <v-select
              id="country"
              class="form-control v-select-custom"
              label="text"
              v-model="school.country"
              :reduce="country => country.value"
              :placeholder="$t('schoolMsgs.countryPlaceholder')"
              :options="countryOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!school.country" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>
        </div>

        <b-form-group :label="$t('schoolMsgs.bio')" label-for="about" label-class="form-label">
          <b-form-textarea
            id="about"
            :placeholder="`${$t('schoolMsgs.bioPlaceholder')}`"
            :rows="5"
            v-model="school.bio"
          />
        </b-form-group>

        <b-form-group
          :label="$t('schoolMsgs.profileBgImage')"
          label-for="profileBgImage"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <image-uploader
              @image="e => setImage(e, 'profileBgImage')"
              :isUploading="isProfileBgImgUploading"
              :isRequired="false"
              :placeholder="$t('schoolMsgs.profileBgImageDesc')"
            />
          </b-media>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.homeTitle')" label-for="pp_home_title" label-class="form-label">
          <b-form-input id="pp_home_title" v-model="school.pp_home_title" required />
        </b-form-group>

        <b-form-group
          :label="$t('schoolMsgs.homeDescription')"
          label-for="pp_home_description"
          label-class="form-label"
        >
          <b-form-input id="pp_home_description" v-model="school.pp_home_description" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.publicCTA')" label-for="pp_cta_link_text" label-class="form-label">
          <input id="pp_cta_link_text" v-model="school.pp_cta_link_text" class="form-control" required maxlength="15" />
        </b-form-group>
        <b-form-group>
          <label>
            Registration Page Description
            <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="
                `Use {{application_fee}} and {{tuition_fee}} bookmarks for showing relevant program fees in the description.`
              "
              >info</i
            >
          </label>
          <b-form-textarea
            id="pp_registration_desc"
            :placeholder="
              `Note: A non-refundable application fee of {{application_fee }} is required for online registration submissions. Before registering, please make sure to contact us to make sure all of your questions are answered.`
            "
            v-model="school.pp_registration_desc"
            :rows="3"
          />
        </b-form-group>
        <b-form-group label="Product Page Description" label-for="pp_products_desc" label-class="form-label">
          <b-form-textarea
            id="pp_products_desc"
            placeholder="View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will be received on the date of class orientation."
            v-model="school.pp_products_desc"
            :rows="3"
          />
        </b-form-group>
        <b-form-group label="Important Visitor Notice Title" label-for="visitor_notice_title" label-class="form-label">
          <b-form-input
            id="visitor_notice_title"
            placeholder="Enter Visitor Notice Title"
            v-model="school.visitor_notice_title"
          />
        </b-form-group>
        <b-form-group
          label="Important Visitor Notice Description"
          label-for="visitor_notice_desc"
          label-class="form-label"
        >
          <b-form-textarea
            id="visitor_notice_desc"
            placeholder="Enter Visitor Notice Description"
            v-model="school.visitor_notice_desc"
            :rows="5"
          />
        </b-form-group>
        <div class="row">
          <b-form-group
            :label="$t('schoolMsgs.primaryColor')"
            label-for="primaryColor"
            label-class="form-label"
            class="col-md-4"
          >
            <v-swatches
              v-model.trim="school.primaryColor"
              shapes="circles"
              show-fallback
              :swatches="swatches"
              fallback-input-type="color"
              :fallback-ok-text="$t('generalMsgs.select')"
              fallback-input-class="py-0"
            ></v-swatches>
          </b-form-group>
          <b-form-group
            :label="$t('schoolMsgs.secondaryColor')"
            label-for="secondaryColor"
            label-class="form-label"
            class="col-md-4"
          >
            <v-swatches
              v-model.trim="school.secondaryColor"
              shapes="circles"
              show-fallback
              :swatches="swatches"
              fallback-input-type="color"
              :fallback-ok-text="$t('generalMsgs.select')"
              fallback-input-class="py-0"
            ></v-swatches>
          </b-form-group>
          <b-form-group
            :label="$t('schoolMsgs.darkColor')"
            label-for="darkColor"
            label-class="form-label"
            class="col-md-4"
          >
            <v-swatches
              v-model.trim="school.darkColor"
              shapes="circles"
              show-fallback
              :swatches="swatches"
              fallback-input-type="color"
              :fallback-ok-text="$t('generalMsgs.select')"
              fallback-input-class="py-0"
            ></v-swatches>
          </b-form-group>
        </div>

        <b-form-group label="Registration Terms and Conditions" label-for="registration_terms" label-class="form-label">
          <editor
            :init="{
              height: 400,
              menubar: 'edit view insert format tools table help',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
            }"
            v-model="school.registration_terms"
            placeholder="Enter student registration terms and conditions."
          />
        </b-form-group>

        <!-- SendGrid settings -->
        <page-separator title="SendGrid Settings" />

        <b-form-group label="SendGrid API Key" label-for="sendgrid_api_key" label-class="form-label">
          <b-form-input
            id="sendgrid_api_key"
            placeholder="Enter SendGrid API Key"
            v-model.trim="school.sendgrid_api_key"
            :state="!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty ? false : null"
          />

          <b-form-invalid-feedback
            v-if="school.sendgrid_from_email"
            :state="
              (!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty) ||
              (!$v.school.sendgrid_from_email.required && $v.school.sendgrid_from_email.$dirty)
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="SendGrid Verified Email Address" label-for="contact_email" label-class="form-label">
          <b-form-input
            id="sendgrid_from_email"
            placeholder="Enter the email address verified on SendGrid account"
            v-model.trim="school.sendgrid_from_email"
            :state="
              (!$v.school.sendgrid_from_email.required || !$v.school.sendgrid_from_email.email) &&
              $v.school.sendgrid_from_email.$dirty
                ? false
                : null
            "
          />

          <b-form-invalid-feedback
            v-if="school.sendgrid_api_key"
            :state="
              (!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty) ||
              (!$v.school.sendgrid_from_email.required && $v.school.sendgrid_from_email.$dirty)
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>Invalid email address.</b-form-invalid-feedback>
        </b-form-group>

        <!-- Owner Details -->
        <div>
          <page-separator :title="$t('schoolMsgs.ownerInfo')" />

          <b-form-group
            :label="$t('userMsgs.avatar')"
            label-for="avatar"
            label-class="form-label"
            class="row-align-items-center"
          >
            <b-media class="align-items-center" vertical-align="center">
              <user-avatar
                slot="aside"
                size="lg"
                :isLoading="isAvatarImgUploading"
                :user="owner"
                url-field="avatar_url"
                first-name-field="firstName"
                last-name-field="lastName"
              >
              </user-avatar>

              <image-uploader
                @image="e => setImage(e, 'avatar')"
                :isUploading="isAvatarImgUploading"
                :isRequired="false"
                :placeholder="$t('userMsgs.avatarDesc')"
              />
            </b-media>
          </b-form-group>
          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="owner.firstName" required />
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="owner.lastName" required />
            </b-form-group>
          </div>
          <b-form-group label="Phone" label-for="owner_phone" label-class="form-label">
            <b-form-input
              id="owner_phone"
              placeholder="(777) 234 - 1235"
              v-model="owner.phone"
              v-mask="'(###) ### - ####'"
            />
          </b-form-group>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              v-model="owner.email"
              type="email"
              required
              :state="errorStates.ownerEmail"
            />
            <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('authMsgs.password')"
            label-for="password"
            label-class="form-label"
            v-if="$route.params.id"
          >
            <b-alert variant="soft-primary px-12pt" show>
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-black-100"
                  >Auto-generated password: <strong>{{ owner.password }}</strong></span
                >

                <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
                <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
                  <md-icon>content_copy</md-icon>
                </a>
              </div>
            </b-alert>
            <!-- <b-form-input
            id="password"
            :placeholder="$t('authMsgs.password')"
            v-model="owner.password"
            required
            type="password"
            :state="errorStates.password"
          />
          <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>-->
          </b-form-group>
        </div>
      </span>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'Save' : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import hexRgb from 'hex-rgb';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import generator from 'generate-password';
import Editor from '@tinymce/tinymce-vue';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import Page from '../../../components/Page.vue';
import { REVIEW_STATUSES } from '../../../common/constants';
import VSwatches from 'vue-swatches';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import moment from 'moment-timezone';
import 'vue-swatches/dist/vue-swatches.css';
import vSelect from 'vue-select';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    VSwatches,
    UserAvatar,
    Editor,
    vSelect,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96']
      ],
      title: this.$route.params.id ? this.$t('schoolMsgs.editSchool') : this.$t('schoolMsgs.addSchool'),
      statusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'approved', text: 'Approved' },
        { value: 'rejected', text: 'Rejected' },
        { value: 'pending', text: 'Pending' }
      ],
      review_status: null,
      owner: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        avatar_url: '',
        phone: '',
        repeatPassword: ''
      },
      school: {
        name: '',
        phone: '',
        transition_sch_id: null,
        websiteUrl: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        country: 'United States',
        logoUrl: null,
        small_logo_url: null,
        profileBgImgUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        bio: '',
        time_zone: 'America/New_York',
        pp_home_title: 'Welcome To Transition Enroll',
        pp_home_description: 'Our school offers a variety of programs and class for CNAs and more.',
        pp_registration_desc: null,
        pp_products_desc: null,
        visitor_notice_title: null,
        visitor_notice_desc: null,
        pp_cta_link_text: 'Join Transition Enroll',
        registration_terms: '',
        sendgrid_api_key: null,
        sendgrid_from_email: null
      },
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      countryOptions: [],
      errorStates: {
        password: null,
        ownerEmail: null
      },
      passErrorCode: null,
      isPassCopied: false,
      isSubdomainCopied: false,
      freePartnership: true,
      isLoading: false,
      isLogoUploading: false,
      isSmallLogoUploading: false,
      isProfileBgImgUploading: false,
      isAvatarImgUploading: false,
      REVIEW_STATUSES,
      review_remarks: '',
      uploadFile: { logo: null, smallLogo: null, profileBgImage: null, avatar: null },
      uploadPercent: { logo: 0, smallLogo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { logo: null, smallLogo: null, profileBgImage: null },
      allPrograms: [],
      areProgramsLoading: false,
      allTimeZones: [],
      interestedSchId: ''
    };
  },
  validations() {
    return {
      school: {
        sendgrid_api_key: { required: requiredIf(() => this.school.sendgrid_from_email) },
        sendgrid_from_email: { required: requiredIf(() => this.school.sendgrid_api_key), email }
      },
      review_remarks: { required }
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'INTERESTED SCHOOLS', to: { name: 'manage-int-schools' } },
        {
          text: this.$route.params.id ? 'Update School Status' : this.$t('schoolMsgs.addSchool'),
          active: true
        }
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.isProfileBgImgUploading ||
        this.isAvatarImgUploading ||
        this.isSmallLogoUploading
      );
    }
  },

  methods: {
    ...mapActions('school', [
      'getInterestedSchool',
      'createSchool',
      'updateSchool',
      'getSchoolImageUploadPresignedUrl',
      'createInterestedSchoolReview'
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    async copySubdomain() {
      try {
        await navigator.clipboard.writeText(this.school.subdomain);
        this.isSubdomainCopied = true;
        setTimeout(() => (this.isSubdomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            review_status: this.review_status,
            review_remarks: this.review_remarks,
            school: {
              name: this.school.name,
              phone: this.school.phone,
              website_url: this.school.websiteUrl,
              zipcode: this.school.zipcode,
              address: this.school.address,
              city: this.school.city,
              state: this.school.state,
              country: this.school.country,
              logo_url: this.school.logoUrl,
              small_logo_url: this.school.small_logo_url,
              profile_bg_img_url: this.school.profileBgImgUrl,
              primary_color: this.school.primaryColor,
              secondary_color: this.school.secondaryColor,
              dark_color: this.school.darkColor,
              dark_color_rgb: hexRgb(this.school.darkColor, { alpha: 0.5 }),
              bio: this.school.bio,
              pp_home_title: this.school.pp_home_title,
              pp_home_description: this.school.pp_home_description,
              transition_sch_id: this.school.transition_sch_id,
              pp_registration_desc: this.school.pp_registration_desc,
              pp_products_desc: this.school.pp_products_desc,
              visitor_notice_title: this.school.visitor_notice_title,
              visitor_notice_desc: this.school.visitor_notice_desc,
              time_zone: this.school.time_zone,
              pp_cta_link_text: this.school.pp_cta_link_text,
              registration_terms: this.school.registration_terms,
              sendgrid_api_key: this.school.sendgrid_api_key,
              sendgrid_from_email: this.school.sendgrid_from_email,
              owner: {
                email: this.owner.email,
                first_name: this.owner.firstName,
                last_name: this.owner.lastName,
                ...(this.owner.password && { password: this.owner.password }),
                phone: this.owner.phone,
                avatar_url: this.owner.avatar_url
              }
            }
          };

          let isConfirmed = true;

          if (data.review_status !== REVIEW_STATUSES.PENDING) {
            let content = '';
            data.review_status === REVIEW_STATUSES.APPROVED
              ? (content = `Please confirm that you want to approve this school. A new school account will be created for this school using the given credentials. This action is irreversible and you won't be able to update the interested school's status afterwards.`)
              : (content = `Please confirm that you want to reject this school. You'll still be able to change the status at anytime.`);
            isConfirmed = await this.$bvModal.msgBoxConfirm(content, {
              title: 'Are you sure?',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            });
          }
          if (isConfirmed) {
            await this.createInterestedSchoolReview({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Status Updated' });

            setTimeout(() => this.$router.push({ name: 'manage-int-schools' }), 250);
          }
        } catch (err) {
          if (get(err, 'response.data.owner')) {
            this.errorStates.ownerEmail = false;
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      this.isAvatarImgUploading = uploadType === 'avatar';

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.school.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'avatar') {
          this.owner.avatar_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.school.small_logo_url = urlResp.upload_url.split('?')[0];
        } else {
          this.school.profileBgImgUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'avatar') this.isAvatarImgUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else this.isProfileBgImgUploading = false;
    },
    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.owner.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    }
  },

  async mounted() {
    this.isLoading = true;
    this.allTimeZones = moment.tz.names();
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name
      }))
    );

    // Auto-generate password.
    try {
      if (this.$route.params.id) {
        this.owner.password = generator.generate({ length: 10, numbers: true, strict: true });

        const resp = (await this.getInterestedSchool(this.$route.params.id)).data;
        this.interestedSchId = resp.id;
        this.review_remarks = resp.review_remarks;
        this.review_status = resp.review_status;
        this.school.name = resp.name;
        this.school.phone = resp.phone;
        this.school.websiteUrl = resp.website_url;
        this.school.zipcode = resp.zipcode;
        this.school.address = resp.address;
        this.school.city = resp.city;
        this.school.state = resp.state;
        this.school.country = resp.country;
        this.school.logoUrl = resp.logo_url;
        this.school.bio = resp.bio;
        this.school.time_zone = get(resp, 'time_zone', 'America/New_York');
        this.owner.firstName = resp.contact_first_name;
        this.owner.lastName = resp.contact_last_name;
        this.owner.email = resp.contact_email;
        this.owner.phone = resp.contact_phone;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-schools' });
    }

    this.isLoading = false;
  }
};
</script>
