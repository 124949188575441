<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? this.$t('schoolMsgs.editSchool') : this.$t('schoolMsgs.addSchool')"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator :title="$t('schoolMsgs.schoolInfo')" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic School Info -->
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="school.logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="school.logoUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="!school.logoUrl"
            :placeholder="$t('schoolMsgs.logoDesc')"
          />
        </b-media>
      </b-form-group>
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isSmallLogoUploading">...</span>
            <b-img
              :src="school.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="school.small_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo')"
            :isUploading="isSmallLogoUploading"
            :isRequired="!school.small_logo_url"
            placeholder="Select School's Small Logo Picture"
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
        <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="school.name" required />
      </b-form-group>

      <b-form-group label="School Type" label-for="school_type" label-class="form-label">
        <v-select
          id="school_type"
          class="form-control v-select-custom"
          label="title"
          v-model="school.school_type"
          :reduce="item => item.value"
          placeholder="Select school type"
          :options="schoolTypeOptions"
          :clearable="false"
          :disabled="!!$route.params.id"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Transition School Id" label-for="transition_sch_id" label-class="form-label">
        <b-form-input
          id="transition_sch_id"
          placeholder="Enter Trasition's School Id"
          v-model="school.transition_sch_id"
          type="number"
        />
      </b-form-group>

      <b-form-group label="Transition Subdomain" label-for="website" label-class="form-label">
        <b-form-input id="website" placeholder="Enter the transition's subdomain" v-model="school.transitionSub" />
      </b-form-group>

      <b-form-group label="Stripe Customer Portal Link" label-for="customer" label-class="form-label">
        <b-form-input
          id="customer"
          placeholder="Enter the stripe customer portal link"
          v-model="school.customerPortalLink"
        />
      </b-form-group>

      <b-form-group label="Contact email" label-for="email" label-class="form-label">
        <b-form-input id="email" placeholder="Enter school's contact email" v-model="school.email" type="email" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="school.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
        <b-form-input
          id="website"
          :placeholder="$t('schoolMsgs.websitePlaceholder')"
          v-model="school.websiteUrl"
          :required="websiteBtn"
        />
      </b-form-group>

      <b-form-group label="Subdomain" label-for="subdomain" label-class="form-label" v-if="$route.params.id">
        <b-alert variant="soft-light px-12pt" show>
          <div class="d-flex align-items-center justify-content-between">
            <span class="text-black-100"
              ><strong>{{ school.subdomain }}</strong></span
            >

            <span v-if="isSubdomainCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
            <a href="#" @click.prevent="copySubdomain" v-else v-b-popover.hover.top="'Copy URL'">
              <md-icon>content_copy</md-icon>
            </a>
          </div>
        </b-alert>
      </b-form-group>

      <b-form-group label="Time Zone" label-for="time_zone" label-class="form-label">
        <v-select
          id="time_zone"
          class="form-control v-select-custom"
          label="text"
          v-model="school.time_zone"
          placeholder="Enter Time Zone"
          :options="allTimeZones"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school.time_zone" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="school.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="school.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="school.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="school.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
          <!-- <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="school.state" required /> -->
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="school.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <!-- Other Details -->

      <b-form-group :label="$t('schoolMsgs.bio')" label-for="about" label-class="form-label">
        <b-form-textarea
          id="about"
          :placeholder="`${$t('schoolMsgs.bioPlaceholder')}`"
          :rows="5"
          v-model="school.bio"
        />
      </b-form-group>

      <b-form-group label="Pass Ratio" label-for="passRatio" label-class="form-label">
        <currency-input
          id="passRatio"
          v-model="passRatio"
          class="form-control"
          placeholder="Enter pass percentage"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
        />
      </b-form-group>
      <b-form-group label="Payment Plan" label-for="paymentPlan" label-class="form-label">
        <v-select
          id="paymentPlan"
          label="title"
          class="form-control v-select-custom"
          v-model="paymentPlan"
          :reduce="paymentPlan => paymentPlan"
          placeholder="Select plan"
          :options="allPaymentPlans"
          :loading="arePaymentPlansLoading"
          :class="!$v.paymentPlan.required && $v.paymentPlan.$dirty ? 'form-control is-invalid' : ''"
          :clearable="false"
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <span>{{ $t(`school_payment_plans.${option.title}`) }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ $t(`school_payment_plans.${option.title}`) }}
          </template>
        </v-select>

        <b-form-invalid-feedback :state="!$v.paymentPlan.required && $v.paymentPlan.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        label="Chatbot AI Model"
        label-for="model"
        label-class="form-label"
        v-if="
          get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT ||
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT
        "
      >
        <v-select
          id="model"
          class="form-control v-select-custom"
          label="text"
          v-model="model"
          :reduce="model => model.value"
          placeholder="Select AI model"
          :options="allModels"
          :class="!$v.model.required && $v.model.$dirty ? 'form-control is-invalid' : ''"
          :clearable="false"
        >
        </v-select>

        <b-form-invalid-feedback :state="!$v.model.required && $v.model.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        v-if="
          get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED ||
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT
        "
      >
        <b-form-checkbox
          id="checkbox-1"
          v-model="published"
          name="checkbox-1"
          switch
          :required="
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED ||
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT
              ? true
              : false
          "
          >Website Published?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="
          get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED ||
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT
        "
        label="Linked Website"
        label-for="website"
        label-class="form-label"
      >
        <b-form-input
          id="website"
          placeholder="Enter linked website domain"
          v-model="linkedWeb"
          :required="
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED ||
            get(paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT
              ? true
              : false
          "
        />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="checkbox-inapp" v-model="inappPaymentEnable" name="checkbox-inapp" switch>
          Is In-app payment enabled?
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Allow students to pay their pending payment plans via transition.`"
            >info</i
          >
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage')"
            :isUploading="isProfileBgImgUploading"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.profileBgImageDesc')"
          />
        </b-media>
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeTitle')" label-for="pp_home_title" label-class="form-label">
        <b-form-input id="pp_home_title" v-model="school.pp_home_title" maxlength="100" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeDescription')" label-for="pp_home_description" label-class="form-label">
        <b-form-input id="pp_home_description" v-model="school.pp_home_description" maxlength="250" />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="checkbox-3" v-model="showBannerStatus" name="checkbox-3" switch>
          Display description on public page
        </b-form-checkbox>
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.publicCTA')" label-for="pp_cta_link_text" label-class="form-label">
        <input id="pp_cta_link_text" v-model="school.pp_cta_link_text" class="form-control" maxlength="15" />
      </b-form-group>
      <b-form-group>
        <label>
          Registration Page Description
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Use {{application_fee}} and {{tuition_fee}} bookmarks for showing relevant program fees in the description.`
            "
            >info</i
          >
        </label>
        <b-form-textarea
          id="pp_registration_desc"
          :placeholder="
            `Note: A non-refundable application fee of {{application_fee }} is required for online registration submissions. Before registering, please make sure to contact us to make sure all of your questions are answered.`
          "
          v-model="school.pp_registration_desc"
          :rows="3"
        />
      </b-form-group>
      <b-form-group label="Product Page Description" label-for="pp_products_desc" label-class="form-label">
        <b-form-textarea
          id="pp_products_desc"
          placeholder="View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will be received on the date of class orientation."
          v-model="school.pp_products_desc"
          :rows="3"
        />
      </b-form-group>

      <b-form-group label="Important Visitor Notice Title" label-for="visitor_notice_title" label-class="form-label">
        <b-form-input
          id="visitor_notice_title"
          placeholder="Enter Visitor Notice Title"
          v-model="school.visitor_notice_title"
        />
      </b-form-group>
      <b-form-group
        label="Important Visitor Notice Description"
        label-for="visitor_notice_desc"
        label-class="form-label"
      >
        <b-form-textarea
          id="visitor_notice_desc"
          placeholder="Enter Visitor Notice Description"
          v-model="school.visitor_notice_desc"
          :rows="5"
        />
      </b-form-group>
      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>

      <b-form-group label="Registration Terms and Conditions" label-for="registration_terms" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="school.registration_terms"
          placeholder="Enter student registration terms and conditions."
        />
      </b-form-group>

      <b-form-group label="Cancellation Terms" label-for="cancellation_terms" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="school.cancellation_terms"
          placeholder="Enter student cancellation terms."
        />
      </b-form-group>

      <!-- SendGrid settings -->
      <page-separator title="SendGrid Settings" />

      <b-form-group label="SendGrid API Key" label-for="sendgrid_api_key" label-class="form-label">
        <b-form-input
          id="sendgrid_api_key"
          placeholder="Enter SendGrid API Key"
          v-model.trim="school.sendgrid_api_key"
          :state="!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty ? false : null"
        />

        <b-form-invalid-feedback
          v-if="school.sendgrid_from_email"
          :state="
            (!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty) ||
            (!$v.school.sendgrid_from_email.required && $v.school.sendgrid_from_email.$dirty)
              ? false
              : null
          "
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="SendGrid Verified Email Address" label-for="contact_email" label-class="form-label">
        <b-form-input
          id="sendgrid_from_email"
          placeholder="Enter the email address verified on SendGrid account"
          v-model.trim="school.sendgrid_from_email"
          :state="
            (!$v.school.sendgrid_from_email.required || !$v.school.sendgrid_from_email.email) &&
            $v.school.sendgrid_from_email.$dirty
              ? false
              : null
          "
        />

        <b-form-invalid-feedback
          v-if="school.sendgrid_api_key"
          :state="
            (!$v.school.sendgrid_api_key.required && $v.school.sendgrid_api_key.$dirty) ||
            (!$v.school.sendgrid_from_email.required && $v.school.sendgrid_from_email.$dirty)
              ? false
              : null
          "
          >This field is required.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-else>Invalid email address.</b-form-invalid-feedback>
      </b-form-group>

      <!-- Best School Details -->

      <page-separator title="Best School's Info" />
      <div class="row">
        <b-form-group label="Best States" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="name"
            v-model="bestStates"
            :reduce="bestStates => bestStates.id"
            placeholder="Select States"
            :loading="areBestStatesLoading"
            :options="bestStateOptions"
            multiple
            @input="getCities()"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
          <!-- <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="school.state" required /> -->
        </b-form-group>

        <b-form-group label="Best Cities" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="name"
            v-model="bestCities"
            :reduce="bestCities => customReduce(bestCities)"
            placeholder="Select Cities"
            :loading="areBestCitiesLoading"
            :options="bestCitiesOptions"
            multiple
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <span>{{ option.name }}, {{ option.state.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
        </b-form-group>
      </div>
      <b-form-group label="Google Place Id" label-for="place_id" label-class="form-label">
        <b-form-input id="place_id" placeholder="Enter Google Place Id (For Reviews)" v-model="place_id" />
      </b-form-group>

      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Best School Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="bestSchoolImgUrl" class="img-fluid" width="40" alt="Logo" v-else-if="bestSchoolImgUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'BestSchoolImg')"
            :isUploading="isLogoUploading"
            :isRequired="false"
            placeholder="Select best school image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Best School Offerings" label-for="bestSchoolOfferings" label-class="form-label">
        <v-select
          id="bestSchoolOfferings"
          class="form-control v-select-custom"
          label="text"
          v-model="bestSchoolOfferings"
          :reduce="item => item.value"
          placeholder="Select best school offerings"
          :options="BestSchoolOfferingsOptions"
          multiple
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          placeholder="Please specify."
          v-model="otherOfferings"
          required
          v-if="bestSchoolOfferings.includes('other')"
        />
      </b-form-group>

      <b-form-group label="Class Tuition Range" label-for="classTuitionRange" label-class="form-label">
        <div class="row">
          <b-form-group label="From" label-for="fromClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="fromClassTuition"
              v-model="fromClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
          <b-form-group label="To" label-for="toClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="toClassTuition"
              v-model="toClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <div class="row">
        <b-form-group class="col-md-6">
          <b-form-checkbox id="checkbox-1" v-model="enrollBtn" name="checkbox-1" switch>
            Enrollment Button Active
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-md-6">
          <b-form-checkbox id="checkbox-2" v-model="websiteBtn" name="checkbox-2" switch>
            Website Button Active
          </b-form-checkbox>
        </b-form-group>
      </div>

      <b-form-group label="School Review" label-for="review" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="schoolReview"
          placeholder="please enter school review to be displayed on best school profile."
        />
      </b-form-group>

      <!-- Owner Details -->
      <div>
        <page-separator :title="$t('schoolMsgs.ownerInfo')" />

        <b-form-group
          :label="$t('userMsgs.avatar')"
          label-for="avatar"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <user-avatar
              slot="aside"
              size="lg"
              :isLoading="isAvatarImgUploading"
              :user="owner"
              url-field="avatar_url"
              first-name-field="firstName"
              last-name-field="lastName"
            >
            </user-avatar>

            <image-uploader
              @image="e => setImage(e, 'avatar')"
              :isUploading="isAvatarImgUploading"
              :isRequired="false"
              :placeholder="$t('userMsgs.avatarDesc')"
            />
          </b-media>
        </b-form-group>
        <div class="row">
          <b-form-group
            :label="$t('userMsgs.firstName')"
            label-for="firstname"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="owner.firstName" required />
          </b-form-group>

          <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
            <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="owner.lastName" required />
          </b-form-group>
        </div>
        <b-form-group label="Phone" label-for="owner_phone" label-class="form-label">
          <b-form-input
            id="owner_phone"
            placeholder="(777) 234 - 1235"
            v-model="owner.phone"
            v-mask="'(###) ### - ####'"
          />
        </b-form-group>

        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="owner.email"
            type="email"
            required
            :state="errorStates.ownerEmail"
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('authMsgs.password')"
          label-for="password"
          label-class="form-label"
          v-if="!$route.params.id"
        >
          <b-alert variant="soft-primary px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                >Auto-generated password: <strong>{{ owner.password }}</strong></span
              >

              <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </b-form-group>
        <div class="row" v-else>
          <b-form-group :label="$t('authMsgs.password')" label-for="npass" label-class="form-label" class="col-md-6">
            <b-form-input
              id="npass"
              name="password"
              :placeholder="$t('authMsgs.password')"
              v-model="owner.password"
              :required="!!owner.repeatPassword"
              :type="fieldTypes.password"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="errorStates.password"
            />
            <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('profileMsgs.repeatPassword')"
            label-for="repeatPassword"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input
              id="repeatPassword"
              name="repeatPassword"
              :placeholder="$t('profileMsgs.repeatPassword')"
              v-model="owner.repeatPassword"
              :required="!!owner.password"
              :type="fieldTypes.repeatPassword"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="errorStates.password"
            />
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import hexRgb from 'hex-rgb';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import generator from 'generate-password';
import Editor from '@tinymce/tinymce-vue';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import Page from '@/components/Page.vue';
import {
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  SCHOOL_PAYMENT_PLANS,
  SCHOOL_DEF_CANCEL_POLICY,
} from '@/common/constants';
import VSwatches from 'vue-swatches';
import UserAvatar from '@/components/User/UserAvatar.vue';
import moment from 'moment-timezone';
import 'vue-swatches/dist/vue-swatches.css';
var UsaStates = require('usa-states').UsaStates;
import vSelect from 'vue-select';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    VSwatches,
    UserAvatar,
    Editor,
    vSelect,
    ImageUploader,
  },
  extends: Page,

  data() {
    return {
      SCHOOL_PAYMENT_PLANS,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96'],
      ],
      title: this.$route.params.id ? this.$t('schoolMsgs.editSchool') : this.$t('schoolMsgs.addSchool'),
      model: 'gpt-3.5-turbo',
      owner: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        avatar_url: '',
        phone: '',
        repeatPassword: '',
      },
      school: {
        name: '',
        customerPortalLink: '',
        email: '',
        phone: '',
        transition_sch_id: null,
        websiteUrl: '',
        transitionSub: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        country: 'United States',
        logoUrl: null,
        small_logo_url: null,
        profileBgImgUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        bio: '',
        time_zone: 'America/New_York',
        pp_home_title: 'Welcome!',
        pp_home_description:
          'Our school offers a variety of programs and classes for aspiring healthcare professionals.',
        pp_registration_desc: null,
        pp_enroll_desc: null,
        pp_products_desc: null,
        visitor_notice_title: null,
        visitor_notice_desc: null,
        pp_cta_link_text: 'Enroll Now',
        registration_terms: '',
        cancellation_terms: SCHOOL_DEF_CANCEL_POLICY,
        sendgrid_api_key: null,
        sendgrid_from_email: null,
        school_type: 'local',
      },
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      countryOptions: [],
      stateOptions: [],
      errorStates: {
        password: null,
        ownerEmail: null,
      },
      passErrorCode: null,
      isPassCopied: false,
      isSubdomainCopied: false,
      freePartnership: true,
      isLoading: false,
      isLogoUploading: false,
      isBestSchoolImgUploading: false,
      isSmallLogoUploading: false,
      isProfileBgImgUploading: false,
      isAvatarImgUploading: false,

      uploadFile: { logo: null, smallLogo: null, profileBgImage: null, avatar: null, BestSchoolImg: null },
      uploadPercent: { logo: 0, smallLogo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { logo: null, smallLogo: null, profileBgImage: null },
      allPrograms: [],
      inappPaymentEnable: false,
      arePaymentPlansLoading: false,
      areBestStatesLoading: false,
      areBestCitiesLoading: false,
      allTimeZones: [],
      paymentPlan: null,
      allPaymentPlans: [],
      passRatio: null,
      BestSchoolOfferingsOptions: [
        { value: 'online_classes', text: 'Online Classes' },
        { value: 'payment_plans', text: 'Payment Plans' },
        { value: 'online_enrollment', text: 'Online Enrollment' },
        { value: 'WIOA', text: 'WIOA' },
        { value: 'job_placement', text: 'Job Placement' },
        { value: 'graduation_ceremony', text: 'Graduation Ceremony' },
        { value: 'military_discount', text: 'Military Discount' },
        { value: 'hybrid_classes', text: 'Hybrid Classes' },
        { value: 'employer_partnerships', text: 'Employer partnerships' },
        { value: 'other', text: 'Other' },
      ],
      place_id: null,
      bestStates: [],
      bestStateOptions: [],
      bestCities: [],
      bestCitiesOptions: [],
      bestSchoolOfferings: [],
      otherOfferings: null,
      fromClassTuition: null,
      toClassTuition: null,
      enrollBtn: true,
      websiteBtn: true,
      bestSchoolImgUrl: null,
      prevStateLength: null,
      schoolReview: '',

      showBannerStatus: true,

      published: false,
      linkedWeb: '',
      allModels: [
        { text: 'GPT 3.5 Turbo', value: 'gpt-3.5-turbo' },
        { text: 'GPT 3.5 Turbo Instruct', value: 'gpt-3.5-turbo-instruct' },
        { text: 'GPT 4o Mini  (Recommended)', value: 'gpt-4o-mini' },
        { text: 'GPT 4o', value: 'gpt-4o' },

      ],
      schoolTypeOptions: [
        { value: 'local', title: 'Local' },
        { value: 'national_partner', title: 'National Partner' },
      ],
    };
  },
  validations() {
    return {
      school: {
        sendgrid_api_key: { required: requiredIf(() => this.school.sendgrid_from_email) },
        sendgrid_from_email: { required: requiredIf(() => this.school.sendgrid_api_key), email },
      },
      paymentPlan: { required },
      model: {
        required: requiredIf(
          () =>
            get(this.paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT ||
            get(this.paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT
        ),
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('schoolMsgs.schools'), to: { name: 'manage-schools' } },
        {
          text: this.$route.params.id ? this.$t('schoolMsgs.editSchool') : this.$t('schoolMsgs.addSchool'),
          active: true,
        },
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.isProfileBgImgUploading ||
        this.isAvatarImgUploading ||
        this.isSmallLogoUploading
      );
    },
  },

  methods: {
    ...mapActions('school', [
      'getSchool',
      'createSchool',
      'getSchoolPaymentPlans',
      'updateSchool',
      'getSchoolImageUploadPresignedUrl',
    ]),
    ...mapActions('location', ['getBestStateOptions', 'getBestCitiesOptions']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    async copySubdomain() {
      try {
        await navigator.clipboard.writeText(this.school.subdomain);
        this.isSubdomainCopied = true;
        setTimeout(() => (this.isSubdomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    customReduce(bestCities) {
      return bestCities;
    },
    async getCities() {
      if (this.bestStates.length) {
        const bestCitiesApi = await this.getBestCitiesOptions({ states: this.bestStates });
        this.bestCitiesOptions = bestCitiesApi.data;
        if (this.bestStates.length < this.prevStateLength) {
          this.bestStates.forEach(obj => {
            this.bestCities = this.bestCities.filter(city => city.state.id === obj);
          });
          this.prevStateLength = this.bestStates.length;
        } else {
          this.prevStateLength = this.bestStates.length;
        }
      } else if (!this.bestStates.length) {
        this.bestCitiesOptions = [];
        this.bestCities = [];
        this.prevStateLength = this.bestStates.length;
      }
    },
    async fetchPaymentOptions() {
      this.arePaymentPlansLoading = true;

      const payment_plans = await this.getSchoolPaymentPlans({});
      this.allPaymentPlans = payment_plans.data;

      this.arePaymentPlansLoading = false;
    },
    async fetchBestStatesOptions() {
      this.areBestStatesLoading = true;

      const bestStates = await this.getBestStateOptions({});
      this.bestStateOptions = bestStates.data;

      this.areBestStatesLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.errorStates.password = this.errorStates.ownerEmail = null;
        if (!this.$route.params.id && this.owner.password.length < 5) {
          this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
          this.errorStates.password = false;
        } else if (this.$route.params.id && this.owner.password !== this.owner.repeatPassword) {
          this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
          this.errorStates.password = false;
          this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
        } else if (!this.$route.params.id && PASSWORD_ALL_NUMERIC_REGEX.test(this.owner.password)) {
          this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
          this.errorStates.password = false;
        } else {
          try {
            const data = {
              school_type: this.school.school_type,
              name: this.school.name,
              contact_email: this.school.email,
              phone: this.school.phone,
              website_url: this.school.websiteUrl,
              transition_sub: this.school.transitionSub,
              customer_portal_link: this.school.customerPortalLink
                ? this.school.customerPortalLink.startsWith('https://')
                  ? this.school.customerPortalLink
                  : `https://${this.school.customerPortalLink}`
                : '',
              zipcode: this.school.zipcode,
              address: this.school.address,
              city: this.school.city,
              state: this.school.state,
              country: this.school.country,
              logo_url: this.school.logoUrl,
              small_logo_url: this.school.small_logo_url,
              profile_bg_img_url: this.school.profileBgImgUrl,
              primary_color: this.school.primaryColor,
              secondary_color: this.school.secondaryColor,
              dark_color: this.school.darkColor,
              dark_color_rgb: hexRgb(this.school.darkColor, { alpha: 0.5 }),
              bio: this.school.bio,
              pp_home_title: get(this.school, 'pp_home_title.length') ? this.school.pp_home_title : null,
              pp_home_description: get(this.school, 'pp_home_description.length')
                ? this.school.pp_home_description
                : null,
              transition_sch_id: this.school.transition_sch_id,
              pp_registration_desc: this.school.pp_registration_desc,

              pp_products_desc: this.school.pp_products_desc,
              visitor_notice_title: this.school.visitor_notice_title,
              visitor_notice_desc: this.school.visitor_notice_desc,
              time_zone: this.school.time_zone,
              pp_cta_link_text: get(this.school, 'pp_cta_link_text.length') ? this.school.pp_cta_link_text : null,
              registration_terms: this.school.registration_terms,
              cancellation_terms: this.school.cancellation_terms,
              sendgrid_api_key: this.school.sendgrid_api_key,
              sendgrid_from_email: this.school.sendgrid_from_email,

              school_review: this.schoolReview,

              best_cities: this.bestCities.map(city => city.id),
              best_sch_img: this.bestSchoolImgUrl,
              place_id: this.place_id,
              best_offering: this.bestSchoolOfferings,
              other_best_offering: this.otherOfferings,
              class_tuition_from: this.fromClassTuition,
              class_tuition_to: this.toClassTuition,
              enroll_btn_active: this.enrollBtn,
              website_btn_active: this.websiteBtn,

              show_banner_status: this.showBannerStatus,

              inapp_pymnt_enable: this.inappPaymentEnable,
              chat_bot_model: this.model,
              owner: {
                email: this.owner.email,
                first_name: this.owner.firstName,
                last_name: this.owner.lastName,
                ...(this.owner.password && { password: this.owner.password }),
                phone: this.owner.phone,
                avatar_url: this.owner.avatar_url,
              },
              sch_payment_plan: get(this.paymentPlan, 'id'),
              pass_ratio: this.passRatio,
              ...((get(this.paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED ||
                get(this.paymentPlan, 'title') === SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT) && {
                school_linked_site: {
                  wbst_domain: this.linkedWeb,
                  is_published: this.published,
                },
              }),
            };

            if (this.$route.params.id) {
              await this.updateSchool({
                id: this.$route.params.id,
                data,
              });
              this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolProfileUpdated') });
            } else {
              await this.createSchool({
                ...data,
                owner: {
                  email: this.owner.email,
                  first_name: this.owner.firstName,
                  last_name: this.owner.lastName,
                  password: this.owner.password,
                  phone: this.owner.phone,
                  avatar_url: this.owner.avatar_url,
                },
              });
              this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolAdded') });
            }
            setTimeout(() => this.$router.push({ name: 'manage-schools' }), 250);
          } catch (err) {
            if (get(err, 'response.data.owner')) {
              this.errorStates.ownerEmail = false;
            }
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      this.isAvatarImgUploading = uploadType === 'avatar';
      this.isBestSchoolImgUploading = uploadType === 'BestSchoolImg';

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'logo') {
          this.school.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'avatar') {
          this.owner.avatar_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.school.small_logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'BestSchoolImg') {
          this.bestSchoolImgUrl = urlResp.upload_url.split('?')[0];
        } else {
          this.school.profileBgImgUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'avatar') this.isAvatarImgUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else if (uploadType === 'BestSchoolImg') this.isBestSchoolImgUploading = false;
      else this.isProfileBgImgUploading = false;
    },
    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.owner.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },

  async mounted() {
    this.isLoading = true;
    this.allTimeZones = moment.tz.names();
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name,
      }))
    );

    // Auto-generate password.
    if (!this.$route.params.id) {
      this.owner.password = generator.generate({ length: 10, numbers: true, strict: true });
    }

    try {
      this.fetchBestStatesOptions();
      this.fetchPaymentOptions();
      if (this.$route.params.id) {
        const resp = (await this.getSchool(this.$route.params.id)).data;
        this.school.name = resp.name;
        this.school.email = resp.contact_email;
        this.school.phone = resp.phone;
        this.school.websiteUrl = resp.website_url;
        this.school.transitionSub = resp.transition_sub;
        this.school.customerPortalLink = resp.customer_portal_link;
        this.school.zipcode = resp.zipcode;
        this.school.address = resp.address;
        this.school.city = resp.city;
        this.school.state = resp.state;
        this.school.country = resp.country;
        this.school.logoUrl = resp.logo_url;
        this.school.small_logo_url = resp.small_logo_url;
        this.school.subdomain = resp.subdomain.subdomain_url;
        this.school.profileBgImgUrl = resp.profile_bg_img_url;
        this.school.primaryColor = resp.primary_color;
        this.school.secondaryColor = resp.secondary_color;
        this.school.darkColor = resp.dark_color;
        this.school.bio = resp.bio;
        this.school.pp_home_title = resp.pp_home_title;
        this.school.pp_home_description = resp.pp_home_description;
        this.school.pp_registration_desc = resp.pp_registration_desc;
        this.school.school_type = resp.school_type;

        this.school.pp_products_desc = resp.pp_products_desc;
        this.school.visitor_notice_title = resp.visitor_notice_title;
        this.school.visitor_notice_desc = resp.visitor_notice_desc;
        this.school.time_zone = get(resp, 'time_zone', 'America/New_York');
        this.school.pp_cta_link_text = resp.pp_cta_link_text;
        this.school.transition_sch_id = resp.transition_sch_id;
        this.school.registration_terms = resp.registration_terms;
        this.school.cancellation_terms = resp.cancellation_terms;
        this.school.sendgrid_api_key = resp.sendgrid_api_key;
        this.school.sendgrid_from_email = resp.sendgrid_from_email;

        this.schoolReview = resp.school_review;
        this.model = resp.chat_bot_model;
        this.bestCities = resp.best_cities;
        this.bestStates = resp.states;
        this.bestSchoolImgUrl = resp.best_sch_img;
        this.place_id = resp.place_id;
        this.bestSchoolOfferings = resp.best_offering;
        this.otherOfferings = resp.other_best_offering;
        this.fromClassTuition = resp.class_tuition_from;
        this.toClassTuition = resp.class_tuition_to;
        this.enrollBtn = resp.enroll_btn_active;
        this.websiteBtn = resp.website_btn_active;

        this.showBannerStatus = resp.show_banner_status;

        this.owner.firstName = resp.owner.first_name;
        this.owner.lastName = resp.owner.last_name;
        this.owner.email = resp.owner.email;
        this.owner.avatar_url = resp.owner.avatar_url;
        this.owner.phone = resp.owner.phone;

        this.paymentPlan = resp.sch_payment_plan;
        this.passRatio = resp.pass_ratio;

        this.published = get(resp.school_linked_site, 'is_published');
        this.linkedWeb = get(resp.school_linked_site, 'wbst_domain');

        this.prevStateLength = this.bestStates.length;

        this.inappPaymentEnable = resp.inapp_pymnt_enable;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-schools' });
    }

    this.isLoading = false;
  },
};
</script>
