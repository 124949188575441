var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass},[_c('page-header',{staticClass:"mb-16pt",attrs:{"title":"Configure Locations Settings","container-class":null,"info-text":_vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.SCHOOL
        ? "Customize and configure details for your available campuses."
        : ''}}),_c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN)?_c('b-form-group',{attrs:{"label":_vm.$t('schoolMsgs.school'),"label-for":"school","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"school","label":"name","reduce":function (school) { return school.id; },"placeholder":"Select School","options":_vm.allSchools,"loading":_vm.areSchoolsLoading},on:{"input":function($event){return _vm.prefillLogo(_vm.school)}},scopedSlots:_vm._u([{key:"search",fn:function(ref){
        var attributes = ref.attributes;
        var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.school}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,573013980),model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.school.required && _vm.$v.school.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),_c('page-separator',{attrs:{"title":"Manage Locations/Campuses"}}),_c('div',{staticClass:"d-flex flex-wrap justify-content-end"},[_c('b-btn',{staticClass:"btn-normal mb-2",staticStyle:{"width":"150px"},attrs:{"variant":"primary","to":{ name: 'add-location', query: Object.assign({}, (_vm.school && { school_id: _vm.school })) },"type":"submit"}},[(_vm.isLoading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('span',[_vm._v("Add")])])],1),(_vm.campuses.length)?_c('div',{staticClass:"row mb-lg-12pt"},[_c('draggable',{staticClass:"col-sm-12 draggable-cursor",attrs:{"list":_vm.campuses},on:{"start":function($event){_vm.dragging = true},"end":_vm.updateOrder}},_vm._l((_vm.campuses),function(item,index){return _c('div',{key:index},[_c('campus-card',{attrs:{"image":item.image,"name":item.name,"email":item.email,"index":item.id},on:{"removeCampus":_vm.removeCampus}})],1)}),0)],1):(
        (_vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN && _vm.school) ||
        _vm.get(_vm.getLoggedInUser, 'role') !== _vm.USER_ROLES.SUPER_ADMIN
      )?_c('b-card',{staticClass:"text-center"},[_c('i',[_vm._v(" There isn't any location added. Please add if you have any. ")])]):_vm._e(),((!_vm.$v.campuses.required || !_vm.$v.campuses.minLength) && _vm.$v.campuses.$dirty)?_c('b-form-invalid-feedback',{staticClass:"mb-lg-12pt",attrs:{"state":(!_vm.$v.campuses.required || !_vm.$v.campuses.minLength) && _vm.$v.campuses.$dirty ? false : null}},[_vm._v("There isn't any location added. Please add a location if your school has multiple campuses.")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }