<template>
  <div :class="containerClass">
    <page-header
      title="Configure Tuition & Financing Page"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Manage content under the Financing tab, providing clear tuition and financing information.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="school => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillSchoolInfo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Top Banner -->

      <page-separator>
        <b-link v-b-toggle="'top-banner-section'" class="accordion__toggle p-0">
          <span class="flex">Banner</span>
          <md-icon class="accordion__toggle-icon">
            {{ topBannerVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="top-banner-section"
        accordion="topBannerAccordionId"
        :visible="topBannerVisible"
        class="accordion__menu"
        @hide="topBannerVisible = false"
        @show="topBannerVisible = true"
      >
        <div>
          <b-form-group
            label="Banner Image"
            label-for="banner_image"
            label-class="form-label"
            class="row-align-items-center"
          >
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isImgUploading">...</span>
                <b-img
                  :src="tuitionConfig.bannerImg"
                  class="img-fluid"
                  width="40"
                  alt="Image"
                  v-else-if="tuitionConfig.bannerImg"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <image-uploader
                @image="e => setImage(e, 'banner_image')"
                :isUploading="isImgUploading"
                :isRequired="false"
                placeholder="Select Banner Image"
              />
            </b-media>
          </b-form-group>

          <b-form-group label="Title" label-for="banner-title" label-class="form-label">
            <b-form-input id="banner-title" placeholder="Enter Title" v-model="tuitionConfig.title" required />
          </b-form-group>

          <b-form-group label="Description" label-for="banner-desc" label-class="form-label">
            <b-form-textarea
              id="banner-desc"
              placeholder="Enter Description"
              rows="6"
              v-model="tuitionConfig.description"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>
      </b-collapse>

      <!-- Items -->

      <page-separator>
        <b-link v-b-toggle="'item-section'" class="accordion__toggle p-0">
          <span class="flex">Items</span>
          <md-icon class="accordion__toggle-icon">
            {{ ItemsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="item-section"
        accordion="featuresAccordionId"
        :visible="ItemsVisible"
        class="accordion__menu"
        @hide="ItemsVisible = false"
        @show="ItemsVisible = true"
      >
        <!-- <b-form-group label-class="form-label">
          <b-form-checkbox id="item" v-model="teamSection" switch> Enable this section </b-form-checkbox>
        </b-form-group> -->
        <div>
          <!-- <b-form-group label="Title" label-for="item-title" label-class="form-label">
            <b-form-input id="item-title" placeholder="Team" v-model.trim="tuitionConfig.team_title" />
          </b-form-group> -->

          <!-- <b-form-group label="Description" label-for="team_desc" label-class="form-label">
            <b-form-textarea id="team_desc" rows="6" v-model="tuitionConfig.team_desc" required></b-form-textarea>
          </b-form-group> -->

          <div v-for="(fe, index) in $v.item.$each.$iter" :key="fe.$model.id" class="mb-2">
            <div class="d-flex justify-content-end">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addItem(index)"> Add Item </a>
              <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeItem(index)" v-if="item.length > 1">
                Remove Item
              </a>
            </div>

            <div>
              <page-separator :title="`Item #${+index + 1}`" />

              <!-- <b-form-group
                label="Image"
                label-for="image_url"
                label-class="form-label"
                class="row-align-items-center col-md-12"
              >
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="featuresFile[index].isImgUploading">...</span>
                    <b-img
                      :src="fe.image_url.$model"
                      class="img-fluid"
                      width="40"
                      alt="Image"
                      v-else-if="fe.image_url.$model"
                    />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>
                  <b-form-file
                    :id="`image_url-${index}`"
                    placeholder="Select Item Image"
                    :browse-text="$t('generalMsgs.browse')"
                    v-model="featuresFile[index].image_url"
                    @input="uploadImage('image_url', index)"
                    :disabled="featuresFile[index].isImgUploading"
                    accept="image/*"
                  />
                </b-media>
              </b-form-group> -->

              <b-form-group label="Name" label-for="item-title" label-class="form-label">
                <b-form-input
                  id="item-title"
                  placeholder="Item"
                  v-model.trim="fe.title.$model"
                  :state="!fe.title.required && fe.$dirty ? false : null"
                />
                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <!-- <b-form-group label="Description" label-for="description" label-class="form-label" class="col-md-12">
                <b-form-textarea
                  id="description"
                  placeholder="Description"
                  v-model.trim="fe.description.$model"
                  rows="6"
                  :state="!fe.description.required && fe.$dirty ? false : null"
                ></b-form-textarea>

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group> -->

              <b-form-group label="Description" label-for="registration_terms" label-class="form-label">
                <editor
                  :init="{
                    height: 400,
                    menubar: 'edit view insert format tools table help',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                  }"
                  v-model.trim="fe.description.$model"
                  placeholder="Description"
                  :state="!fe.description.required && fe.$dirty ? false : null"
                />
                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<style lang="css" scoped></style>

<script>
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Editor from '@tinymce/tinymce-vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    Editor,
    vSelect,
    PageSeparator,
    ImageUploader
  },
  extends: Page,
  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      topBannerVisible: true,
      ItemsVisible: true,
      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Tuition Page',

      school: null,

      teamSection: false,
      tuitionConfig: {
        title: '',
        description: '',
        bannerImg: ''
      },

      linkedWebsite: null,
      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,

      uploadFile: { banner_image: null },
      uploadPercent: { banner_image: 0 },
      uploadCancelTokenSource: { banner_image: null },
      USER_ROLES,
      USER_ROLE_TYPES,

      item: [
        {
          id: '',
          title: '',
          description: ''
        }
      ]
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN)
      },

      item: {
        $each: {
          title: {
            required: requiredIf(() => this.teamSection)
          },
          description: {
            required: requiredIf(() => this.teamSection)
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading;
    }
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getTuitionPage', 'createTuitionConfig', 'getLinkedImageUploadPresignedUrl']),
    get,
    isEmpty,

    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    addItem(index) {
      this.item.splice(index + 1, 0, {
        id: this.item.length,
        title: '',
        description: ''
      });
    },

    removeItem(index) {
      this.item.splice(index, 1);
    },
    resetData() {
      this.linkedWebsite = null;
      this.tuitionConfig.title = '';
      this.tuitionConfig.description = '';
      this.tuitionConfig.bannerImg = '';

      this.item = [
        {
          title: '',
          description: ''
        }
      ];
      this.uploadFile.banner_image = null;
    },

    prefillSchoolInfo(schoolId) {
      this.resetData();
      this.fetchTuitionConfig(schoolId);
    },

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`
      });

      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    async fetchTuitionConfig(school_id = null) {
      let resp;
      if (school_id) {
        resp = await this.getTuitionPage({
          school_id: school_id
        });
      } else {
        resp = await this.getTuitionPage({});
      }

      this.linkedWebsite = resp.linked_website;
      this.tuitionConfig.title = resp.banner_title;
      this.tuitionConfig.description = resp.banner_desc;
      this.tuitionConfig.bannerImg = resp.banner_img;

      this.item = get(resp, 'items.length')
        ? get(resp, 'items')
        : [
            {
              id: '',
              title: '',
              description: ''
            }
          ];
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      if (uploadType === 'banner_image') {
        this.isImgUploading = true;
      }
      try {
        const urlResp = await this.getLinkedImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images'
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'banner_image') {
          this.tuitionConfig.bannerImg = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'banner_image') this.isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            linked_website: this.linkedWebsite,
            banner_title: this.tuitionConfig.title,
            banner_desc: this.tuitionConfig.description,
            banner_img: this.tuitionConfig.bannerImg,

            items: this.item || [],

            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id
          };
          await this.createTuitionConfig({
            ...data
          });
          this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchTuitionConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  }
};
</script>
