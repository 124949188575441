<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
            <b-skeleton type="button" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER"></b-skeleton>
            <b-skeleton type="button" class="ml-2"></b-skeleton>
          </div>
        </div>
      </template>

      <div
        class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
        :style="`background-image: url('${school.profile_bg_img_url}');`"
      >
        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar
              :title="true"
              rounded
              size="xxl"
              no-link
              :class="!isMobSmallScreen ? 'mr-3' : ''"
              title-class="bg-white"
            >
              <b-img
                :src="school.logo_url || DefaultAvatar"
                class="img-fluid"
                width="104"
                alt="Logo"
                v-if="school.logo_url"
              />
              <md-icon v-else>account_balance</md-icon>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0" v-text="school.name" />

              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="60%"></b-skeleton>
                  </p>
                </template>

                <p class="lead text-white-50 d-flex align-items-center">
                  <span class="d-flex align-items-center">
                    <i class="material-icons icon-16pt mr-4pt">local_library</i>
                    {{ get(school, 'program_set.length') }}
                    {{ get(school, 'program_set.length', 0) > 1 ? 'Programs' : 'Program' }}
                  </span>
                </p>
              </b-skeleton-wrapper>
            </div>

            <b-btn
              :disabled="
                ![PARTNERSHIP_STATUS.NOT_REQUESTED, PARTNERSHIP_STATUS.REQUESTED].includes(
                  get(partnership, 'status')
                ) || isRequesting
              "
              @click.prevent="
                () =>
                  get(partnership, 'status') == PARTNERSHIP_STATUS.REQUESTED
                    ? deleteRequest(get(partnership, 'id'))
                    : (showAffReqModal = true)
              "
              :variant="
                [PARTNERSHIP_STATUS.REQUESTED, PARTNERSHIP_STATUS.REVOKED].includes(get(partnership, 'status'))
                  ? 'danger'
                  : PARTNERSHIP_STATUS.NOT_REQUESTED
                  ? 'primary'
                  : 'secondary'
              "
            >
              {{ $t(`npAffBtnText.${get(partnership, 'status')}`) }}
            </b-btn>
          </div>
        </div>
      </div>

      <div :class="containerClass">
        <div style="display: center">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3"
            content-class=" "
            class="mt-0"
            id="homePageTab"
            @input="onTabsChanged"
            style="scroll-margin-top: 66px; font-size: 16.5px"
          >
            <b-tab title="Overview" class="tab-secondary">
              <template #title>
                <i class="fas fa-info align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Overview</strong>
              </template>
              <div class="page-section">
                <div class="row card-group-row mb-lg-12pt home-card">
                  <div
                    class="col-sm-12 card-group-row__col"
                    v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.REQUESTED"
                  >
                    <div class="card card-block card-stretch card-height col-md-12">
                      <div class="card-body text-center">Affiliation request has been sent.</div>
                    </div>
                  </div>
                </div>
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <div class="card border-left-0">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">About</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex">
                              <div
                                class="d-flex align-items-center mt-2"
                                v-if="
                                  get(school, 'address') &&
                                    get(school, 'city') &&
                                    get(school, 'state') &&
                                    get(school, 'zipcode') &&
                                    get(school, 'country')
                                "
                              >
                                <md-icon class="font-size-16pt mr-2">location_on</md-icon>
                                {{ get(school, 'address') }}, {{ get(school, 'city') }}, {{ get(school, 'state') }}
                                {{ get(school, 'zipcode') }},
                                {{ get(school, 'country') }}
                              </div>

                              <div class="d-flex align-items-center mt-2" v-if="school.phone">
                                <md-icon class="font-size-16pt mr-2">phone</md-icon>
                                <a :href="`tel:${school.phone}`" class="text-black">{{ school.phone }}</a>
                              </div>

                              <div class="d-flex align-items-center mt-2" v-if="school.website_url">
                                <md-icon class="font-size-16pt mr-2">language</md-icon>
                                <a :href="getClickableLink(school.website_url)" target="_blank" class="text-black">{{
                                  getClickableLink(school.website_url)
                                }}</a>
                              </div>

                              <div v-if="get(school, 'program_set.length')" class="mt-3">
                                <h5 class="card-title fw600">Programs</h5>
                                <div v-for="program in get(school, 'program_set')" :key="program.id" class=" px-1 mt-2">
                                  <div class="">
                                    <md-icon class="font-size-16pt mr-2">check</md-icon>
                                    <strong class="">{{ program.title }} </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-left-0" v-if="school.bio">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Bio</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex program-description">
                              <div v-safe-html="get(school, 'bio', '')" v-if="school.bio" />

                              <i v-else>{{ school.name }} has not added any bio.</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-skeleton-wrapper>

    <req-affiliation-modal :show-modal="showAffReqModal" @close="hideAffReqModal" @onSubmit="makeAffiliates" />
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate, formatTimeAgo } from '../../../common/utils';
import { FmvAvatar } from 'fmv-avatar';
import { get, isEmpty } from 'lodash';
import ReqAffiliationModal from './ReqAffiliationModal.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { USER_ROLE_TYPES, PARTNERSHIP_STATUS, USER_ROLES, GENDERS } from '@/common/constants';

export default {
  components: { MdIcon, FmvAvatar, ReqAffiliationModal },
  extends: Page,
  name: 'SchoolProfile',

  data() {
    return {
      DefaultAvatar,
      USER_ROLE_TYPES,
      USER_ROLES,

      title: 'School Profile',
      showAffReqModal: false,

      isLoading: true,
      isRequesting: false,
      areStudentsLoading: true,
      stdFirstLoading: true,

      stdCountLoading: false,
      school: {},
      schoolId: null,
      schoolStudents: 0,

      totalStudents: 0,
      students: [],
      perPage: 10,
      currentPage: 1,

      studentSearchTerm: '',
      studentOrdering: 'user__first_name,user__last_name',

      windowWidth: window.innerWidth,

      showRequestModal: false,
      partnership: {},
      isPartnershipLoading: false,
      PARTNERSHIP_STATUS,
      openDetailsModal: false,

      GENDERS,

      isPartnershipResponding: false,
      openRequestPartnershipDetailModal: false,

      unlockedStatusesLoading: false,
      unlockedStatusesById: {},
      firstLoad: true,
      showModal: false,
      programs: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'School Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('school', ['getNationalSchool', 'partnerStatus', 'reqAffiliation', 'deleteAffiliationReq']),
    get,
    isEmpty,
    formatFullDate,
    formatTimeAgo,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },

    hideAffReqModal() {
      this.showAffReqModal = false;
    },

    async deleteRequest(id) {
      this.isRequesting = true;

      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to cancel the affiliation request.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteAffiliationReq(id);
          this.fetchPartnership();
          this.makeToast({ variant: 'success', msg: 'Request canceled!' });
        }
      } catch (err) {
        //
      }

      this.isRequesting = false;
    },

    async fetchPartnership() {
      this.partnerStatusesLoading = true;

      const statuses = (
        await this.partnerStatus({
          school_ids: [this.school.id],
        })
      ).data.data;
      this.partnership = statuses[0];

      this.partnerStatusesLoading = false;
    },

    async fetchSchool() {
      this.isLoading = true;

      try {
        const empId = this.$route.params.id || this.getLoggedInUser.linked_entity.id;
        const resp = (await this.getNationalSchool(empId)).data;

        this.school = resp;

        this.schoolId = this.school.id;
      } catch (err) {
        //
      }

      this.isLoading = false;
    },

    async makeAffiliates(comments) {
      this.isLoading = true;

      try {
        const data = {
          request_to_id: this.schoolId,
          comments: comments,
        };

        await this.reqAffiliation(data);
        this.fetchPartnership();

        this.makeToast({ variant: 'success', msg: 'Request sent successfully!' });
      } catch (err) {
        //
      }

      this.isLoading = false;
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    await this.fetchSchool();
    this.fetchPartnership();

    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
