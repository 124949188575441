<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex align-items-center"
            v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
          >
            <p class="backResult text-50">
              <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>
              </a>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card" v-for="i in [1, 2, 3]" :key="i">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div
          class="container page__container d-flex align-items-center"
          v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
        >
          <p class="backResult text-50">
            <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </a>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <!-- Old avatar image style (use if needed) -->
          <!-- <img
            :src="student.user.avatar_url || DefaultAvatar"
            width="115"
            class="mr-md-32pt mb-32pt mb-md-0 rounded mobilemb10"
            :alt="`${getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'Student' : 'Candidate'} Photo`"
          /> -->
          <!-- <user-avatar
            size="7.3rem"
            :user="student.user"
            rounded
            hide-text
            class="mr-md-32pt mb-32pt mb-md-0 mobilemb10"
          /> -->

          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4>{{ pre_enroll.first_name }} {{ pre_enroll.last_name }}</h4>
            <div class="d-flex">
              <b-dropdown text="Contact" variant="primary" class="mr-2">
                <b-dropdown-item :href="`mailto:${pre_enroll.email}`"
                  ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
                >
                <b-dropdown-item :href="`tel:${pre_enroll.phone}`"
                  ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown text="Actions" variant="">
                <b-dropdown-item
                  @click.prevent="updateStatus('disapproved')"
                  v-if="[STD_PRE_ENROLL_STATUSES.APPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(pre_enroll.status)"
                >
                  <md-icon class="text-danger">block</md-icon> Disapprove
                </b-dropdown-item>

                <b-dropdown-item
                  @click.prevent="updateStatus('approved')"
                  v-if="
                    [STD_PRE_ENROLL_STATUSES.DISAPPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(pre_enroll.status)
                  "
                >
                  <md-icon class="text-success">check</md-icon> Approve
                </b-dropdown-item>

                <b-dropdown-item class="reg-table-item" @click.prevent="openAddCommentModal()">
                  <md-icon class="text-info">comment</md-icon>
                  {{ pre_enroll.comment ? 'Update Notes' : 'Add Notes' }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex justify-content-between">
              <div class="icon-align">
                <i class="material-icons icon-18pt mr-8pt">flare</i><b class="mr-1">Status:</b>
                <i v-if="isFormLoading" class="mr-1">Loading</i>
                <span v-else class="mr-1">{{ $t(`preEnrollStatuses.${pre_enroll.status}`) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="pre_enroll.comment">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Notes</h5>
            </div>

            <p v-html="toggleComment ? formattedText : truncate(formattedText, { length: 300 })"></p>
            <strong
              @click.prevent="() => (toggleComment = !toggleComment)"
              v-if="pre_enroll.comment.length > 300"
              class="clickable-item"
              >{{ toggleComment ? ' ... see less' : 'see more' }}</strong
            >
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Student's Information</h5>
            </div>

            <div class="row">
              <b-form-group
                :label="$t('userMsgs.firstName')"
                label-for="firstname"
                label-class="form-label"
                class="col-md-6"
              >
                <b-form-input
                  id="firstname"
                  :placeholder="$t('userMsgs.firstName')"
                  :value="pre_enroll.first_name"
                  disabled
                />
              </b-form-group>

              <b-form-group
                :label="$t('userMsgs.lastName')"
                label-for="lastname"
                label-class="form-label"
                class="col-md-6"
              >
                <b-form-input
                  id="lastname"
                  :placeholder="$t('userMsgs.lastName')"
                  :value="pre_enroll.last_name"
                  disabled
                />
              </b-form-group>
            </div>

            <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
              <b-form-input
                id="email"
                :placeholder="$t('authMsgs.emailAddr')"
                type="email"
                :value="pre_enroll.email"
                disabled
              />
            </b-form-group>
            <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
              <b-form-input
                id="phone"
                placeholder="(888) 689 - 1235"
                :value="pre_enroll.phone"
                v-mask="'(###) ### - ####'"
                disabled
              />
            </b-form-group>
            <b-form-group label="Birth Date" label-for="birthday" label-class="form-label">
              <b-form-input id="birthday" :value="student.birthday" disabled />
            </b-form-group>
            <b-form-group
              label="Language Preference(s)"
              label-for="lang_preferences"
              label-class="form-label"
              v-if="staticFieldsConfig.lang_preferences !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="lang_preferences"
                class="form-control v-select-custom"
                label="name"
                :value="student.lang_preferences"
                :reduce="(item) => item.value"
                placeholder="Select Preferred Language"
                :options="langOptions"
                :clearable="false"
                multiple
                disabled
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Gender"
              label-for="gender"
              label-class="form-label"
              v-if="staticFieldsConfig.gender !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="gender"
                class="form-control v-select-custom"
                label="text"
                :value="student.gender"
                :reduce="(item) => item.value"
                placeholder="Select your gender"
                :options="GENDER_OPTIONS"
                :clearable="false"
                disabled
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Ethnicity"
              label-for="ethnicity"
              label-class="form-label"
              v-if="staticFieldsConfig.ethnicity !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="ethnicity"
                class="form-control v-select-custom"
                label="text"
                :value="student.ethnicity"
                :reduce="(r) => r.value"
                placeholder="Select your ethnicity"
                :options="ethnicityOptions"
                :clearable="false"
                disabled
              >
              </v-select>
              <b-form-input
                id="ethnicity_other"
                class="mt-1"
                placeholder="Please specify"
                :value="student.ethnicity_other"
                v-if="ethnicity === 'other'"
                disabled
                :clearable="false"
              />
            </b-form-group>

            <!-- Address Fields -->
            <b-form-group
              :label="$t('addressMsgs.address')"
              label-for="address"
              label-class="form-label"
              v-if="staticFieldsConfig.address !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input id="address" :placeholder="$t('addressMsgs.address')" :value="student.address" disabled />
            </b-form-group>

            <div class="row">
              <b-form-group
                :label="$t('addressMsgs.zipcode')"
                label-for="zipcode"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.zipcode !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" :value="student.zipcode" disabled />
              </b-form-group>

              <b-form-group
                :label="$t('addressMsgs.city')"
                label-for="city"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.city !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="city" :placeholder="$t('addressMsgs.city')" :value="student.city" disabled />
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                :label="$t('addressMsgs.state')"
                label-for="state"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.state !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="state" :placeholder="$t('addressMsgs.state')" :value="student.state" disabled />
              </b-form-group>

              <b-form-group
                :label="$t('addressMsgs.country')"
                label-for="country"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.country !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="country"
                  class="form-control v-select-custom"
                  label="text"
                  :value="student.country"
                  :reduce="(country) => country.value"
                  :placeholder="$t('addressMsgs.countryPlaceholder')"
                  disabled
                  :clearable="false"
                >
                </v-select>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Additional Information</h5>
            </div>

            <b-form-group
              label="Social Security Number"
              label-for="student_ssn"
              label-class="form-label"
              v-if="staticFieldsConfig.student_ssn !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="student_ssn"
                placeholder="Please enter your Social Security Number (SSN)"
                v-mask="'###-##-####'"
                :value="student.student_ssn"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Occupation"
              label-for="occupation"
              label-class="form-label"
              v-if="staticFieldsConfig.occupation !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="occupation"
                placeholder="Please enter your occupation (if any)"
                :value="student.occupation"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Have you been certified before?"
              label-for="certified_before"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="certified_before"
                v-model="student.certified_before"
                :options="YES_NO_OPTIONS"
                :aria-describedby="ariaDescribedby"
                disabled
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              :label="`How did you hear about the program?`"
              label-for="reference"
              label-class="form-label"
              v-if="staticFieldsConfig.reference !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="reference"
                class="form-control v-select-custom"
                label="text"
                :value="student.reference"
                :reduce="(r) => r.value"
                placeholder="How did you discover the program?"
                :options="STD_REG_REFERRAL_OPTIONS"
                disabled
                :clearable="false"
              >
              </v-select>

              <b-form-input
                id="other_reference"
                class="mt-1"
                placeholder="Please specify"
                :value="other_reference"
                v-if="student.reference === 'other'"
                disabled
              />
            </b-form-group>
            <b-form-group
              label="Would you like to receive job and career opportunities from top healthcare employers?"
              label-for="career_service_interest"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="career_service_interest"
                v-model="student.career_service_interest"
                :options="YES_NO_OPTIONS"
                :aria-describedby="ariaDescribedby"
                disabled
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              label="Resume"
              label-for="resume"
              label-class="form-label"
              class="row-align-items-center"
              v-if="staticFieldsConfig.resume_url !== STATIC_FIELD_TYPE.DISABLED"
            >
              <!-- TODO: Add resume download here -->
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: student.resume_url,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                v-if="student.resume_url"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(student.resume_url, true) }}
              </a>
              <i v-else>No resume uploaded.</i>
            </b-form-group>

            <b-form-group
              label="Desired Work Type"
              label-for="desired_work_type"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_type !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_type"
                placeholder="Select Desired Work Type"
                :value="student.desired_work_type"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Desired Work Time"
              label-for="desired_work_time"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_time !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_time"
                placeholder="Select Desired Work Time"
                :value="student.desired_work_time"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Desired Work Days"
              label-for="desired_work_day"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_day !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_day"
                placeholder="Select Desired Work Days"
                :value="student.desired_work_day"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Work Travel"
              label-for="work_travel"
              label-class="form-label"
              v-if="staticFieldsConfig.work_travel !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="work_travel"
                class="form-control v-select-custom"
                label="text"
                :value="student.work_travel"
                :reduce="(item) => item.value"
                :options="workTravelOptions"
                placeholder="Select preferable miles to travel."
                disabled
                :clearable="false"
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Interested Facility Type(s)"
              label-for="interested_facility_types"
              label-class="form-label"
              v-if="staticFieldsConfig.interested_facility_types !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="interested_facility_types"
                class="form-control v-select-custom"
                label="text"
                :value="student.interested_facility_types"
                :reduce="(item) => item.value"
                placeholder="Select the facility types the student is interested in working at"
                multiple
                disabled
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </div>
        </div>

        <div class="card" v-for="sec in pre_enroll.enroll_questions_responses" :key="sec.id">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">{{ sec.section.title }}</h5>
                </div>

                <i v-if="!sec.data.length">No questions in the section.</i>

                <div v-else>
                  <b-form-group
                    :label="ques.title"
                    label-class="form-label"
                    v-for="(ques, index) in sec.data"
                    :key="index"
                  >
                    <b-form-input
                      :placeholder="ques.placeholder"
                      v-if="ques.type === QUES_TYPES.SHORT_TEXT"
                      :value="ques.response.data"
                      disabled
                    />
                    <b-form-textarea
                      v-else-if="ques.type === QUES_TYPES.LONG_TEXT"
                      class="form-control"
                      rows="10"
                      :placeholder="ques.placeholder"
                      :value="ques.response.data"
                      disabled
                    ></b-form-textarea>
                    <v-select
                      v-else-if="ques.type === QUES_TYPES.SINGLE_SELECT"
                      class="form-control v-select-custom"
                      label="text"
                      disabled
                      :value="ques.response.data"
                      :reduce="(ins) => ins.value"
                      :options="get(ques, 'extra.options', [])"
                      :placeholder="ques.placeholder"
                      :clearable="false"
                    >
                    </v-select>
                    <v-select
                      v-if="ques.type === QUES_TYPES.MULTI_SELECT"
                      class="form-control v-select-custom"
                      label="text"
                      :value="ques.response.data"
                      :reduce="(ins) => ins.value"
                      :options="get(ques, 'extra.options', [])"
                      :placeholder="ques.placeholder"
                      multiple
                      disabled
                      :clearable="false"
                    >
                    </v-select>
                    <div v-else-if="ques.type === QUES_TYPES.FILE_TYPE">
                      <a
                        v-if="ques.response.data"
                        href="#"
                        @click.prevent="
                          downloadGivenFile({
                            fileUrl: ques.response.data,
                            removeTimestamp: true,
                          })
                        "
                        v-b-popover.hover.top="'Download'"
                        ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(ques.response.data, true) }}
                      </a>
                      <i v-else>No file uploaded.</i>
                    </div>

                    <b-form-radio-group
                      v-else-if="ques.type === QUES_TYPES.BOOLEAN"
                      v-model="ques.response.data"
                      :options="get(ques, 'extra.options', YES_NO_OPTIONS)"
                      disabled
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-comment-modal
      :show-modal="showAddCommentModal"
      :show-preview="false"
      :reg="pre_enroll"
      is-preenroll-comment
      @close="hideAddCommentModal"
      @commentUpdated="onCommentUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, keyBy, truncate } from 'lodash';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import AddCommentModal from './AddCommentModal.vue';
import {
  formatFullDate,
  getLangOptions,
  getHumanReadableCommaSepStr,
  getWorkTravelOptions,
  formatDateTime,
  parseFileNameFromUrl,
  formatDateSimple,
} from '@/common/utils';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import {
  USER_ROLES,
  USER_ROLE_TYPES,
  FACILITY_TYPES,
  ETHNICITY_OPTIONS,
  STD_PLAN_TO_PAY_OPTIONS,
  STATIC_FIELD_TYPE,
  STD_REG_REASON_OPTIONS,
  YES_NO_OPTIONS,
  GENDER_OPTIONS,
  STD_REG_REFERRAL_OPTIONS,
  QUES_TYPES,
  STD_PRE_ENROLL_STATUSES,
  STD_REG_PAY_OPTIONS,
} from '@/common/constants';

export default {
  components: {
    vSelect,
    AddCommentModal,
  },
  extends: Page,
  name: 'PreEnrollStdProfile',

  data() {
    return {
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      STATIC_FIELD_TYPE,
      STD_REG_REASON_OPTIONS,
      YES_NO_OPTIONS,
      GENDER_OPTIONS,
      STD_REG_REFERRAL_OPTIONS,
      QUES_TYPES,
      STD_PRE_ENROLL_STATUSES,
      STD_REG_PAY_OPTIONS,

      title: 'Student Pre Enroll',
      //studentsListLinkName: '',

      isLoading: false,
      isLastContactLoading: true,
      isPayStatusLoading: false,
      termsAndConditions: true,
      student: {},
      pre_enroll: {},
      statusLoading: false,
      // TODO: Check 'Cats Acceptable' Certification.
      certificationTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false, showText: 'Show License' },
        { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
        { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
        { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
      ],
      ethnicity: null,
      planToPayOptions: STD_PLAN_TO_PAY_OPTIONS,
      ethnicityOptions: ETHNICITY_OPTIONS,
      showDoc: { cna_license_no: false },
      certifications: [],
      records: [],
      careerPreferences: [],
      stdCountLoading: true,
      schoolStdCount: 0,
      sections: [],
      showDocModal: false,
      selectedDocTitle: '',
      selectedDocSrc: '',
      showContactModal: false,
      langOptions: [],
      workTravelOptions: [],
      FACILITY_TYPES,

      staticFieldsConfig: {
        lang_preferences: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        student_ssn: null,
        reference: null,
        registration_reason: null,
        plan_to_pay: null,
        occupation: null,
        gender: null,
        ethnicity: null,
        address: null,
        zipcode: null,
        city: null,
        state: null,
        country: null,
        resume_url: null,
      },

      showTermsModal: false,
      registrationTerms: null,
      windowWidth: window.innerWidth,
      showAddCommentModal: false,
      comment: null,
      toggleComment: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.title, active: true },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.statusLoading;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    formattedText() {
      return this.pre_enroll.comment.replace(/\n/g, '<br>');
    },
  },

  methods: {
    ...mapActions('student', ['getStudent', 'getStudentRegProfile', 'updateStdRegPayStatus']),
    ...mapActions('registrationForm', ['getPublicRegistrationForm']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('preEnrollForm', ['getStudentPreEnrollProfile', 'updatePreEnrollStatus']),
    formatFullDate,
    formatDateTime,
    parseFileNameFromUrl,
    get,
    truncate,
    goBack() {
      this.$router.go(-1);
    },
    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getStudentPreEnrollProfile({ pre_enrollment: this.$route.params.id })).data;
      // Mapping student data.
      const student = resp.payload;

      if (student.birthday) {
        student.birthday = formatDateSimple(student.birthday);
      }

      student.desired_work_type = getHumanReadableCommaSepStr(
        student.desired_work_type.map((type) => this.$t(`studentWorkTypeOpts.${type}`)),
        'or'
      );
      student.desired_work_time = getHumanReadableCommaSepStr(
        student.desired_work_time.map((time) => this.$t(`studentWorkTimeOpts.${time}`))
      );
      student.desired_work_day = getHumanReadableCommaSepStr(
        student.desired_work_day.map((day) => this.$t(`jobWorkDays.${day}`))
      );

      if (get(student, 'lang_preferences.length')) {
        const arr = getLangOptions();
        const langPreference = keyBy(arr, 'value');
        student.lang_preferences = student.lang_preferences.map((lang) => langPreference[lang].name);
      }

      if (get(student, 'interested_facility_types.length')) {
        student.interested_facility_types = student.interested_facility_types.map((type) => this.FACILITY_TYPES[type]);
      }

      this.student = student;
      this.student.student_ssn = this.student.student_ssn ? '***-**-' + this.student.student_ssn : '';

      this.pre_enroll = resp;

      this.isLoading = false;
    },

    async fetchStaticFieldsConfig() {
      this.isDataLoading = true;
      try {
        const resp = (
          await this.getPublicRegistrationForm({
            params: {
              program: this.$route.query.program,
              school: this.getLoggedInUser.linked_entity.id,
              enrollment_type: 'pre_enrollment',
            },
          })
        ).data;

        resp.forEach((obj) => {
          this.staticFieldsConfig[obj.field_abbr] = obj.status;
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDataLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    openAddCommentModal() {
      this.showAddCommentModal = true;
    },
    hideAddCommentModal() {
      this.showAddCommentModal = false;
    },
    onCommentUpdate(data) {
      this.pre_enroll.comment = data.comment;
    },

    async updateStatus(statusText) {
      this.statusLoading = true;

      try {
        await this.updatePreEnrollStatus({ id: this.pre_enroll.id, data: { status: statusText } });
        this.fetchStudent();
        this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.statusLoading = false;
    },

    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.registrationTerms = this.getLoggedInUser.linked_entity.registration_terms;
    this.workTravelOptions = getWorkTravelOptions();
    if (this.$route.params.id) {
      await this.fetchStudent();
      this.fetchStaticFieldsConfig();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
