<template>
  <b-modal
    ref="registeredStudentsModal"
    hide-footer
    title="Registered Students"
    centered
    lazy
    id="modal"
    size="lg"
    scrollable
    @hide="hideModal"
  >
    <div class="d-flex mb-2" v-if="registeredStudents.length">
      <b-btn
        style="width: 220px; height: 40px"
        @click.prevent="fetchStudentRosters"
        variant="primary"
        class="ml-auto"
        :disabled="isExportLoading"
      >
        <i class="fas fa-circle-notch fa-spin" v-if="isExportLoading" />
        <span v-else> Export Student Roster </span>
      </b-btn>
    </div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div v-for="item in [1, 2, 3]" :key="item" class="d-flex pt-2 px-1">
          <div class="avatar avatar-sm rounded o-hidden border">
            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
          </div>
          <div class="w-100 ml-2">
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </div>
        </div>
      </template>

      <i v-if="!registeredStudents.length">No student is registered yet.</i>

      <router-link
        v-else
        v-for="std in registeredStudents"
        :key="std.id"
        :to="{ name: 'manage-registration-profile', params: { id: std.id } }"
      >
        <div class="d-flex event-mod-item pt-3 px-1 clickable-item">
          <!-- <div class="avatar avatar-sm">
          <img :src="std.student.user.avatar_url || DefaultImg" alt="Avatar" class="avatar-img rounded-circle" />
        </div> -->
          <div class="mr-2 mt-3" @click.stop>
            <b-dropdown no-flip variant="flush" toggle-class="text-muted" no-caret>
              <template #button-content>
                <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                <span class="sr-only">Menu</span>
              </template>
              <b-dropdown-item
                @click.prevent="
                  () => {
                    std.status === REGISTRATION_TYPES.ACTIVE ? cancelRegistration(std) : reactivateRegistration(std.id);
                  }
                "
                ><i
                  class="material-icons mr-1"
                  :class="std.status === REGISTRATION_TYPES.CANCELLED ? 'text-success' : 'text-danger'"
                  >{{ std.status === REGISTRATION_TYPES.CANCELLED ? 'check_circle_outline' : 'block' }}</i
                >{{
                  std.status === REGISTRATION_TYPES.ACTIVE ? 'Cancel Registration' : 'Reactivate Registration'
                }}</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <user-avatar
            slot="aside"
            :user="std.student.user"
            size="lg"
            :isLoading="isLoading"
            :file-url="std.student.user.avatar_url"
            variant="dark"
            rounded="false"
          >
          </user-avatar>
          <div class="mb-3">
            <strong class="ml-2 text-black font-weight-bolder"
              >{{ std.student.user.first_name }} {{ std.student.user.last_name }}</strong
            >
            <div class="ml-2 text-break text-black font-weight-bolder">{{ std.student.user.email }}</div>
            <div class="ml-2 text-break">
              <b class="text-black">Registered On:</b> {{ formatDateTime(std.created_at) }}
            </div>
            <div class="ml-2 text-break" v-if="get(std, 'plan_to_pay')">
              <b class="text-black">Plan to Pay:</b> {{ $t(`planToPayOptions.${std.plan_to_pay}`) }}
            </div>
            <div class="ml-2" v-if="std.status === REGISTRATION_TYPES.CANCELLED">
              <b-badge class="" pill variant="danger">Canceled</b-badge>
            </div>
          </div>
        </div>
      </router-link>
    </b-skeleton-wrapper>

    <pagination
      v-model="currentPage"
      :total-rows="totalRegistrations"
      :per-page="perPage"
      @change="onPageChange"
      aria-controls="reg-students-table"
      class="mt-2"
    />

    <reg-status-modal :show-modal="showRegStatusModal" @close="hideRegStatusModal" :registration="registration" />
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import UserAvatar from '@/components/User/UserAvatar.vue';

import { formatDateTime } from '@/common/utils';
import Pagination from '../../../../components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, REGISTRATION_TYPES } from '@/common/constants';
import RegStatusModal from './RegStatusModal.vue';

export default {
  name: 'RegisteredStudentsModal',

  components: { UserAvatar, Pagination, RegStatusModal },

  props: {
    showModal: { type: Boolean, default: false },
    selectedClass: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      registeredStudents: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalRegistrations: 0,
      REGISTRATION_TYPES,
      registration: {},
      showRegStatusModal: false,
      isExportLoading: false,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;
        this.fetchRegistrations();
        this.$refs.registeredStudentsModal.show();
      }
    },
  },

  methods: {
    ...mapActions('classes', ['getClassRegistration', 'getStudentRosters']),
    ...mapActions('student', ['updateStdRegStatus']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    formatDateTime,
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.registeredStudentsModal.hide();
      this.hideModal();
    },
    hideRegStatusModal(no) {
      this.showRegStatusModal = false;
      if (!no) {
        this.fetchRegistrations();
      }
    },
    cancelRegistration(registration) {
      this.showRegStatusModal = true;
      this.registration = registration;
    },
    async reactivateRegistration(id) {
      const data = {
        status: REGISTRATION_TYPES.ACTIVE,

        student_registration: id,
      };

      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to reactivate registration for this student?`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateStdRegStatus(data);
          this.fetchRegistrations();
        }
      } catch (e) {
        //
      }
    },
    async downloadCSV(url) {
      try {
        await this.downloadFile({
          fileUrl: url,
        });
        const h = this.$createElement;
        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (error) {
        //
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchStudentRosters() {
      this.isExportLoading = true;
      const response = await this.getStudentRosters({
        class_id: this.selectedClass.id,
      });

      this.downloadCSV(response.data.url);
      this.isExportLoading = false;
    },

    async fetchRegistrations(pageNum = 1) {
      this.isLoading = true;
      const response = await this.getClassRegistration({
        id: this.selectedClass.id,
        params: { limit: this.perPage, offset: (pageNum - 1) * this.perPage },
      });
      this.registeredStudents = response.data.results;
      this.currentPage = pageNum;
      this.totalRegistrations = response.data.count;
      this.isLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchRegistrations(pageNum);
    },
  },

  async mounted() {},
};
</script>
