<template>
  <div class="container page__container page-section">
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="student-page">
          <div class="container page__container d-flex align-items-center">
            <p class="backResult text-50">
              <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>
              </a>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-5 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">person</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">email</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">phone</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>
    <div v-else>
      <div class="d-flex align-items-center">
        <p class="backResult text-50">
          <a @click.prevent="goBack" style="text-decoration: underline" class="clickable-item">
            <span class="material-icons icon-18pt text-80">navigate_before</span>
          </a>
        </p>
      </div>
      <h2>Refund Request for {{ get(refundReq, 'student.name') }}</h2>

      <div class="d-flex flex-column flex-md-row align-items-center text-center text-md-left">
        <user-avatar size="7.3rem" rounded hide-text class="mr-md-32pt mb-32pt mb-md-0 mobilemb10" />

        <div class="flex mb-32pt mb-md-0 mobilemb0">
          <div class="mb-2" style="font-size: 16px">
            <img
              style="width: 30px; height: 30px"
              :src="get(refundReq, 'school.logo_url')"
              :alt="get(refundReq, 'school.name')"
            />
            <b>
              {{ get(refundReq, 'school.name') }}
            </b>
          </div>

          <div class="mb-2">
            <b-badge
              :class="
                'border border-' +
                  (get(refundReq, 'status') === REFUND_STATUSES.APPROVED
                    ? `success text-success`
                    : get(refundReq, 'status') === REFUND_STATUSES.PENDING
                    ? 'warning text-warning'
                    : get(refundReq, 'status') === REFUND_STATUSES.DECLINED
                    ? `danger text-danger`
                    : 'black')
              "
              variant="none"
              >{{
                get(refundReq, 'status') === REFUND_STATUSES.APPROVED && get(refundReq, 'is_reg_cancelled')
                  ? 'Approved and Registration Cancelled'
                  : get(refundReq, 'status') === REFUND_STATUSES.APPROVED
                  ? 'Approved'
                  : get(refundReq, 'status') === REFUND_STATUSES.PENDING
                  ? 'Pending'
                  : get(refundReq, 'status') === REFUND_STATUSES.CANCELLED
                  ? 'Cancelled'
                  : 'Declined'
              }}</b-badge
            >
          </div>

          <b-dropdown text="Contact Student" variant="primary" class="mr-2">
            <b-dropdown-item :href="`mailto:${get(refundReq, 'student.email')}`"
              ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
            >
            <b-dropdown-item :href="`tel:${get(refundReq, 'student.phone')}`"
              ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
            >
          </b-dropdown>

          <span v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
            <b-dropdown
              text="Actions"
              variant="primary"
              v-if="get(refundReq, 'status') !== REFUND_STATUSES.CANCELLED && !get(refundReq, 'is_reg_cancelled')"
            >
              <b-dropdown-item
                v-if="get(refundReq, 'status') !== REFUND_STATUSES.APPROVED && !get(refundReq, 'is_reg_cancelled')"
                @click.prevent="openAppStatusModal(get(refundReq, 'refund_reason'), false, get(refundReq, 'status'))"
                ><i class="material-icons mr-1 text-success">check_circle_outline</i>Approve</b-dropdown-item
              >
              <b-dropdown-item
                v-if="get(refundReq, 'status') === REFUND_STATUSES.PENDING"
                @click.prevent="() => (showDecStatusModal = true)"
                ><i class="material-icons mr-1 text-danger">block</i>Decline</b-dropdown-item
              >
              <b-dropdown-item
                @click.prevent="openAppStatusModal(get(refundReq, 'refund_reason'), true, get(refundReq, 'status'))"
                ><i
                  :class="get(refundReq, 'status') === REFUND_STATUSES.APPROVED ? 'text-danger' : 'text-success'"
                  class="material-icons mr-1"
                  >{{ get(refundReq, 'status') === REFUND_STATUSES.APPROVED ? 'block' : 'check_circle' }}</i
                >{{
                  `${get(refundReq, 'status') === REFUND_STATUSES.APPROVED ? '' : 'Approve and'} Cancel
                Registration`
                }}</b-dropdown-item
              >
            </b-dropdown>
          </span>
          <b-dropdown text="Actions" variant="primary" v-else-if="get(refundReq, 'status') === REFUND_STATUSES.PENDING">
            <b-dropdown-item @click.prevent="cancelReq()"
              ><i class="material-icons mr-1 text-danger">block</i>Cancel Request</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>

      <div class="mt-4" v-if="get(refundReq, 'status') === REFUND_STATUSES.DECLINED">
        <b-alert show variant="danger">
          <b>Declined Reason:</b>
          <div class="mt-2">{{ get(refundReq, 'review_reason') }}</div>
        </b-alert>
      </div>

      <div class="student-card mt-0 pt-0 mb-32pt">
        <div class="card my-4">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Refund Information</h5>
            </div>
            <h6 class="icon-align">
              <i class="material-icons icon-18pt mr-8pt">class</i>Class ID:
              <b class="ml-2">#{{ get(refundReq, 'registration.class_enrolled') }}</b>
            </h6>
            <h6 class="icon-align">
              <i class="material-icons icon-18pt mr-8pt">date_range</i>Requested On:
              <b class="ml-2">{{ formatFullDate(get(refundReq, 'created_at')) }}</b>
            </h6>
            <h6 class="icon-align">
              <i class="material-icons icon-18pt mr-8pt">attach_money</i>Amount:
              <b class="ml-2 text-primary">{{ $n(get(refundReq, 'amount'), 'currency', 'en-US') }}</b>
            </h6>
            <h6 class="icon-align" v-if="get(refundReq, 'review_date')">
              <i class="material-icons icon-18pt mr-8pt">date_range</i>Reviewed On:
              <b class="ml-2">{{ formatFullDate(get(refundReq, 'review_date')) }}</b>
            </h6>
            <h6 class="icon-align" v-if="get(refundReq, 'reviewed_by')">
              <i class="material-icons icon-18pt mr-8pt">person</i>Reviewed By:
              <b class="ml-2"
                >{{ get(refundReq, 'reviewed_by.first_name') }} {{ get(refundReq, 'reviewed_by.last_name') }}</b
              >
            </h6>
            <h6 class="icon-align mb-2"><i class="material-icons icon-18pt mr-8pt">text_fields</i>Reason:</h6>
            <div class="ml-4 p-2" style="background: #f9f9f9">
              <b class="">{{ get(refundReq, 'refund_reason') }}</b>
            </div>
          </div>
        </div>
        <div class="mb-2" v-if="plans.length">
          <div class="card">
            <div class="card-body">
              <div class="flex">
                <div>
                  <h2 class="form-label">Payment Plans</h2>
                </div>

                <b-table
                  :fields="plansTableColumns"
                  :items="plans"
                  :busy="arePlansLoading"
                  head-variant="light"
                  hover
                  responsive
                  no-local-sorting
                >
                  <template #cell(status)="data">
                    <b-badge
                      variant="none"
                      :class="
                        (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                        (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                          ? 'border border-success text-success'
                          : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                          ? 'border border-warning text-warning'
                          : 'border border-danger text-danger'
                      "
                    >
                      <strong>{{
                        (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                        (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                          ? 'Paid'
                          : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                          ? 'Cancelled'
                          : 'Unpaid'
                      }}</strong>
                    </b-badge>
                  </template>

                  <template #cell(amount)="data"> {{ $n(get(data, 'item.amount'), 'currency', 'en-US') }} </template>

                  <template #cell(date)="data"> {{ formatFullDate(get(data, 'item.date')) }} </template>

                  <template #cell(date_paid)="data"> {{ formatFullDate(get(data, 'item.date_paid')) }} </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4" v-if="payments.length">
          <div class="card">
            <div class="card-body">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">Payment Log</h5>
                </div>
                <div class="d-flex">
                  <b-table
                    :fields="tableColumns"
                    :items="payments"
                    :busy="arePlansLoading"
                    head-variant="light"
                    hover
                    responsive
                    no-local-sorting
                  >
                    <template #cell(type)="data"> {{ $t(`transactionTypes.${data.item.type}`) }} </template>

                    <template #cell(amount)="data">{{ formatAmount(data.item.amount) }}</template>

                    <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <declined-status-modal :show-modal="showDecStatusModal" @close="hideDecStatusModal" @onDeclined="updateStatus" />
    <approve-status-modal
      :show-modal="showAppStatusModal"
      @close="hideAppStatusModal"
      :regCancel="regCancel"
      :status="status"
      :refundReason="refundReason"
      @onApprove="updateStatus"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { formatFullDate } from '@/common/utils';
import { REFUND_STATUSES, USER_ROLES, STUDENT_PAYMENT_TYPES } from '@/common/constants';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import DeclinedStatusModal from './DeclinedStatusModal.vue';
import ApproveStatusModal from './ApproveStatusModal.vue';
import AmountFormatter from '@/mixins/amount-formatter';

export default {
  extends: Page,
  components: { UserAvatar, DeclinedStatusModal, ApproveStatusModal },
  mixins: [AmountFormatter],

  data() {
    return {
      payments: [],
      plans: [],
      isLoading: false,
      arePlansLoading: false,
      showDecStatusModal: false,
      showAppStatusModal: false,
      regCancel: false,
      refundReason: '',
      refundReq: {},
      status: '',
      REFUND_STATUSES,
      USER_ROLES,
      STUDENT_PAYMENT_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading;
    },
    plansTableColumns() {
      return [
        { key: 'status', label: 'status' },
        { key: 'amount', label: 'Amount' },
        { key: 'date', label: 'Date' },
        { key: 'date_paid', label: 'Due Date' },
      ];
    },

    tableColumns() {
      return [
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },

  methods: {
    ...mapActions('refunds', ['getRefundReq', 'updateRefundReqStatus', 'cancelRefundReq']),
    ...mapActions('payoutAcc', ['getPayments', 'getPlansToRefund']),
    get,
    formatFullDate,

    goBack() {
      this.$router.go(-1);
    },

    async fetchPlans() {
      this.arePlansLoading = true;
      try {
        const response = await this.getPlansToRefund({ reg_id: this.refundReq.registration.id });

        this.plans = response.data;
      } catch (error) {
        //
      }
      this.arePlansLoading = false;
    },

    async fetchPayments() {
      this.arePlansLoading = true;
      try {
        const response = await this.getPayments({ reg_id: this.refundReq.registration.id });

        this.payments = response.data;
      } catch (error) {
        //
      }
      this.arePlansLoading = false;
    },

    async fetchRefundReq() {
      this.isLoading = true;
      try {
        const response = await this.getRefundReq(this.$route.params.id);

        this.refundReq = response.data;
      } catch (error) {
        //
      }
      this.isLoading = false;
    },

    async updateStatus(data) {
      try {
        await this.updateRefundReqStatus({
          id: this.refundReq.id,
          data,
        });

        this.fetchRefundReq();
      } catch (error) {
        //
      }
    },

    async cancelReq() {
      try {
        await this.cancelRefundReq(this.refundReq.id);
        this.fetchRefundReq();
      } catch (error) {
        //
      }
    },

    hideDecStatusModal() {
      this.showDecStatusModal = false;
    },

    openAppStatusModal(reason, regCancel = false, status = null) {
      this.regCancel = regCancel;
      this.refundReason = reason;
      this.status = status;
      this.showAppStatusModal = true;
    },

    hideAppStatusModal() {
      this.showAppStatusModal = false;
      this.fetchPlans();
    },

    onDeclined(data) {
      this.updateStatus(data);
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.fetchRefundReq();
      this.fetchPlans();
      this.fetchPayments();
    }
  },
};
</script>

<style></style>
