<template>
  <div>
    <basic-config />
  </div>
</template>

<script>
import BasicConfig from '@/components/WebsiteConfig/BasicConfig.vue';

export default {
  components: {
    BasicConfig,
  },
};
</script>