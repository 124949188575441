<template>
  <div>
    <b-modal ref="postLocationModal" hide-footer title="Add Location" centered lazy @hide="hideModal" size="lg">
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Address" label-for="address" label-class="form-label">
          <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="location.address" required />
        </b-form-group>

        <div class="row">
          <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
            <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="location.zipcode" required />
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
            <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="location.city" required />
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
            <v-select
              id="state"
              class="form-control v-select-custom"
              label="text"
              v-model="location.state"
              :reduce="(state) => state.value"
              placeholder="Select State"
              :options="stateOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!location.state" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
            <v-select
              id="country"
              class="form-control v-select-custom"
              label="text"
              disabled
              v-model="location.country"
              :reduce="(country) => country.value"
              :placeholder="$t('schoolMsgs.countryPlaceholder')"
              :options="countryOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!location.country" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>
        </div>
        <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>add</span>
        </b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { countries } from 'countries-list';
import { mapActions, mapGetters } from 'vuex';
import { map } from 'lodash';
var UsaStates = require('usa-states').UsaStates;
import vSelect from 'vue-select';
export default {
  props: {
    showModal: Boolean,
    orderId: { type: Number, required: false },
  },

  components: { vSelect },

  data() {
    return {
      location: {
        zipcode: '',
        address: '',
        city: '',
        state: '',
        country: 'United States',
      },
      countryOptions: [],
      stateOptions: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('location', ['createLocation']),
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.postLocationModal.hide();
      this.hideModal();
    },

    hideUpdateModal(location) {
      this.$refs.postLocationModal.hide();

      this.$emit('closeUpdate', location);
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          zipcode: this.location.zipcode,
          address: this.location.address,
          city: this.location.city,
          state: this.location.state,
          country: this.location.country,
          school: this.getLoggedInUser.linked_entity.id,
        };
        const res = (await this.createLocation({ ...data })).data.data;
        this.makeToast('success', this.$t('orderMsgs.orderAdded'));

        this.isLoading = false;
        this.hideUpdateModal(res);
      } catch (error) {
        this.makeToast('danger', this.$t('genErrorMsg'));
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, (country) => ({
        value: country.name,
        text: country.name,
      }))
    );
    this.$refs.postLocationModal.show();
  },
};
</script>

<style>
</style>