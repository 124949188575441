<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Template' : 'Add New Template'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="image" class="img-fluid" width="40" alt="Image" v-else-if="image" />
            <i class="fas fa-shopping-bag fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="(e) => setImage(e, 'image')"
            :isUploading="isImgUploading"
            :isRequired="false"
            placeholder="Select Image"
          />
          <!-- :required="!image" -->
        </b-media>
      </b-form-group>

      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter Template's Title" v-model="name" required />
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea id="description" placeholder="Enter Template's Description" :rows="5" v-model="description" />
      </b-form-group>

      <b-form-group label-for="prompt" label-class="form-label">
        <label
          >Prompt - <a href="#" class="text-primary" @click.prevent="openBookmarkDetails">Bookmark Details</a></label
        >
        <b-form-textarea id="prompt" required :rows="5" v-model="prompt" />
      </b-form-group>

      <b-form-group label-for="image-prompt" label-class="form-label">
        <label
          >Image Prompt -
          <a href="#" class="text-primary" @click.prevent="openBookmarkDetails">Bookmark Details</a></label
        >
        <b-form-textarea id="image-prompt" required :rows="5" v-model="imagePrompt" />
      </b-form-group>

      <b-form-group label="Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="category"
          :reduce="(category) => category.value"
          placeholder="Select Category"
          :options="categoryOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="active" name="check-button" switch> Active? </b-form-checkbox>
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="imageGeneration" name="check-button-1" switch> Image Generation? </b-form-checkbox>
      </b-form-group>

      <div v-if="imageGeneration">
        <div class="d-flex justify-content-end">
          <a class="btn btn-primary btn-md" href="#" @click.prevent="openCanvasModal()"> Add Canvas </a>
        </div>

        <div class="d-flex flex-wrap mt-2">
          <div style="position: relative" class="mr-2 mb-2" v-for="canvas in canvases" :key="canvas.id">
            <b-img
              :src="canvas.sample_image || canvas.image"
              class="img-fluid"
              @click.prevent="openPhotoModal(canvas.sample_image || canvas.image)"
              style="
                height: 13rem;
                width: 13rem;
                color: white;
                border-radius: 0.5rem;
                border: solid gray 0.1px !important;
                object-fit: cover;
                min-width: 5rem;
                cursor: pointer;
              "
              alt="Logo"
            />
            <div
              class="clickable-item canvas-btn"
              @click.prevent="openCanvasModal(canvas)"
              style="background-color: #2a2a2a; position: absolute; top: 4px; right: 40px; font-size: 12px"
            >
              <i style="color: white" class="fas fa-pencil-alt"></i>
            </div>

            <div
              class="clickable-item canvas-btn"
              @click.prevent="removeVisual($route.params.id ? canvas.id : canvas.canvasId)"
              style="background-color: #2a2a2a; position: absolute; top: 4px; right: 4px; font-size: 15px"
            >
              <i style="color: white" class="fas fa-times"></i>
            </div>
          </div>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('save') : $t('save') }}</span>
      </b-btn>
    </b-form>
    <bookmark-detail-modal :show-modal="showBookmarkDetails" @close="hideBookmarkDetails" :abbr="'prompt'" />
    <canvas-modal
      :show-modal="showCanvas"
      @close="hideCanvasModal"
      @canvasData="canvasData"
      @canvasAdded="canvasAdded"
      @updateCanvasData="updateCanvasData"
      :canvas="canvas"
      :template="tempId"
    />
    <photo-modal :show-modal="showPhotoModal" :photo="photoUrl" @close="hidePhotoModal" />
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PhotoModal from './PhotoModal.vue';
import Page from '@/components/Page.vue';
import CanvasModal from './CanvasModal.vue';
import { mapGetters, mapActions } from 'vuex';
import { AI_BOOKMARKS } from '@/common/constants';
import BookmarkDetailModal from './BookmarkDetailModal.vue';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    BookmarkDetailModal,
    CanvasModal,
    PhotoModal,
    vSelect,
    FmvAvatar,
    ImageUploader,
  },
  name: 'AddTemplate',
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Template' : 'Add New Template',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      name: '',
      active: true,
      prompt: '',
      imagePrompt: '',
      description: '',
      isLoading: false,
      image: '',
      isImgUploading: false,
      category: '',
      uploadFile: { image: null },
      uploadPercent: { image: 0 },
      uploadCancelTokenSource: { image: null },
      stateOptions: [],
      showBookmarkDetails: false,
      showCanvas: false,
      AI_BOOKMARKS,
      categoryOptions: [
        { value: 'social_media', text: 'Social Media' },
        { value: 'blog', text: 'Blog' },
        { value: 'email_marketing', text: 'Email Marketing' },
      ],
      imageGeneration: false,
      canvases: [],
      canvas: {},
      tempId: null,
      showPhotoModal: false,
      photoUrl: '',
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'States',
          to: {
            name: 'sa-manage-states',
          },
        },
        {
          text: this.$route.params.id ? 'Edit Template' : 'Add Template',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isImgUploading;
    },
  },

  methods: {
    ...mapActions('myAI', [
      'getTemplate',
      'createTemplate',
      'updateTemplate',
      'getTemplateImageUploadPresignedUrl',
      'deleteTemplateCanvas',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    openBookmarkDetails() {
      this.showBookmarkDetails = true;
    },
    openCanvasModal(canvas = {}) {
      this.showCanvas = true;
      this.canvas = canvas;
    },
    hideBookmarkDetails() {
      this.showBookmarkDetails = false;
    },
    hideCanvasModal() {
      this.showCanvas = false;
    },
    hidePhotoModal() {
      this.showPhotoModal = false;
    },
    openPhotoModal(photo) {
      this.showPhotoModal = true;
      this.photoUrl = photo;
    },
    canvasData(canvas) {
      this.canvases.push({ ...canvas, canvasId: this.canvases.length });
    },
    updateCanvasData(updatedCanvas) {
      const canvas = this.canvases.find((canv) => canv.canvasId == updatedCanvas.canvasId);
      Object.assign(canvas, updatedCanvas);
    },
    canvasAdded() {
      this.fetchTemplate();
    },
    async removeVisual(index) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected image.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          if (this.$route.params.id) {
            await this.deleteTemplateCanvas(index);

            this.fetchTemplate();
          } else {
            this.canvases = this.canvases.filter((canv) => canv.canvasId !== index);
          }
          setTimeout(() => {
            this.makeToast({ variant: 'success', msg: 'Canvas successfully deleted!' });
          }, [1000]);
        }
      } catch (e) {
        // TODO: handle error
      }
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getTemplateImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          title: this.name,
          description: this.description,
          active: this.is_active,
          image: this.image,
          prompt: this.prompt,
          category: this.category,
          image_gen_enable: this.imageGeneration,
          ...(!this.$route.params.id && { template_canvas: this.canvases }),
          image_prompt: this.imagePrompt,
        };
        if (this.$route.params.id) {
          await this.updateTemplate({
            id: this.$route.params.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: this.$t('Template Updated!') });
        } else {
          await this.createTemplate({
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'Template Added!' });
        }
        setTimeout(
          () =>
            this.$router.push({
              name: 'manage-templates',
            }),
          250
        );
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    async fetchTemplate() {
      const resp = (await this.getTemplate(this.$route.params.id)).data;
      this.name = resp.title;
      this.description = resp.description;
      this.active = resp.is_active;
      this.image = resp.image;
      this.prompt = resp.prompt;
      this.category = resp.category;
      this.imageGeneration = resp.image_gen_enable;
      this.canvases = resp.template_canvas;
      this.imagePrompt = resp.image_prompt;
      this.tempId = resp.id;
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        this.fetchTemplate();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'manage-templates',
      });
    }

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.canvas-btn {
  height: 30px;
  width: 30px;
  border: solid 1px #d1c4e9;
  border-radius: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>