<template>
  <div>
    <div :class="containerClass">
      <div class="row align-items-center mt-4" style="white-space: nowrap">
        <div class="col-lg-auto">
          <h3>Home Dashboard</h3>
        </div>

        <div class="col-lg d-flex flex-wrap justify-content-end">
          <b-btn :to="{ name: 'school-enrollment-report' }" exact variant="primary"
            ><i class="material-icons mr-2">insert_chart </i> Enrollment Analytics</b-btn
          >
        </div>
      </div>
    </div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div>
          <b-alert v-for="notification in pendingNotifications" :key="notification.id" show>
            <div class="d-flex">
              <router-link
                :to="{
                  name: 'feature-update-details',
                  params: { id: notification.extra.app_update_id },
                  query: { notification: notification.id },
                }"
              >
                {{ notification.extra.notification_title }}
              </router-link>
              <div class="ml-auto clickable-item" @click.prevent="setNotificationRead(notification.id)">
                <md-icon>close</md-icon>
              </div>
            </div>
          </b-alert>
          <div class="text-center" v-if="moreNotifications > 0">
            <router-link :to="{ name: 'feature-updates-page' }"> View {{ moreNotifications }} more </router-link>
          </div>
        </div>
        <div class="card border-left-0">
          <div class="card-body">
            <div class="mb-3">
              <h5 class="card-title fw600">Filter by Date(s)</h5>
            </div>

            <div>
              <date-picker
                v-model="filters.dateRange"
                format="MMMM DD, YYYY"
                valueType="YYYY-MM-DD"
                style="width: 100%"
                id="date"
                lang="en"
                placeholder="Filter by Start Date - End Date Range"
                range
                class="w-100 mb-3"
                @input="updateFilters"
              ></date-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalPrograms">
                  <template #loading>
                    <h3 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h3>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalPrograms) }}</h4>
                </b-skeleton-wrapper>
                <div>Programs</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalClasses">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalClasses) }}</h4>
                </b-skeleton-wrapper>
                <div>Classes</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalInstructors">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalInstructors) }}</h4>
                </b-skeleton-wrapper>
                <div>Instructors</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalRegistrations">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalRegistrations) }}</h4>
                </b-skeleton-wrapper>
                <div>Registrations</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalStudents">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalStudents) }}</h4>
                </b-skeleton-wrapper>
                <div>Students</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalProducts">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ $n(totalProducts) }}</h4>
                </b-skeleton-wrapper>
                <div>Products</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

import Page from '@/components/Page.vue';
import { formatToAPIDate } from '../../../common/utils';

export default {
  components: { DatePicker },
  extends: Page,

  data() {
    return {
      title: 'Home Dashboard',

      totalRegistrations: 0,
      totalStudents: 0,
      totalPrograms: 0,
      totalClasses: 0,
      totalInstructors: 0,
      totalProducts: 0,

      filters: {
        dateRange: [],
      },

      isLoading: {
        totalRegistrations: false,
        totalStudents: false,
        totalPrograms: false,
        totalClasses: false,
        totalInstructors: false,
        totalProducts: false,
      },
      areNotificationsLoading: false,
      allNotifications: [],
      pendingNotifications: [],
      moreNotifications: 0,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('school', []),
    ...mapActions('program', ['getSchoolProgramAggregate']),
    ...mapActions('student', ['getSchoolStudentAggregate', 'getSchoolRegAggregate']),
    ...mapActions('classes', ['getSchoolClassesAggregate']),
    ...mapActions('instructor', ['getSchoolInstructorsAggregate']),
    ...mapActions('products', ['getAllProducts']),
    ...mapActions('notifications', ['getAllNotifications', 'updateNotification']),

    async fetchStudentsCount() {
      try {
        this.isLoading.totalStudents = true;
        const response = await this.getSchoolStudentAggregate({});
        this.totalStudents = response.data.count;
        this.isLoading.totalStudents = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchProgramsCount() {
      try {
        this.isLoading.totalPrograms = true;
        const response = await this.getSchoolProgramAggregate({});
        this.totalPrograms = response.data.count;
        this.isLoading.totalPrograms = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchClassesCount() {
      try {
        this.isLoading.totalClasses = true;
        const response = await this.getSchoolClassesAggregate({
          ...(get(this.filters.dateRange, '0') && {
            start_date_from: this.filters.dateRange[0],
            start_date_to: this.filters.dateRange[1],
          }),
        });
        this.totalClasses = response.data.count;
        this.isLoading.totalClasses = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchRegistrationCount() {
      try {
        this.isLoading.totalRegistrations = true;
        const response = await this.getSchoolRegAggregate({
          ...(get(this.filters.dateRange, '0') && {
            start_date: this.filters.dateRange[0],
            end_date: this.filters.dateRange[1],
          }),
        });
        this.totalRegistrations = response.data.count;
        this.isLoading.totalRegistrations = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchInstructorsCount() {
      try {
        this.isLoading.totalInstructors = true;
        const response = await this.getSchoolInstructorsAggregate({});
        this.totalInstructors = response.data.count;
        this.isLoading.totalInstructors = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchProductsCount() {
      try {
        this.isLoading.totalProducts = true;
        const response = await this.getAllProducts({ limit: 1, offset: 0 });
        this.totalProducts = response.data.count;
        this.isLoading.totalProducts = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    updateFilters() {
      this.fetchRegistrationCount();
      this.fetchClassesCount();
    },

    async fetchNotifications() {
      this.areNotificationsLoading = true;
      const response = await this.getAllNotifications({
        notification_type__type_abbreviation: 'app_feature_update',
      });
      if (response.data) {
        this.allNotifications = response.data;

        const unReadNotification = (this.pendingNotifications = this.allNotifications.filter(
          (notification) => notification.is_read === false
        ));

        this.pendingNotifications = this.allNotifications
          .filter((notification) => notification.is_read === false)
          .slice(0, 3);

        this.moreNotifications = unReadNotification.length - this.pendingNotifications.length;
      }

      this.areNotificationsLoading = false;
    },

    async setNotificationRead(id) {
      const data = { is_read: true };
      await this.updateNotification({ id: id, data });
      this.fetchNotifications();
    },
  },

  async mounted() {
    this.filters.dateRange = [formatToAPIDate(moment().startOf('month')), formatToAPIDate(moment().endOf('month'))];
  
    this.fetchProgramsCount();
    this.fetchStudentsCount();
    this.fetchClassesCount();
    this.fetchRegistrationCount();
    this.fetchInstructorsCount();
    this.fetchProductsCount();
    this.fetchNotifications();
  },
};
</script>
