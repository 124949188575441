<template>
  <div :class="containerClass">
    <page-header
      :title="
        this.$route.params.id
          ? 'Edit Section'
          : this.$route.name === 'add-pre-enroll-section' || this.$route.name === 'edit-pre-enroll-section'
          ? 'Add New Section for Pre-enrollment'
          : 'Add New Section for Registration'
      "
      :container-class="null"
      class="mb-16pt"
    />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter Section's Title" v-model="form.title" :disabled="isFormLoading" />
        <b-form-invalid-feedback :state="!$v.form.title.required && $v.form.title.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator title="Questions" />

      <!-- TODO: Drag and drop questions list -->
      <div class="d-flex justify-content-end mb-3">
        <b-btn class="mr-2" variant="primary" @click.prevent="openQuestionModal" size="sm" :disabled="isFormLoading">
          <span>Add Question</span>
        </b-btn>
      </div>

      <div class="row mb-lg-12pt" v-if="questions.length">
        <draggable
          class="col-sm-12 draggable-cursor"
          @start="dragging = true"
          @end="dragging = false"
          :list="questions"
        >
          <div v-for="(question, index) in questions" :key="question.id">
            <ques-card :question="question" :index="index" @removeQuestion="removeQuestion" />
          </div>
        </draggable>
      </div>

      <b-form-invalid-feedback
        class="mb-lg-12pt"
        v-if="(!$v.questions.required || !$v.questions.minLength) && $v.questions.$dirty"
        :state="(!$v.questions.required || !$v.questions.minLength) && $v.questions.$dirty ? false : null"
        >There isn't any question added. Please add some questions.</b-form-invalid-feedback
      >
      <div class="mb-lg-12pt" v-else-if="!questions.length">
        <i>There isn't any question added.</i>
      </div>
      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>

    <add-question-modal
      :show-modal="showQuestionModal"
      @close="hideQuestionModal"
      @update="updateQuestions"
      :questions="questionOptions"
      :selected-questions="questions"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { map } from 'lodash';
import Page from '@/components/Page.vue';
import AddQuestionModal from './AddQuestionModal.vue';
import QuesCard from './QuesCard.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  extends: Page,
  components: {
    AddQuestionModal,
    QuesCard,
    draggable,
    PageSeparator,
    PageHeader,
  },
  data() {
    return {
      title: this.$route.params.id ? 'Edit Section' : 'Add Section',

      form: {
        title: '',
      },
      questions: [],
      isLoading: false,
      dragging: false,
      isSectionLoading: false,
      showQuestionModal: false,
      questionOptions: [],
      areQuestionsLoading: false,
    };
  },
  validations() {
    return {
      form: { title: { required } },
      questions: { required, minLength: minLength(1) },
    };
  },
  computed: {
    isFormLoading() {
      return this.isLoading || this.isSectionLoading || this.areQuestionsLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Manage Registration Form',
          to: { name: 'manage-registration-form', params: { id: this.$route.params.program } },
        },
        {
          text: this.$route.params.id ? 'Edit Section' : 'Add Section',
          active: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions('sections', ['createSection', 'updateSection', 'getSection']),
    ...mapActions('registrationForm', ['getCustomQuestions']),
    dragOver() {
      document.documentElement.style.cursor = '-webkit-grabbing';
    },
    async fetchQuestions() {
      this.areQuestionsLoading = true;
      try {
        const resp = await this.getCustomQuestions({});
        this.questionOptions = resp.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areQuestionsLoading = false;
    },
    openQuestionModal() {
      this.showQuestionModal = true;
    },
    hideQuestionModal() {
      this.showQuestionModal = false;
    },
    async fetchSection() {
      this.isSectionLoading = true;
      try {
        const resp = (await this.getSection(this.$route.params.id)).data;
        this.form.title = resp.title;
        this.questions = map(resp.sectionquestion_set, (secQue) => ({
          ...secQue.question,
          required: secQue.is_required,
        }));
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isSectionLoading = false;
    },

    updateQuestions(obj) {
      this.showQuestionModal = false;
      this.questions.push(obj);
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.form.title,
            program: parseInt(this.$route.params.program),
            section_enroll_type:
              this.$route.name === 'add-pre-enroll-section' || this.$route.name === 'edit-pre-enroll-section'
                ? 'pre_enrollment'
                : 'enrollment',
            questions: map(this.questions, (que, index) => ({
              question: que.id,
              is_required: que.required,
              order_num: index + 1,
            })),
          };

          if (this.$route.params.id) {
            await this.updateSection({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Section Updated!' });
          } else {
            await this.createSection({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Section Added!' });
          }

          setTimeout(
            () =>
              this.$router.push({
                name:
                  this.$route.name === 'add-pre-enroll-section' || this.$route.name === 'edit-pre-enroll-section'
                    ? 'manage-pre-enrollment-form'
                    : 'manage-registration-form',
                params: { id: this.$route.params.program },
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.fetchSection();
    }
    this.fetchQuestions();
  },
};
</script>
