<template>
  <b-modal ref="payToPlanModal" hide-footer title="Payment" centered lazy @hide="hideModal" size="lg">
    <b-form class="col-md-12 px-0 pt-0" @submit.prevent="onSubmit">
      <b-form-group label-for="plan_to_pay" label-class="form-label">
        <div
          @click.prevent="
            () => {
              plan_to_pay = option.value;
            }
          "
          style="cursor: pointer"
          class="card p-3 mb-2"
          v-for="option in stdPlanToPayOptions(get(this.getCurrentSchool, 'tuition_fee_name'))"
          :key="option.value"
        >
          <b-form-radio
            size="lg"
            name="plan-to-pay-radio"
            :value="option.value"
            v-model="plan_to_pay"
            :class="!$v.plan_to_pay.required && $v.plan_to_pay.$dirty ? 'form-control is-invalid' : ''"
          >
            <i style="font-size: 23px" :class="`fas ${option.icon} text-primary mr-1 mb-1`" />
            <span style="font-size: 16px">
              {{ option.text }}
            </span>
          </b-form-radio>
          <span :class="option.description ? 'mt-2' : ''">
            {{ option.description }}
          </span>
          <div v-if="plan_to_pay === 'sponsored_employer_agency' && option.value === 'sponsored_employer_agency'">
            <v-select
              id="sponsorship_select"
              class="form-control v-select-custom my-2"
              label="title"
              v-model="selectedSponsorship"
              :reduce="sponsorship => sponsorship"
              placeholder="Select sponsorship"
              :options="sponsorshipOptions"
              :clearable="false"
              :class="
                !$v.selectedSponsorship.required && $v.selectedSponsorship.$dirty ? 'form-control is-invalid' : ''
              "
            >
            </v-select>
            <b-form-invalid-feedback
              :state="!$v.selectedSponsorship.required && $v.selectedSponsorship.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </div>
        </div>
        <b-form-invalid-feedback :state="!$v.plan_to_pay.required && $v.plan_to_pay.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ 'update' }}</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { stdPlanToPayOptions } from '@/common/utils';
import vSelect from 'vue-select';

export default {
  name: 'PayToPlanModal',
  components: {
    vSelect
  },

  props: {
    showModal: { type: Boolean, default: false },
    payment: { type: Object, default: () => {} }
  },

  data() {
    return {
      plan_to_pay: null,

      isLoading: false,
      selectedSponsorship: null,
      sponsorshipOptions: [],
      stdPlanToPayOptions
    };
  },

  validations() {
    const val = {
      plan_to_pay: { required },
      selectedSponsorship: { required: requiredIf(() => this.plan_to_pay === 'sponsored_employer_agency') }
    };

    return val;
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isFormLoading() {
      return this.isLoading;
    }
  },
  methods: {
    ...mapActions('student', ['updateStdPlanToPay']),
    ...mapActions('program', ['getExtScholarshipList']),
    get,

    async fetchScholarships(params = {}) {
      try {
        const response = await this.getExtScholarshipList({
          program_id: this.payment.program.id,
          ...params
        });

        this.sponsorshipOptions = response.data;
        this.sponsorshipOptions.push({
          title: 'Other',
          id: 'other'
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            reg_id: this.payment.id,
            plan_to_pay: this.plan_to_pay,
            ...(this.selectedSponsorship && { scholarship_name: this.selectedSponsorship.title })
          };

          await this.updateStdPlanToPay({
            ...data
          });
          this.makeToast({ variant: 'success', msg: 'Plan Updated Successfully!' });
        } catch (e) {
          // USE if needed
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.$refs.payToPlanModal.hide();
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.payToPlanModal.hide();
      this.hideModal();
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.fetchScholarships();

        if (this.payment) {
          this.plan_to_pay = this.payment.plan_to_pay;
          this.selectedSponsorship = get(this.payment, 'extra.scholarship_name') || null;
        }
        this.$refs.payToPlanModal.show();
      }
    }
  },
  async mounted() {}
};
</script>

<style></style>
