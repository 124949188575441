<template>
  <div :class="containerClass">
    <page-header
      title="Configure Basic Website Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Fine-tune your website's basic features for optimal performance.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
      <div v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? this.school : true">
        <template v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
          <page-separator title="Website Theme" />

          <b-form-group label="Theme" label-for="type" label-class="form-label">
            <v-select
              id="type"
              class="form-control v-select-custom"
              label="text"
              v-model="basicConfig.theme"
              :reduce="(type) => type.value"
              placeholder="Select theme"
              :options="typeOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>
        </template>

        <page-separator title="Website Scheme" />
        <b-form-group label="Font (Header + Body)" label-for="font" label-class="form-label">
          <v-select
            id="font"
            class="form-control v-select-custom"
            label="text"
            v-model="font"
            :reduce="(font) => font.value"
            placeholder="Select Header And Body Font"
            :options="allFonts"
            :clearable="false"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <span style="font-size: 14px">{{ option.text }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="d-flex align-items-center">
                <span style="font-size: 14px">{{ option.text }}</span>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group label="Color Scheme" label-for="type" label-class="form-label">
          <v-select
            id="type"
            class="form-control v-select-custom"
            label="text"
            v-model="basicConfig.color"
            :reduce="(type) => type.value"
            placeholder="Select color scheme"
            :options="colorTypeOptions"
            :clearable="false"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <page-separator title="Website Navbar" />

        <b-form-group label="Logo" label-for="image" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading">...</span>
              <b-img
                :src="basicConfig.logo_url"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="basicConfig.logo_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'image')"
              :isUploading="isImgUploading"
              :isRequired="false"
              placeholder="Select Logo"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Navbar Menu" label-class="form-label">
          <draggable class="draggable-cursor" @start="dragging = true" @end="updateOrder" :list="basicConfig.navMenu">
            <b-form-group
              v-for="(menu, index) in basicConfig.navMenu"
              :key="`${menu}-${index}`"
              label-class="form-label"
            >
              <div v-if="menu.name === 'about'" class="card card-lg overlay--primary-dodger-blue mb-1">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="col-8 col-md-8 my-2">
                      <i class="fas fa-bars icon-16pt mr-3"></i>

                      {{ menu.title }}
                    </div>
                    <div class="col-4 col-md-4 d-flex justify-content-end">
                      <b-form-checkbox class="ml-auto" v-model="menu.is_enable" name="check-button" switch>
                        Enabled?
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div
                    v-for="(sub_menus, aboutIndex) in menu.sub_menus"
                    :key="`${sub_menus}-${aboutIndex}`"
                    class="card-body d-flex flex-column mt-2"
                  >
                    <div class="d-flex align-items-center">
                      <div class="col-6 col-md-6 ml-4">
                        {{ sub_menus.title }}
                      </div>
                      <div class="col-4 col-md-4 d-flex justify-content-end">
                        <b-form-checkbox class="ml-auto" v-model="sub_menus.is_enable" name="check-button" switch>
                          Enabled?
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card card-lg overlay--primary-dodger-blue mb-1">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="col-8 col-md-8">
                      <i class="fas fa-bars icon-16pt mr-3"></i>

                      {{ menu.title }}
                    </div>
                    <div class="col-4 col-md-4 d-flex justify-content-end">
                      <b-form-checkbox class="ml-auto" v-model="menu.is_enable" name="check-button" switch>
                        Enabled?
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </b-form-group>
          </draggable>
        </b-form-group>

        <page-separator title="Website Top Bar" />

        <div class="row">
          <b-form-group
            v-for="(top, index) in basicConfig.topBar"
            :key="`${top}-${index}`"
            label-class="form-label"
            class="col-6"
          >
            <b-form-checkbox v-model="top.is_enable" name="check-button" switch>
              {{ $t(`topbarFields.${top.name}`) }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <page-separator>
          Website Placeholders
          <md-icon
            style="font-size: 20px; color: grey"
            class="svg-icon mr-2"
            v-b-popover.hover.right
            title="Will be used in various places like classes section, etc."
            >info</md-icon
          >
        </page-separator>

        <b-form-group
          label="Default Image"
          label-for="default-image"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isDefaultImgUploading">...</span>
              <b-img
                :src="basicConfig.defaultImageUrl"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="basicConfig.defaultImageUrl"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'default_image')"
              :isUploading="isDefaultImgUploading"
              :isRequired="false"
              placeholder="Select Image"
            />
          </b-media>
        </b-form-group>

        <page-separator title="Website Footer" />

        <b-form-group label="Logo" label-for="image" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isFooterImgLoading">...</span>
              <b-img
                :src="basicConfig.footer_logo_url"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="basicConfig.footer_logo_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'footer_image')"
              :isUploading="isFooterImgLoading"
              :isRequired="false"
              placeholder="Select Footer Logo"
            />
          </b-media>
        </b-form-group>

        <div class="row">
          <b-form-group
            v-for="(item, index) in basicConfig.footer"
            :key="`${index}-${item}`"
            label-class="form-label"
            class="col-6"
          >
            <b-form-checkbox v-model="item.is_enable" name="check-button" switch
              >{{ $t(`footer.${item.name}`) }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <b-form-group label="Footer Description" label-for="footer_desc" label-class="form-label">
          <b-form-textarea
            id="footer_desc"
            :rows="5"
            placeholder="Enter footer description (if any)"
            v-model.trim="basicConfig.footer_desc"
          />
        </b-form-group>

        <b-form-group label="Social Links" label-for="name" label-class="form-label">
          <div class="row ml-1 mb-2">
            <b-icon class="mr-3 mt-2 text-primary" scale="1.8" icon="facebook" aria-hidden="true"></b-icon>
            <fmv-input-group-merge
              class="col-11 no-padding"
              id="name"
              placeholder=""
              v-model="basicConfig.footerFacebook"
              prepend
            >
              {{ basicConfig.facebookUrl }}
            </fmv-input-group-merge>
          </div>

          <div class="row ml-1 mb-2 text-primary">
            <b-icon class="mr-3 mt-2" scale="1.8" icon="instagram" aria-hidden="true"></b-icon>
            <fmv-input-group-merge
              class="col-11 no-padding"
              id="name"
              placeholder=""
              v-model="basicConfig.footerInstagram"
              prepend
            >
              {{ basicConfig.instagramUrl }}</fmv-input-group-merge
            >
          </div>

          <div class="row ml-1 mb-2 text-primary">
            <b-icon class="mr-3 mt-2" scale="1.8" icon="twitter" aria-hidden="true"></b-icon>
            <fmv-input-group-merge
              class="col-11 no-padding"
              id="name"
              placeholder=""
              v-model="basicConfig.footerTwitter"
              prepend
            >
              {{ basicConfig.twitterUrl }}
            </fmv-input-group-merge>
          </div>

          <div class="row ml-1 mb-2 text-primary">
            <b-icon class="mr-3 mt-2" scale="1.8" icon="youtube" aria-hidden="true"></b-icon>
            <fmv-input-group-merge
              class="col-11 no-padding"
              id="name"
              placeholder=""
              v-model="basicConfig.footerYoutube"
              prepend
            >
              {{ basicConfig.youtubeUrl }}
            </fmv-input-group-merge>
          </div>

          <div class="row ml-1 mb-2 text-primary">
            <b-icon class="mr-3 mt-2" scale="1.8" icon="linkedin" aria-hidden="true"></b-icon>
            <fmv-input-group-merge
              class="col-11 no-padding"
              id="name"
              placeholder=""
              v-model="basicConfig.footerLinkedin"
              prepend
            >
              {{ basicConfig.linkedinUrl }}
            </fmv-input-group-merge>
          </div>
        </b-form-group>

        <page-separator>
          Meta Descriptions
          <md-icon
            style="font-size: 20px; color: grey"
            class="svg-icon mr-2"
            v-b-popover.hover.right
            title="Will be used for SEO purposes"
            >info</md-icon
          >
        </page-separator>

        <b-form-group label="Home" label-for="home-desc" label-class="form-label">
          <b-form-textarea
            id="home-desc"
            placeholder="Enter description"
            rows="3"
            maxlength="200"
            v-model="meta.homeDescription"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="About" label-for="about-desc" label-class="form-label">
          <b-form-textarea
            id="about-desc"
            placeholder="Enter description"
            rows="3"
            maxlength="200"
            v-model="meta.aboutDescription"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Gallery" label-for="gallery-desc" label-class="form-label">
          <b-form-textarea
            id="gallery-desc"
            placeholder="Enter description"
            rows="3"
            maxlength="200"
            v-model="meta.galleryDescription"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="FAQ" label-for="faq-desc" label-class="form-label">
          <b-form-textarea
            id="faq-desc"
            placeholder="Enter description"
            rows="3"
            maxlength="200"
            v-model="meta.faqDescription"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Tuition" label-for="tuition-desc" label-class="form-label">
          <b-form-textarea
            id="tuition-desc"
            placeholder="Enter description"
            rows="3"
            maxlength="200"
            v-model="meta.tuitionDescription"
          ></b-form-textarea>
        </b-form-group>

        <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Update</span>
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<style lang="css" scoped>
@import './style.css';
</style>

<script>
import { get, isEmpty, replace } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import vSelect from 'vue-select';
import { FmvInputGroupMerge } from 'fmv-input-group-merge';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    draggable,
    FmvAvatar,
    vSelect,
    FmvInputGroupMerge,
    PageSeparator,
    ImageUploader,
  },
  extends: Page,
  data() {
    return {
      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Basic Website Settings',
      isAdded: false,
      school: null,
      dragging: false,
      meta: {
        homeDescription: '',
        aboutDescription: '',
        galleryDescription: '',
        faqDescription: '',
        tuitionDescription: '',
      },
      basicConfig: {
        navMenu: [
          { name: 'homepage', is_enable: true },
          { name: 'program', is_enable: false },
          { name: 'location', is_enable: false },
          {
            name: 'about',
            is_enable: false,
            sub_menus: [
              { name: 'about_our_school', is_enable: false },
              { name: 'faqs', is_enable: false },
              { name: 'tuition', is_enable: false },
            ],
          },
          { name: 'gallery', is_enable: false },
          { name: 'contact', is_enable: false },
        ],
        topBar: [
          { name: 'phone', is_enable: true },
          { name: 'email', is_enable: true },
          { name: 'enroll_now', is_enable: true },
        ],
        footer: [
          { name: 'phone', is_enable: true },
          { name: 'email', is_enable: true },
          { name: 'address', is_enable: true },
          { name: 'website', is_enable: true },
        ],
        tuition_reg_req: false,
        logo_url: null,
        defaultImageUrl: null,
        footer_logo_url: null,
        footer_desc: null,
        theme: '',
        color: null,

        footerFacebook: null,
        footerYoutube: null,
        footerLinkedin: null,
        footerTwitter: null,
        footerInstagram: null,

        facebookUrl: 'https://www.facebook.com/',
        instagramUrl: 'https://www.instagram.com/',
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/',
        youtubeUrl: 'https://www.youtube.com/',
      },
      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,
      isDefaultImgUploading: false,
      isFooterImgLoading: false,
      typeOptions: [
        { value: 'agency', text: 'Agency' },
        { value: 'medical', text: 'Medical' },
      ],
      colorTypeOptions: [
        { value: 'light', text: 'Light' },
        { value: 'dark', text: 'Dark' },
      ],
      font: 'Inter+Roboto',
      allFonts: [
        { value: 'Inter+Roboto', text: 'Inter + Roboto' },
        { value: 'Roboto+Poppins', text: 'Roboto + Poppins' },
        { value: 'Arvo+Lato', text: 'Arvo + Lato' },
        { value: 'Merriweather+Mulish', text: 'Merriweather + Mulish' },
        { value: 'Work Sans+Open Sans', text: 'Work Sans + Open Sans' },
        { value: 'Oswald+Quattrocento', text: 'Oswald + Quattrocento' },
        { value: 'Ovo+Quattrocento', text: 'Ovo + Quattrocento' },
        { value: 'Ubuntu+Open Sans', text: 'Ubuntu + Open Sans' },
        { value: 'Slabo 13px+Lato', text: 'Slabo 13px + Lato' },
        { value: 'Rufina+PT Serif', text: 'Rufina + PT Serif' },
      ],
      uploadFile: { image: null, footer_image: null, default_image: null },
      uploadPercent: { image: 0, footer_image: 0, default_image: 0 },
      uploadCancelTokenSource: { image: null, footer_image: null, default_image: null },
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading || this.isFooterImgLoading;
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getBasicConfig', 'createBasicConfig', 'getLinkedImageUploadPresignedUrl']),
    get,
    isEmpty,

    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    async updateOrder() {
      // this.dragging = false;
      // const data = {
      //   custom_navbar_menu: map(this.items, (id, order_num) => ({
      //     id: id.id,
      //     order_num: order_num + 1,
      //   })),
      // };
      // try {
      //   await this.updateCustomNavbarItemOrder(data);
      // } catch (err) {
      //   this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      // }
    },
    resetData() {
      this.basicConfig.theme = null;
      this.basicConfig.color = null;

      this.basicConfig.footer_desc = null;
      this.basicConfig.logo_url = null;
      this.basicConfig.defaultImageUrl = null;
      this.basicConfig.navMenu = null;
      this.basicConfig.footer = null;
      this.basicConfig.footerTwitter = null;
      this.basicConfig.footerInstagram = null;
      this.basicConfig.footerYoutube = null;
      this.basicConfig.footerLinkedin = null;
      this.basicConfig.footerFacebook = null;
      this.basicConfig.topBar = null;
      this.meta.homeDescription = '';
      this.meta.aboutDescription = '';
      this.meta.galleryDescription = '';
      this.meta.faqDescription = '';
      this.meta.tuitionDescription = '';

      // this.basicConfig.school = null;
    },
    prefillLogo(schoolId) {
      this.resetData();
      this.fetchBasicConfig(schoolId);
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchBasicConfig(school_id = null) {
      let resp;
      if (school_id) {
        resp = await this.getBasicConfig({
          school_id: school_id,
        });
      } else {
        resp = await this.getBasicConfig({});
      }

      this.basicConfig.theme = resp.wbst_theme;
      this.basicConfig.color = resp.wbst_scheme;
      this.font = resp.font_option;
      this.basicConfig.logo_url = resp.navbar_logo;
      this.basicConfig.defaultImageUrl = resp.default_img_url;
      this.basicConfig.footer_logo_url = resp.footer_logo;
      this.basicConfig.navMenu = resp.navbar_attribs;
      this.basicConfig.footer = resp.footer_contact_attribs;
      this.basicConfig.footer_desc = resp.footer_desc;

      this.basicConfig.footerTwitter = replace(resp.twitter_url, this.basicConfig.twitterUrl, '');
      this.basicConfig.footerInstagram = replace(resp.insta_url, this.basicConfig.instagramUrl, '');
      this.basicConfig.footerYoutube = replace(resp.youtube_url, this.basicConfig.youtubeUrl, '');
      this.basicConfig.footerLinkedin = replace(resp.linkedin_url, this.basicConfig.linkedinUrl, '');
      this.basicConfig.footerFacebook = replace(resp.fb_url, this.basicConfig.facebookUrl, '');

      this.basicConfig.topBar = resp.topbar_fields;

      this.meta.homeDescription = resp.hmpg_meta_desc;
      this.meta.aboutDescription = resp.abtpg_meta_desc;
      this.meta.galleryDescription = resp.photos_meta_desc;
      this.meta.faqDescription = resp.faqs_meta_desc;
      this.meta.tuitionDescription = resp.tuition_meta_desc;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';
      this.isDefaultImgUploading = uploadType === 'default_image';
      this.isFooterImgLoading = uploadType === 'footer_image';

      try {
        const urlResp = await this.getLinkedImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.basicConfig.logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'footer_image') {
          this.basicConfig.footer_logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'default_image') {
          this.basicConfig.defaultImageUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
      if (uploadType === 'default_image') this.isDefaultImgUploading = false;
      if (uploadType === 'footer_image') this.isFooterImgLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            wbst_theme: this.basicConfig.theme,
            wbst_scheme: this.basicConfig.color,

            navbar_logo: this.basicConfig.logo_url,
            default_img_url: this.basicConfig.defaultImageUrl,
            navbar_attribs: this.basicConfig.navMenu,
            footer_logo: this.basicConfig.footer_logo_url,
            footer_contact_attribs: this.basicConfig.footer,
            footer_desc: this.basicConfig.footer_desc,
            twitter_url: this.basicConfig.footerTwitter
              ? this.basicConfig.twitterUrl + this.basicConfig.footerTwitter
              : null,
            insta_url: this.basicConfig.footerInstagram
              ? this.basicConfig.instagramUrl + this.basicConfig.footerInstagram
              : null,
            youtube_url: this.basicConfig.footerYoutube
              ? this.basicConfig.youtubeUrl + this.basicConfig.footerYoutube
              : null,
            linkedin_url: this.basicConfig.footerLinkedin
              ? this.basicConfig.linkedinUrl + this.basicConfig.footerLinkedin
              : null,
            fb_url: this.basicConfig.footerFacebook
              ? this.basicConfig.facebookUrl + this.basicConfig.footerFacebook
              : null,
            topbar_fields: this.basicConfig.topBar,

            hmpg_meta_desc: this.meta.homeDescription,
            abtpg_meta_desc: this.meta.aboutDescription,
            photos_meta_desc: this.meta.galleryDescription,
            faqs_meta_desc: this.meta.faqDescription,
            tuition_meta_desc: this.meta.tuitionDescription,
            font_option: this.font,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
          };

          await this.createBasicConfig({
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    } else if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }

    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
