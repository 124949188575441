<template>
  <b-modal
    ref="bookmarkDetailModal"
    hide-footer
    title="Bookmark Details"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <div class="d-flex align-items-start" v-for="(bookmark, index) in bookmarks[abbr]" :key="index">
      <span
        ><md-icon class="mr-2 text-50 icon-16pt">check_circle</md-icon><strong>{{ bookmark.text }}:</strong>
        <span class="ml-2">{{ bookmark.description }}</span></span
      >
    </div>
  </b-modal>
</template>

<script>
import { AI_BOOKMARKS_DESCRIPTION } from '@/common/constants';
export default {
  name: 'BookmarkDetailModal',

  props: {
    showModal: { type: Boolean, default: false },
    abbr: { type: String, default: '' },
  },

  data() {
    return {
      bookmarks: AI_BOOKMARKS_DESCRIPTION,
    };
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.bookmarkDetailModal.show();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.bookmarkDetailModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
