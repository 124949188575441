<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />

    <div class="page-section">
      <div class="container">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Payments"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
                <b-btn
                  variant="secondary"
                  @click.prevent="() => (showFilters = true)"
                  :class="isTabSmallScreen ? 'mb-2' : 'ml-2'"
                  v-if="!showFilters"
                  size="sm"
                >
                  <i class="fas fa-sliders-h mr-1" />Show {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn
                  variant="light"
                  class="ml-2"
                  @click.prevent="() => (showFilters = false)"
                  v-if="showFilters"
                  size="sm"
                >
                  <i class="fas fa-eye-slash mr-1" />Hide {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn variant="light" class="ml-2" @click.prevent="clearFilters" v-if="areFiltersApplied" size="sm">
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-7 px-0" :class="isTabSmallScreen ? 'mt-2 mb-2' : 'mx-0'">
                  <date-picker
                    v-model="dateRange"
                    format="MMMM DD, YYYY"
                    valueType="YYYY-MM-DD"
                    style="width: 100%"
                    id="date"
                    lang="en"
                    placeholder="Filter by Start Date - End Date Range"
                    range
                    @input="filterPayments"
                  ></date-picker>
                </div>
              </div>
            </div>

            <transition name="slide">
              <div class="row mb-2 mx-0 w-100 mt-2" v-if="showFilters">
                <div class="col-sm-6 col-md-6 mb-2 pl-0" :class="{ 'px-0': isTabSmallScreen }">
                  <v-select
                    id="school"
                    class="form-control v-select-custom"
                    label="name"
                    v-model="school"
                    :reduce="school => school.id"
                    placeholder="Select School"
                    :options="allSchools"
                    :loading="areSchoolsLoading"
                    @input="filterPayments"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                          <b-img
                            :src="option.logo_url"
                            class="img-fluid"
                            width="20"
                            alt="Logo"
                            v-if="option.logo_url"
                          />
                          <i class="fas fa-university fa-lg" v-else></i>
                        </fmv-avatar>
                        <span>{{ option.name }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </div>
                <div class="col-sm-6 col-md-6 mb-2 pr-0" :class="{ 'px-0': isTabSmallScreen }">
                  <v-select
                    label="text"
                    class="form-control v-select-custom"
                    :value="type"
                    v-model="type"
                    :reduce="type => type.value"
                    placeholder="Select Type"
                    :options="allTypes"
                    @input="filterPayments"
                  >
                  </v-select>
                </div>
              </div>
            </transition>
          </div>

          <b-table
            :fields="tableColumns"
            :items="payments"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
          >
            <template #cell(status)="data">
              <b-badge
                :class="
                  `border border-${TRANSACTION_STATUSES_COLORS[data.item.status]} text-${
                    TRANSACTION_STATUSES_COLORS[data.item.status]
                  }`
                "
                variant="none"
                >{{ $t(`transactionStatuses.${data.item.status}`) }}
              </b-badge>
            </template>

            <template #cell(type)="data"> {{ $t(`transactionTypes.${data.item.type}`) }} </template>

            <template #cell(amount)="data"> {{ formatAmount(data.item.amount) }} </template>

            <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
          </b-table>
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="payments-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/Ui/Pagination.vue';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import { FmvAvatar } from 'fmv-avatar';
import AmountFormatter from '@/mixins/amount-formatter';
import { formatFullDate } from '@/common/utils';
import { DEFAULT_PAGE_SIZE, TRANSACTION_STATUSES_COLORS } from '../../../common/constants';
import DatePicker from 'vue2-datepicker';

export default {
  components: { PageHeader, Pagination, vSelect, FmvAvatar, DatePicker },
  extends: Page,
  mixins: [AmountFormatter],

  data() {
    return {
      title: 'Payments',
      dateRange: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      searchTerm: '',
      areSchoolsLoading: false,
      showFilters: false,
      type: null,
      school: null,
      allTypes: [
        {
          value: 'student_registration',
          text: 'Student Registration',
        },
        { value: 'product_purchase', text: 'Product(s) Purchase' },
        { value: 'product_subscription', text: 'Product Subscription' },
        { value: 'manually_paid', text: 'Manual' },
        { value: 'class_plan', text: 'Payment Plan Dues' },
        { value: 'product_payment', text: 'Subscription Payment' },
        { value: 'referral_fee', text: 'Referral Fee' },
        { value: 'processing_fee', text: 'Processing Fee' },
      ],
      allSchools: [],
      payments: [],
      totalPayments: 0,
      TRANSACTION_STATUSES_COLORS,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    areFiltersApplied() {
      return this.school || this.dateRange.length || this.type;
    },
    tableColumns() {
      return [
        { key: 'school.name', label: 'School' },
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('payoutAcc', ['getPayments']),
    formatFullDate,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({});
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    filterPayments() {
      this.payments = [];
      this.fetchPayments();
    },

    async fetchPayments(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getPayments({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.school && { school: this.school }),
        ...(this.type && { type: this.type }),
        ...(!!this.dateRange.length && { start_date: this.dateRange[0] }),
        ...(!!this.dateRange.length && { end_date: this.dateRange[1] }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.payments = response.data.results;
      this.currentPage = pageNum;
      this.totalPayments = response.data.count;
      this.isLoading = false;
    },

    clearFilters() {
      this.searchTerm = '';
      this.dateRange = this.school = this.type = null;
      this.fetchPayments();
    },

    onPageChange(pageNum) {
      this.fetchPayments(pageNum);
    },

    onSearch() {
      this.debouncedSearchPayments(this);
    },

    debouncedSearchPayments: debounce(vm => {
      vm.fetchPayments();
    }, 500),
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.fetchPayments();
    this.fetchSchools();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
