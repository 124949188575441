<template>
  <b-modal ref="viewTokensModal" hide-footer title="ChatBot Tokens" centered lazy @hide="hideModal" size="md">
    <b-form-group label="Start Date - End Date" label-for="Date" label-class="form-label">
      <date-picker
        v-model="dateRange"
        format="DD MMMM, YYYY"
        valueType="date"
        style="width: 100%"
        id="date"
        lang="en"
        placeholder="Select Start Date - End Date Range"
        range
        :disabled="isLoading"
        @change="fetchSchoolCosumedTokens"
      ></date-picker>
    </b-form-group>
    <b-form-group>
      <div class="d-flex mb-1">
        <strong>Chat Tokens: </strong>
        <b-skeleton class="ml-2" v-if="isLoading" width="13%"></b-skeleton>
        <span class="ml-2" v-else>{{ get(tokenInRange, 'school_tokens', 0) }}</span>
      </div>
      <div class="d-flex mb-1">
        <strong>All Time Consumed Chat: </strong>

        <span class="ml-2">{{ consumedTokens }}</span>
      </div>
      <div class="d-flex mb-1">
        <strong>Content Tokens: </strong>
        <b-skeleton class="ml-2" v-if="isLoading" width="13%"></b-skeleton>
        <span class="ml-2" v-else>{{ get(contentTokenInRange, 'school_tokens', 0) }}</span>
      </div>
      <div class="d-flex mb-1">
        <strong>All Time Consumed Content: </strong>

        <span class="ml-2">{{ contentTokens }}</span>
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'AddMetaTagsModal',
  components: { DatePicker },
  props: {
    showModal: { type: Boolean, default: false },
    schoolId: { type: Number, default: null },
    consumedTokens: { type: Number, default: () => 0 },
    contentTokens: { type: Number, default: () => 0 },
  },

  data() {
    return {
      isLoading: false,
      dateRange: [],
      tokenInRange: {},
      contentTokenInRange: {},
    };
  },

  methods: {
    ...mapActions('school', ['getSchoolCosumedTokens']),
    ...mapActions('myAI', ['getSchoolContentTokens']),
    get,
    async fetchSchoolCosumedTokens() {
      const day = 60 * 60 * 24 * 1000;

      const endDate = new Date(new Date(this.dateRange[1]).getTime() + day);

      this.isLoading = true;
      try {
        const response = await this.getSchoolCosumedTokens({
          from_date: this.dateRange[0],
          to_date: endDate,
          school_id: this.schoolId,
        });

        const content = await this.getSchoolContentTokens({
          from_date: this.dateRange[0],
          to_date: endDate,
          school_id: this.schoolId,
        });

        this.tokenInRange = response.data;
        this.contentTokenInRange = content.data;
      } catch (e) {
        // USE Error
      }
      this.isLoading = false;
    },
    getDate() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date();

      this.dateRange = [firstDay, lastDay];
    },

    async hideModal() {
      this.$emit('close');
      this.getDate();
    },

    hideModalManual() {
      this.$refs.viewTokensModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.fetchSchoolCosumedTokens();
        this.$refs.viewTokensModal.show();
      }
    },
  },
  async mounted() {
    await this.getDate();
  },
};
</script>

<style>
</style>