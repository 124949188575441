<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'Manage classes and students registration.' : ''
      "
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3 "
            content-class="mt-2"
            class="mt-2"
            id="classTab"
            @input="onTabsChanged"
            v-model="tabIndex"
            style="scroll-margin-top: 66px"
            lazy
          >
            <b-tab title="Upcoming" class="tab-secondary">
              <class-tabs
                :classes="classes"
                :per-page="perPage"
                :total-classes="totalClasses"
                :is-loading="isLoading"
                :are-register-counts-loading="areRegisterCountsLoading"
                :program.sync="program"
                :show-filters.sync="showFilters"
                :location.sync="location"
                :status.sync="status"
                :type.sync="type"
                :schedule.sync="schedule"
                :search-term.sync="searchTerm"
                :all-programs-loading="areProgramsLoading"
                :all-programs="allPrograms"
                :all-locations="allLocations"
                :are-locations-loading="areLocationsLoading"
                :register-counts="registerCounts"
                :register-count-by-class="registerCountByClass"
                @duplicate="duplicateClass"
                @filter="onFilterApplied"
                @onSort="onSortChange"
                @edit="editClass"
                @pageChange="onPageChange"
                @search="onSearch"
                @fetch="fetchClasses"
                @filterLocation="filterLocations"
                @clear="clearFilters"
              />
            </b-tab>
            <b-tab title="Previous" class="tab-secondary">
              <class-tabs
                :classes="classes"
                :per-page="perPage"
                :total-classes="totalClasses"
                :register-counts="registerCounts"
                :register-count-by-class="registerCountByClass"
                :is-loading="isLoading"
                :program.sync="program"
                :search-term.sync="searchTerm"
                :status.sync="status"
                :show-filters.sync="showFilters"
                :schedule.sync="schedule"
                :location.sync="location"
                :are-register-counts-loading="areRegisterCountsLoading"
                :all-programs-loading="areProgramsLoading"
                :all-programs="allPrograms"
                :all-locations="allLocations"
                :are-locations-loading="areLocationsLoading"
                @duplicate="duplicateClass"
                @filter="onFilterApplied"
                @onSort="onSortChange"
                @edit="editClass"
                @pageChange="onPageChange"
                @search="onSearch"
                @fetch="fetchClasses"
                @filterLocation="filterLocations"
                @clear="clearFilters"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, keyBy, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import { DEFAULT_PAGE_SIZE, CLASS_STATUSES, CLASS_TYPES, USER_ROLE_TYPES } from '../../../common/constants';

import ClassTabs from './ClassTabs.vue';
export default {
  components: { PageHeader, ClassTabs },
  extends: Page,

  data() {
    return {
      title: 'Manage Classes',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalClasses: 0,
      classes: [],
      searchTerm: '',
      ordering: 'start_date',

      CLASS_STATUSES,
      areProgramsLoading: false,
      areLocationsLoading: false,
      showFilters: false,
      status: null,
      type: null,
      schedule: null,
      program: null,
      location: null,
      allLocations: [],
      allPrograms: [],
      tabIndex: 0,
      classType: CLASS_TYPES.UPCOMING,
      areRegisterCountsLoading: false,
      registerCounts: [],
      registerCountByClass: {},
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Classes', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('location', ['getAllLocations']),
    ...mapActions('classes', ['getAllClasses', 'updateStatus', 'getRegistrationCounts', 'createDuplicateClass']),
    async duplicateClass(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to duplicate class #${id}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.isLoading = true;
          await this.createDuplicateClass({ class_id: id });
          this.fetchClasses();
          this.makeToast({ variant: 'success', msg: 'Duplicate Class created!' });
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isDuplicateClassLoading = false;
    },
    async fetchLocation() {
      this.areLocationsLoading = true;

      const response = await this.getAllLocations({});
      this.allLocations = response.data;
      this.areLocationsLoading = false;
    },
    clearFilters() {
      this.searchTerm = '';
      this.type = null;
      this.program = this.location = this.status = this.schedule = null;
      this.typeFilterApplied();
      this.fetchClasses();
    },

    typeFilterApplied() {
      this.$router.replace({
        name: 'classes-management-list',
        query: {
          ...(this.program && { program: this.program }),
          type: this.tabIndex === 0 ? CLASS_TYPES.UPCOMING : CLASS_TYPES.PREVIOUS,
        },
      });
    },
    filterLocations(option, label, search) {
      let searchLower = search.toLowerCase();

      return (
        get(option, 'address', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'city', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'state', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'zipcode', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'country', '').toLowerCase().indexOf(searchLower) > -1
      );
    },
    onTabsChanged() {
      if (this.tabIndex === 0) {
        this.classType = CLASS_TYPES.UPCOMING;
      } else {
        this.classType = CLASS_TYPES.PREVIOUS;
      }

      this.fetchClasses();
      this.typeFilterApplied();
    },

    onFilterApplied() {
      this.typeFilterApplied();
      this.fetchClasses();
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
    async fetchRegisterCounts() {
      this.areRegisterCountsLoading = true;
      this.registerCountByClass = {};

      try {
        const response = await this.getRegistrationCounts({
          classes: this.classes.map((cs) => cs.id),
        });
        this.registerCounts = response;
        this.registerCountByClass = keyBy(response, 'class_enrolled_id');
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areRegisterCountsLoading = false;
    },

    async fetchClasses(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllClasses({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.program && { program: this.program }),
        ...(this.classType && { type: this.classType }),
        ...(this.location && { class_location: this.location }),
        ...(this.schedule && { schedule_type: this.schedule }),
        ...(this.status && { status: this.status }),
        ...(this.type && { class_type: this.type }),
        ...params,
      });
      this.classes = response.data.results;
      this.currentPage = pageNum;
      this.totalClasses = response.data.count;
      this.fetchRegisterCounts();
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchClasses(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchClasses();
    },

    editClass(data) {
      this.$router.push({ name: 'edit-class', params: { id: data.id } });
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce((vm) => {
      vm.fetchClasses();
    }, 500),
  },

  async mounted() {
    this.fetchLocation();
    this.fetchPrograms();
    if (this.$route.query.program) {
      this.program = Number(this.$route.query.program);
    }

    if (this.$route.query.type) this.classType = this.$route.query.type;
    this.tabIndex = this.$route.query.type === CLASS_TYPES.PREVIOUS ? 1 : 0;

    this.ordering = '';
    if (this.$route.query.type !== CLASS_TYPES.PREVIOUS) {
      this.fetchClasses();
    }
  },
};
</script>
