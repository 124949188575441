<template>
  <div class="card card-lg overlay--primary-dodger-blue mb-2">
    <div class="card-body d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="col-1 col-md-1">
          <i class="fas fa-bars icon-16pt mr-8pt"></i>
        </div>

        <!-- <div class="col-3 col-md-2">
          <b-img
            :src="image"
            class="img-fluid"
            style="width: 70px; height: 40px; object-fit: cover"
            alt="Image"
            v-if="image"
          />
        </div> -->

        <!-- <div class="col-3 col-md-5">
          <span>{{ email }}</span>
        </div> -->

        <div class="col-5 col-md-8">
          <span>{{ name }}</span>
        </div>

        <div class="col-4 col-md-2">
          <b-form-checkbox
            class="ml-auto"
            @change="updateValue(isEnabled, index, linkedWebsite)"
            v-model="isEnabled"
            name="check-button"
            switch
          >
            Enabled?
          </b-form-checkbox>
        </div>

        <div class="mr-2">
          <router-link
            :to="{
              name: 'edit-faq',
              params: { id: index },
            }"
            class="svg-icon mr-2"
            v-b-popover.hover.right
            :title="$t('generalMsgs.edit')"
          >
            <md-icon class="text-info">edit</md-icon>
          </router-link>
        </div>
        <div>
          <a href="#" @click.prevent="removeItem" v-b-popover.hover.right :title="'Remove Faq'">
            <i class="material-icons text-info icon-18pt">delete</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UrlCard',
  props: {
    image: { type: String, default: '' },
    name: { type: String, default: '' },
    email: { type: String, default: '' },
    isEnable: { type: Boolean, default: true },
    index: { type: Number, default: null },
    linkedWebsite: { type: Number, default: null },
  },
  data() {
    return {
      isEnabled: this.isEnable,
    };
  },
  methods: {
    updateValue(isEnable, index, linkedWebsite) {
      this.$emit('updateIsEnable', { isEnable, index, linkedWebsite });
    },
    async removeItem() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to remove the selected faq. The faq will no longer appear on the school public website and all existing responses will be removed.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        this.$emit('removeCampus', this.index);
      }
    },
  },
};
</script>

<style>
</style>
