<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit State' : 'Add New State'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="image" class="img-fluid" width="40" alt="Image" v-else-if="image" />
            <i class="fas fa-shopping-bag fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="image"
            placeholder="Select Image"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.image"
            @input="uploadImage('image')"
            :disabled="isImgUploading"
            :required="!image"
            accept="image/*"
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label">
        <v-select
          id="state"
          class="form-control v-select-custom"
          label="text"
          v-model="name"
          :reduce="(state) => state.value"
          placeholder="Select State"
          :options="stateOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!name" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea id="description" placeholder="Enter State's Description" :rows="5" v-model="description" />
      </b-form-group>
      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="active" name="check-button" switch> Active? </b-form-checkbox>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { get, map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import axios from 'axios';
import { FmvAvatar } from 'fmv-avatar';

import 'vue-swatches/dist/vue-swatches.css';
var UsaStates = require('usa-states').UsaStates;
import vSelect from 'vue-select';
export default {
  components: {
    PageHeader,
    vSelect,
    FmvAvatar,
  },
  name: 'PostProduct',
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Product' : 'Add Product',
      name: '',
      active: true,

      description: '',
      isLoading: false,
      image: '',
      isImgUploading: false,

      uploadFile: { image: null },
      uploadPercent: { image: 0 },
      uploadCancelTokenSource: { image: null },
      stateOptions: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'States',
          to: {
            name: 'sa-manage-states',
          },
        },
        {
          text: this.$route.params.id ? 'Edit State' : 'Add State',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isImgUploading;
    },
  },

  methods: {
    ...mapActions('location', [
      'createBestSchoolState',
      'updateBestSchoolState',
      'getBestSchoolState',
      'getStatePresignedUrl',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    get,
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getStatePresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          name: this.name,
          description: this.description,
          active: this.active,
          image_url: this.image,
        };
        if (this.$route.params.id) {
          await this.updateBestSchoolState({
            id: this.$route.params.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: this.$t('Product Updated!') });
        } else {
          await this.createBestSchoolState({
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'State Added!' });
        }
        setTimeout(
          () =>
            this.$router.push({
              name: 'sa-manage-states',
            }),
          250
        );
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getBestSchoolState(this.$route.params.id)).data;
        this.name = resp.name;
        this.description = resp.description;
        this.active = resp.active;
        this.image = resp.image_url;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'sa-manage-states',
      });
    }

    this.isLoading = false;
  },
};
</script>
