<template>
  <b-modal
    ref="registeredStudentsModal"
    hide-footer
    title="Are you sure?"
    centered
    lazy
    size="md"
    scrollable
    @hide="hideModal"
  >
    <p v-if="status === REFUND_STATUSES.APPROVED">Are you sure you want to cancel the registration?</p>
    <p v-else>Are you sure you want to approve the request?</p>

    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="status !== REFUND_STATUSES.APPROVED"
        label="Note"
        label-for="cancellation_reason"
        label-class="form-label"
      >
        <b-form-textarea id="cancellation_reason" placeholder="Enter reason" :rows="10" required v-model="reason" />
      </b-form-group>

      <div class="row">
        <div class="ml-auto" style="margin-right: 12px">
          <b-button @click.prevent="hideModalManual(true)" size="sm">Cancel</b-button>
          <b-button type="submit" variant="danger" size="sm" class="ml-2">Confirm</b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { REFUND_STATUSES } from '@/common/constants';

export default {
  name: 'ApproveStatusModal',

  props: {
    showModal: { type: Boolean, default: false },
    regCancel: { type: Boolean, default: false },
    refundReason: { type: String, default: '' },
    status: { type: String, default: null },
  },

  data() {
    return {
      reason: '',
      isLoading: false,
      REFUND_STATUSES,
      REFUND_NOTE:
        'Refund does not include non-refundable deposit and/or associated processing fees per REGISTRATION TERMS AND CONDITIONS',
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;
        this.reason = this.refundReason + '\n\n' + this.REFUND_NOTE;

        this.$refs.registeredStudentsModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal(no = false) {
      this.reason = '';
      this.$emit('close', no);
    },

    hideModalManual(no = false) {
      this.$refs.registeredStudentsModal.hide();
      this.hideModal(no);
    },

    async onSubmit() {
      const data = {
        status: REFUND_STATUSES.APPROVED,
        review_reason: this.reason,
        is_reg_cancelled: this.regCancel,
      };

      this.$emit('onApprove', data);
      this.hideModalManual();
    },
  },

  async mounted() {},
};
</script>
