<template>
  <div class="bg-light">
    <b-modal ref="initiateModal" hide-footer title="Initiate Registration" centered lazy @hide="hideModal" size="lg">
      <div>
        <b-alert show variant="light">
          Initiating a registration will send an email to the student for completing the registration by entering in
          complete details. Student will also be able to pay securely through the app and apply for sponsorships. Feel
          free to use this for walk-in registrations or initiating registrations for potential students.
        </b-alert>
      </div>
      <div class="d-flex">
        <b-btn
          style="min-width: 120px"
          class="btn-normal ml-2"
          variant="secondary"
          :to="{ name: 'initiated-registrations' }"
        >
          View Initiations</b-btn
        >
        <div class="col-lg d-flex flex-wrap justify-content-end mb-3">
          <b-btn
            style="width: 120px"
            class="btn-normal"
            variant="primary"
            @click.prevent="resendAll()"
            v-if="totalData"
          >
            <i v-if="isResendingAll" class="fas fa-circle-notch fa-spin"></i> <span v-else>Resend all</span></b-btn
          >
          <b-btn
            style="width: 120px"
            class="btn-normal ml-2"
            @click.prevent="openInitiateRegistrationFormModal(selectedClass)"
            variant="primary"
            v-text="'Initiate'"
          />
        </div>
      </div>
      <i v-if="!totalData" class="d-flex justify-content-center">No registeration initiated yet!</i>
      <b-table
        v-else
        :fields="tableColumns"
        :items="initiateData"
        head-variant="light"
        class="table-nowrap"
        hover
        responsive
        no-local-sorting
      >
        <template #cell(menu)="data">
          <div>
            <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
              <template #button-content>
                <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                <span class="sr-only">Menu</span>
              </template>

              <b-dropdown-item @click.prevent="resend(data.item.id)">
                <md-icon class="text-info">email</md-icon> Resent Email
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="copyUrl(data.item.id)">
                <md-icon class="text-info">content_copy</md-icon> Copy Link
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="deleteReg(data.item.id)">
                <md-icon class="text-info">block</md-icon> Close Registration
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="my-2">
        <pagination
          v-if="totalData > initiateData.length"
          v-model="currentPage"
          :total-rows="totalData"
          :per-page="perPage"
          @change="onPageChange"
        />
      </div>
    </b-modal>

    <initiate-registration-form-modal
      :show-modal="modals.showInitiateRegistrationModal"
      :selected-class="selectedClass"
      @close="hideInitiateRegistrationFormModal"
      @closeSubmit="hideInitiateFormOnSubmitModal"
    >
    </initiate-registration-form-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDateSimple, formatFullDate, formatDateTime } from '../../../../common/utils';
import InitiateRegistrationFormModal from './InitiateRegistrationFormModal.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import Pagination from '@/components/Ui/Pagination.vue';

export default {
  props: {
    showModal: { type: Boolean, default: false },
    selectedClass: { type: Object, default: () => ({}) }
  },
  components: { InitiateRegistrationFormModal, Pagination },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalData: 0,
      initiateData: [],
      perPage: DEFAULT_PAGE_SIZE,
      isResendingAll: false,
      isResending: false,
      isCopied: false,
      modals: {
        showInitiateRegistrationModal: false
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    tableColumns() {
      return [
        {
          key: 'menu'
        },
        { key: 'std_name', label: this.$t('studentMsgs.name') },
        { key: 'email', label: 'Email' },
        { key: 'created_at', label: 'Initiated at', formatter: value => (value ? formatDateTime(value) : value) }
      ];
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.resetData();
        this.$refs.initiateModal.show();
        this.fetchData();
      }
    }
  },
  methods: {
    ...mapActions('student', ['allInitiateStudents', 'resendAllInitiates', 'deleteInitiate']),

    formatDateSimple,
    formatFullDate,
    resetData() {
      this.initiateData = [];
      this.totalData = 0;
    },
    hideModal() {
      this.$refs.initiateModal.hide();
      this.$emit('close');
    },
    async copyUrl(id) {
      try {
        const urlLink = `${this.getLoggedInUser.linked_entity.subdomain.subdomain_url}/registration?regId=${id}`;
        await navigator.clipboard.writeText(urlLink);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);

        this.makeToast({ variant: 'success', msg: 'Link copied.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async resendAll() {
      this.isResendingAll = true;
      if (this.totalData) {
        try {
          await this.resendAllInitiates({
            class_enrolled: this.selectedClass.id
          });
          this.fetchData();
          this.makeToast({ variant: 'success', msg: 'Email resent to all initiated re.' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'No invites to resend' });
      }
      this.isResendingAll = false;
    },
    async resend(id) {
      this.isResending = true;
      try {
        await this.resendAllInitiates({ regId: id });
        this.fetchData();

        this.makeToast({ variant: 'success', msg: 'Resent registration email.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async deleteReg(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          'Please confirm that you want to delete the initiated registration.',
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteInitiate(id);
          this.fetchData();
          this.makeToast({ variant: 'success', msg: 'Deleted' });
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async fetchData(pageNum = 1, params = {}) {
      this.isLoading = true;
      const response = await this.allInitiateStudents({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        class_enrolled: this.selectedClass.id,
        ...params
      });
      this.initiateData = response.data.results;
      this.currentPage = pageNum;
      this.totalData = response.data.count;
      this.isLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchData(pageNum);
    },
    hideModalManual() {
      this.$refs.initiateModal.hide();
    },
    hideUpdateModal() {
      this.$refs.initiateModal.hide();
      this.hideModal();
    },
    openInitiateRegistrationFormModal() {
      this.modals.showInitiateRegistrationModal = true;
    },
    hideInitiateRegistrationFormModal() {
      this.modals.showInitiateRegistrationModal = false;
    },
    hideInitiateFormOnSubmitModal() {
      this.modals.showInitiateRegistrationModal = false;
      this.fetchData();
    }
  }
};
</script>

<style></style>
