<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage all instructors associated with your school."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card bg-primary p-2" style="color: white">
          <div class="d-flex justify-content-center align-items-center p-2" style="font-size: 16px">
            <strong> Invite Instructor </strong>
          </div>

          <div class="p-2 mb-4">
            Use your invite link below to let your instructors fill their data themselves. Associate that instructor
            with any offered class after reviewing their filled form.
          </div>
          <b-alert variant="soft-light px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                ><strong>{{ inviteURL }}</strong></span
              >

              <span v-if="isUrlCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyInviteUrl" v-else v-b-popover.hover.top="'Copy Invite Link'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </div>
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Instructor"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>

                <b-btn
                  class="ml-2"
                  :to="{ name: 'add-instructor' }"
                  exact
                  variant="primary"
                  v-text="'Add Instructor'"
                />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allInstructors"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="editInstructor"
          >
            <template #cell(logo)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.photo"
                    class="img-fluid rounded"
                    width="50"
                    alt="Logo"
                    v-if="data.item.photo"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
              </div>
            </template>

            <template #cell(first_name)="data"> {{ data.item.first_name }} {{ data.item.last_name }}</template>

            <template #cell(phone)="data"> {{ data.item.phone }}</template>
            <template #cell(email)="data"> {{ data.item.email }}</template>
            <template #cell(bio)="data"><div v-safe-html="data.item.bio" /> </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-instructor',
                  params: { id: data.item.id }
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </router-link>
              <a href="#" v-b-popover.hover.right :title="'Close Registration'"> </a>
              <a
                href="#"
                @click.prevent="removeInstructor(data.item.id)"
                v-b-popover.hover.right
                :title="'Remove Instructors'"
              >
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalInstructors"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Instructors',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalInstructors: 0,
      searchTerm: '',
      allInstructors: [],
      isUrlCopied: false,
      inviteId: ''
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Instructors', active: true }
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '', tdClass: 'clickable-item' },

        { key: 'first_name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'email', label: 'Email', tdClass: 'clickable-item' },
        { key: 'phone', label: 'Phone', tdClass: 'clickable-item' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' }
        }
      ];
    },

    inviteURL() {
      return `${this.getClickableLink(process.env.VUE_APP_MAIN_DOMAIN)}/instructor-onboarding/?inviteId=${
        this.inviteId
      }`;
    }
  },

  methods: {
    ...mapActions('instructor', ['getAllInstructors', 'deleteInstructor']),
    ...mapActions('school', ['getSchoolInstructorId']),

    async copyInviteUrl() {
      try {
        await navigator.clipboard.writeText(this.inviteURL);
        this.isUrlCopied = true;

        setTimeout(() => (this.isUrlCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    async fetchSchoolInstructorId() {
      const response = await this.getSchoolInstructorId();
      this.inviteId = response.data.instructor_id;
    },

    async fetchInstructors(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllInstructors({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.allInstructors = response.data.results;
      this.currentPage = pageNum;
      this.totalInstructors = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchInstructors(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchInstructors();
    },

    editInstructor(data) {
      this.$router.push({ name: 'edit-instructor', params: { id: data.id } });
    },

    onSearch() {
      this.debouncedSearchInstructors(this);
    },

    debouncedSearchInstructors: debounce(vm => {
      vm.fetchInstructors();
    }, 500),

    async removeInstructor(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected Instructor. The Instructor will no longer appear on your portal.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteInstructor(id);
          this.fetchInstructors();
          this.makeToast({ variant: 'success', msg: 'Instructor removed successfully!' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    }
  },

  async mounted() {
    this.fetchInstructors();
    this.fetchSchoolInstructorId();
    // if (this.$route.query.program) {
    //   this.program = this.$route.query.program;
    // }
  }
};
</script>
