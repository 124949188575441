<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Blog' : 'Post Blog'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >  <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="school => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator title="Blog Details" />

      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.blog_image">...</span>
            <b-img :src="blog.image_url" class="img-fluid" width="40" alt="Logo" v-else-if="blog.image_url" />
            <i class="material-icons" v-else>vignette</i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'blog_image')"
            :isUploading="isUploading.blog_image"
            :isRequired="!blog.image_url"
            placeholder="Select blog's image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter blog's title" v-model="blog.title" required />
      </b-form-group>

      <b-form-group label="Blog Summary" label-for="summary" label-class="form-label">
        <b-form-textarea
          id="summary"
          placeholder="Enter a short summary for the blog"
          :rows="5"
          v-model="blog.summary"
          required
        />
      </b-form-group>

      <b-form-group label="Text" label-for="text" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="blog.text"
          placeholder="Enter blog's text."
        />
        <b-form-invalid-feedback :state="!$v.blog.text.required && $v.blog.text.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="category"
          :reduce="item => item.value"
          placeholder="Select category"
          :options="categoriesOptions"
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          placeholder="Please specify"
          v-model="otherCategory"
          required
          v-if="category === 'other'"
        />
      </b-form-group>

      <b-form-group label="Published At (Date and Time)" label-for="published_at" label-class="form-label">
        <date-picker
          id="published_at"
          class="form-control datepicker-custom w-100"
          placeholder="Select a publishing time (leave empty for current time)"
          v-model="publishedAt"
          type="datetime"
          format="MM/DD/YYYY HH:mm"
          :minute-step="5"
        />
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group label-class="form-label">
            <b-form-checkbox id="visible" v-model="blog.isVisible" name="check-button" switch>
              Is blog visible?
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'Update' : 'Post' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';
import 'vue2-datepicker/index.css';
import Page from '../../../components/Page.vue';
import DatePicker from 'vue2-datepicker';
import { required, requiredIf } from 'vuelidate/lib/validators';
import ImageUploader from '@/components/ImageUploader.vue';
import { SCHOOL_PAYMENT_PLANS, USER_ROLES } from '../../../common/constants';

export default {
  components: { PageHeader, PageSeparator, FmvAvatar, Editor, DatePicker, ImageUploader, vSelect },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Blog' : 'Post Blog',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      ctaButtonTitle: '',
      ctaButtonLink: '',
      blog: {
        title: '',
        text: '',
        summary: '',
        image_url: '',
        isVisible: true,
      },
      school: null,
      publishedAt: null,
      category: '',
      otherCategory: null,
      categoriesOptions: [
        { value: 'general', text: 'General' },
        { value: 'announcements', text: 'Announcements' },
        { value: 'student_enrollment', text: 'Student Enrollment' },
        { value: 'student_engagement', text: 'Student Engagement' },
        { value: 'student_outcomes', text: 'Student Outcomes' },
        { value: 'marketing', text: 'Marketing' },
        { value: 'partnerships', text: 'Partnerships' },
        { value: 'data_and_analytics', text: 'Data and Analytics' },
        { value: 'recruitment', text: 'Recruitment' },
        { value: 'retainment', text: 'Retainment' },
      ],
      isLoading: false,
      areStatesLoading: false,
      isUploading: { blog_image: false },
      selectedUploadFile: { blog_image: null },
      uploadPercent: { blog_image: 0 },
      uploadCancelTokenSource: { blog_image: null },
      linkedWebsite: null,
      allSchools: [],
      areSchoolsLoading: false,
      SCHOOL_PAYMENT_PLANS,
      USER_ROLES,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
      blog: {
        text: { required },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Blogs', to: { name: 'sa-manage-blogs' } },
        {
          text: this.$route.params.id ? 'Edit Blog' : 'Post Blog',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isUploading.blog_image;
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('blog', ['getBlog', 'createBlog', 'updateBlog', 'getBlogPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    get,

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    setImage(file, fileType) {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType);
    },

    async onSubmit() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            cta_btn_text: this.ctaButtonTitle,
            cta_btn_link: this.ctaButtonLink,
            categories: this.category,
            ...(this.publishedAt && { published_at: this.publishedAt }),
            ...this.blog,
            is_active: this.blog.isVisible,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
          };
          if (this.$route.params.id) {
            await this.updateBlog({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Blog Details Updated' });
          } else {
            await this.createBlog(data);
            this.makeToast({ variant: 'success', msg: 'Blog Posted' });
          }
          setTimeout(() => this.$router.push({ name: 'sa-manage-blogs', query: { school_id: this.school } }), 250);
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'blog_images') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getBlogPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'blog_image') {
          this.blog.image_url = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },
  },

  async mounted() {
    if (this.$route.query.school_id) {
      this.school = parseInt(this.$route.query.school_id);
    }
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }

    this.isLoading = true;
    try {
      if (this.$route.params.id) {
        const res = await this.getBlog(this.$route.params.id);
        this.blog.image_url = res.data.image_url;
        this.blog.title = res.data.title;
        this.blog.summary = res.data.summary;
        this.blog.text = res.data.text;
        this.school = res.data.school;
        this.blog.isVisible = res.data.is_active;
        this.ctaButtonTitle = res.data.cta_btn_text;
        this.ctaButtonLink = res.data.cta_btn_link;
        this.category = res.data.categories;
        this.publishedAt = new Date(res.data.published_at);
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'sa-manage-blogs' });
    }

    this.isLoading = false;
  },
};
</script>
