<template>
  <div :class="containerClass">
    <page-header
      :title="$route.params.id ? 'Edit Interested School' : 'Add Interested School'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator :title="`Interested School's Info`" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="logoUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.logoDesc')"
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
        <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="name" required />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input id="phone" placeholder="(888) 689 - 1235" v-model="phone" v-mask="'(###) ### - ####'" required />
      </b-form-group>
      <b-form-group label="Pass Ratio" label-for="pass_ratio" label-class="form-label">
        <currency-input
          id="pass_ratio"
          v-model="pass_ratio"
          class="form-control"
          placeholder="Enter pass ratio"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
        />
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.bio')" label-for="about" label-class="form-label">
        <b-form-textarea id="about" :placeholder="`${$t('schoolMsgs.bioPlaceholder')}`" :rows="5" v-model="bio" />
      </b-form-group>
      <b-form-group label="Time Zone" label-for="time_zone" label-class="form-label">
        <v-select
          id="time_zone"
          class="form-control v-select-custom"
          label="text"
          v-model="time_zone"
          placeholder="Enter Time Zone"
          :options="allTimeZones"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Review Remarks" label-for="review_remarks" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="review_remarks"
          placeholder="Enter your remarks"
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="state" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            v-model="country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
            required
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <div>
        <page-separator title="Contact Person's Info" />

        <div class="row">
          <b-form-group
            :label="$t('userMsgs.firstName')"
            label-for="contact_first_name"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input
              id="contact_first_name"
              :placeholder="$t('userMsgs.firstName')"
              v-model="contact_first_name"
              required
            />
          </b-form-group>

          <b-form-group
            :label="$t('userMsgs.lastName')"
            label-for="contact_last_name"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input
              id="contact_last_name"
              :placeholder="$t('userMsgs.lastName')"
              v-model="contact_last_name"
              required
            />
          </b-form-group>
        </div>
        <b-form-group label="Phone" label-for="owner_phone" label-class="form-label">
          <b-form-input
            id="contact_phone"
            placeholder="(777) 234 - 1235"
            v-model="contact_phone"
            v-mask="'(###) ### - ####'"
            required
          />
        </b-form-group>

        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="contact_email" label-class="form-label">
          <b-form-input
            id="contact_email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="contact_email"
            type="email"
            :state="errorStates.ownerEmail"
            required
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Gender" label-for="gender" label-class="form-label">
          <v-select
            id="gender"
            class="form-control v-select-custom"
            label="text"
            v-model="contact_gender"
            :reduce="item => item.value"
            placeholder="Select Contact Person's Gender"
            :options="genderOptions"
          >
          </v-select>
        </b-form-group>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import vSelect from 'vue-select';
import Page from '../../../components/Page.vue';
import Editor from '@tinymce/tinymce-vue';
import axios from 'axios';
import { FmvAvatar } from 'fmv-avatar';
import moment from 'moment-timezone';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    Editor,
    vSelect,
    FmvAvatar,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Interested School' : 'Add Interested School',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      contact_email: '',
      logoUrl: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_gender: '',
      name: '',
      phone: '',
      zipcode: '',
      address: '',
      city: '',
      state: '',
      country: 'United States',
      review_status: '',
      review_remarks: '',
      pass_ratio: 0,
      errorStates: {
        ownerEmail: null
      },
      time_zone: 'America/New_York',
      countryOptions: [],
      isLogoUploading: false,
      isLoading: false,
      uploadFile: { logo: null, smallLogo: null, profileBgImage: null, avatar: null },
      uploadPercent: { logo: 0, smallLogo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { logo: null, smallLogo: null, profileBgImage: null },
      genderOptions: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' }
      ],
      bio: '',
      contact_phone: '',
      allTimeZones: []
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Interested Schools', to: { name: 'manage-int-schools' } },
        {
          text: this.$route.params.id ? 'Edit Inerested School' : 'Add Interested School',
          active: true
        }
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.isProfileBgImgUploading ||
        this.isAvatarImgUploading ||
        this.isSmallLogoUploading
      );
    }
  },

  methods: {
    ...mapActions('school', [
      'getInterestedSchool',
      'createInterestedSchool',
      'updateInterestedSchool',
      'getSchoolImageUploadPresignedUrl'
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      this.isAvatarImgUploading = uploadType === 'avatar';

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'avatar') {
          this.owner.avatar_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.school.small_logo_url = urlResp.upload_url.split('?')[0];
        } else {
          this.school.profileBgImgUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'avatar') this.isAvatarImgUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else this.isProfileBgImgUploading = false;
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          name: this.name,
          phone: this.phone,
          zipcode: this.zipcode,
          address: this.address,
          logo_url: this.logoUrl,
          city: this.city,
          state: this.state,
          country: this.country,
          review_remarks: this.review_remarks,
          pass_ratio: this.pass_ratio,
          contact_email: this.contact_email,
          contact_first_name: this.contact_first_name,
          contact_last_name: this.contact_last_name,
          contact_gender: this.contact_gender,
          contact_phone: this.contact_phone,
          bio: this.bio,
          time_zone: this.time_zone
        };
        if (this.$route.params.id) {
          await this.updateInterestedSchool({
            id: this.$route.params.id,
            data
          });
          this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolProfileUpdated') });
        } else {
          await this.createInterestedSchool({
            ...data
          });
          this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolAdded') });
        }
        setTimeout(() => this.$router.push({ name: 'manage-int-schools' }), 250);
      } catch (err) {
        if (err.response.status === 400) {
          this.makeToast({
            variant: 'danger',
            msg: err.response.data.message,
            autoHideDelay: 5000
          });
        }
        if (get(err, 'response.data.owner')) {
          this.errorStates.ownerEmail = false;
        }
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    this.allTimeZones = moment.tz.names();
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name
      }))
    );

    // Auto-generate password.

    try {
      if (this.$route.params.id) {
        const resp = (await this.getInterestedSchool(this.$route.params.id)).data;

        this.name = resp.name;
        this.phone = resp.phone;
        this.zipcode = resp.zipcode;
        this.address = resp.address;
        this.city = resp.city;
        this.state = resp.state;
        this.country = resp.country;
        this.logoUrl = resp.logo_url;
        this.bio = resp.bio;
        this.time_zone = get(resp, 'time_zone', 'America/New_York');

        this.contact_first_name = resp.contact_first_name;
        this.contact_last_name = resp.contact_last_name;
        this.contact_email = resp.contact_email;
        this.contact_gender = resp.contact_gender;
        this.review_remarks = resp.review_remarks;
        this.contact_phone = resp.contact_phone;
        this.pass_ratio = resp.pass_ratio;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      setTimeout(() => this.$router.push({ name: 'manage-int-schools' }), 200);
    }

    this.isLoading = false;
  }
};
</script>
