<template>
  <div>
    <manage-faqs />
  </div>
</template>

<script>
import ManageFaqs from '@/components/WebsiteConfig/ManageFaqs.vue';

export default {
  components: {
    ManageFaqs,
  },
};
</script>
