<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter Institutions</div>

    <div class="sidebar-heading">Location</div>
    <div class="sidebar-block">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Zip code" v-model="filters.zipCode" />
      </div>
    </div>

    <div class="sidebar-heading">State</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="text"
          v-model="filters.state"
          :reduce="status => status.value"
          placeholder="Select state"
          :options="stateOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.text }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import moment from 'moment';
import { map } from 'lodash';

import { getLangOptions } from '@/common/utils';
import { USER_ROLE_TYPES } from '@/common/constants';
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
var UsaStates = require('usa-states').UsaStates;

export default {
  name: 'SchoolFiltersSidebar',

  components: { FmvSidebar, vSelect },

  data() {
    return {
      USER_ROLE_TYPES,

      filters: {
        zipCode: null,
        zipCodeRange: 30,
        offered_programs__title: null,
        state: null,
      },

      zipCodeRangeOptions: [{ value: 30, text: 'Within 30 Miles' }],

      allPrograms: [],
      areProgramsLoading: false,
      stateOptions: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('school', ['updateSchoolsEmpFilters']),
    ...mapActions('program', ['getAllPrograms']),
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({
        ordering: 'id',
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateSchoolsEmpFilters({ filters });
      },
    },
  },

  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.filters.state = this.getLoggedInUser.linked_entity.state;

    this.fetchPrograms();
    this.langOptions = [{ value: null, text: 'Any' }, ...getLangOptions('text')];
    this.workDayOptions = map(moment.weekdays(), day => ({ value: day.toLowerCase(), text: day }));
  },
};
</script>
