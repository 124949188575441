<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage programs referrals from your affiliated partners."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="row align-items-center" style="white-space: nowrap">
          <div class="col-lg-auto">
            <form class="search-form search-form--light d-lg-inline-flex mb-4pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search Programs" v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>
        <page-separator title="" />

        <div class="page-section d-flex justify-content-center" v-if="!programs.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Affiliated Programs Found</h4>

              <p class="text-muted">Couldn't find any programs.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                      <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                        >more_vert</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div v-for="program in programs" :key="program.id" class="col-lg-4 col-md-4 col-sm-6">
              <div class="card card-sm card--elevated p-relative overlay clickable-item" style="height: 90%">
                <div
                  v-if="partnership[program.id].status"
                  class="justify-content-between align-items-start overlay__content ml-3 my-3 mx-3"
                >
                  <b-badge
                    class="
                      border border-success badge-success
                    "
                    variant="none"
                  >
                    {{ 'Shown on Portal' }}
                  </b-badge>
                  <i
                    class="fa fa-align-justify"
                    style="font-size: 1.5rem; color: black"
                    id="handle"
                    v-if="isMobileScreen"
                  ></i>
                </div>

                <span
                  class="js-image d-flex justify-content-center clickable-item"
                  data-position="left"
                  :data-height="168"
                  @click.prevent="editProgram(program.slug)"
                >
                  <img :src="program.image" alt="cna" class="career-card-img" />
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <a class="flex" href="#" @click.prevent="editProgram(program.slug)">
                      <div class="card-title">{{ program.title }}</div>
                      <div class="row ml-0">
                        <small
                          class="text-50 font-weight-bold d-flex align-items-center"
                          :class="{ 'mb-4pt': get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN }"
                        >
                          {{ $t(`programTypeOptions.${program.type}`) }}
                        </small>

                        <template v-if="program.pre_enrollment_enabled">
                          <small class="text-50 font-weight-bold mx-1">|</small>
                          <small class="text-50 font-weight-bold"> Requires Pre-enrollment </small>
                        </template>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalPrograms > programs.length"
          v-model="currentPage"
          :total-rows="totalPrograms"
          :per-page="perPage"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { get, debounce, keyBy } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Pagination from '@/components/Ui/Pagination.vue';
import Page from '@/components/Page.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import {
  USER_ROLES,
  PROGRAM_STATUSES,
  USER_ROLE_TYPES,
  PAYOUT_ACC_STATUS,
  DEFAULT_PAGE_SIZE,
} from '@/common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Affiliated Programs',
      linkedStatus: false,
      isPayoutStatusLoading: false,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPrograms: 0,
      programs: [],
      typeOptions: [
        { value: 'virtual', text: 'Virtual' },
        { value: 'onsite', text: 'Onsite' },
        { value: 'blended', text: 'Hybrid' },
      ],
      USER_ROLES,
      PROGRAM_STATUSES,
      USER_ROLE_TYPES,
      searchTerm: '',
      PAYOUT_ACC_STATUS,
      dragging: false,
      windowWidth: window.innerWidth,
      isReseting: false,
      partnership: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('Programs'), active: true },
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('program', ['getAllNationalPrograms']),
    ...mapActions('school', ['programStatus']),
    get,

    async fetchPartnership() {
      this.isPartnershipLoading = true;

      const ids = this.programs.map(program => program.id);
      const response = await this.programStatus({ prg_ids: ids });

      this.partnership = keyBy(response.data.data, 'program_id');

      this.isPartnershipLoading = false;
    },

    editProgram(slug) {
      this.$router.push({
        name: 'program-details',
        params: { slug },
      });
    },

    async fetchPrograms(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllNationalPrograms({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,

        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.programs = response.data.results;
      this.currentPage = pageNum;
      this.totalPrograms = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrograms(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrograms();
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce(vm => {
      vm.fetchPrograms();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    await this.fetchPrograms();
    this.fetchPartnership();

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
