<template>
  <div>
    <div :class="containerClass">
      <page-header :title="title" :container-class="containerClass" :info-text="''" />
      <div class="page-section">
        <div :class="containerClass">
          <div class="card mb-0">
            <div class="card-header">
              <div class="row align-items-center" style="white-space: nowrap">
                <div class="col-lg-auto">
                  <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                    <b-form-input
                      class="w-lg-auto"
                      placeholder="Search Calls"
                      v-model="searchTerm"
                      @input="onSearch"
                      :disabled="isLoading"
                    />
                    <b-btn variant="flush" type="submit">
                      <md-icon v-text="'search'" />
                    </b-btn>
                  </form>
                </div>
              </div>
            </div>

            <b-table
              :fields="tableColumns"
              :items="callLogs"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @row-clicked="onRowClicked"
              @sort-changed="onSortChange"
            >
              <template #cell(call_type)="data">
                <div class="text-center">
                  <i class="material-icons icon-24pt card-course__icon-favorite text-info">{{
                    data.item.call_type === 'inbound' ? 'phone_callback' : 'call'
                  }}</i>
                </div>
              </template>

              <template #cell(school)="data">
                {{ get(data, 'item.school.name') }}
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalCallLogs"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="callLogs-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { formatFullDate } from '@/common/utils';

export default {
  components: { PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Call Logs',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalCallLogs: 0,
      callLogs: [],
      USER_ROLE_TYPES,
      USER_ROLES,
      searchTerm: '',
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.callLogs'), active: true },
      ];
    },
    tableColumns() {
      return [
        { key: 'call_type', label: '' },
        { key: 'from_number', label: 'From Number', tdClass: 'clickable-item' },
        { key: 'to_number', label: 'To Number', tdClass: 'clickable-item' },
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? [{ key: 'school', label: 'School' }] : []),
        {
          key: 'updated_at',
          label: 'Date',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatFullDate(value) : value),
        },
        {
          key: 'call_duration',
          label: 'Duration (seconds)',
          tdClass: 'clickable-item',
          formatter: (value) => Math.floor(value),
        },
        { key: 'call_status', label: 'Status', tdClass: 'clickable-item' },
      ];
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  methods: {
    get,
    ...mapActions('school', ['getSchoolsCallLogs']),
    truncate,
    onRowClicked(data) {
      this.$router.push({
        name: 'call-profile',
        params: { id: data.id },
      });
    },
    async fetchCallLogs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getSchoolsCallLogs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...this.filters,
        ...params,
      });
      this.callLogs = response.data.results;
      this.currentPage = pageNum;
      this.totalCallLogs = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchCallLogs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchCallLogs();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce((vm) => {
      vm.fetchCallLogs();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.fetchCallLogs();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
