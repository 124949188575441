<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </fmv-avatar>
          </div>
        </div>
      </template>

      <div :class="containerClass" class="mb-3">
        <div class="backResult my-3 font-weight-bold">
          <a href="#" @click.prevent="$router.go(-1)" style="text-decoration: underline" class="mr-2">
            <span class="material-icons icon-22pt">navigate_before</span>
          </a>
        </div>

        <div
          class="bg-dark employer-profile-bg border-bottom-white overlay overlay--primary-pickled-bluewood"
          :style="`background-image: url('${program.image}');`"
        >
          <div class="overlay__content page-section employer-profile-cover-ol">
            <div
              class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
              :class="containerClass"
            >
              <div class="d-flex justify-space-between mb-32pt mb-md-0 w-100">
                <div>
                  <h2 class="text-white mb-0" style="margin-top: 20%" v-text="program.title" />

                  <b-skeleton-wrapper :loading="isLoading">
                    <template #loading>
                      <p class="lead text-white-50">
                        <b-skeleton width="60%"></b-skeleton>
                      </p>
                    </template>

                    <b-badge
                      variant="primary"
                      style="font-size: 1rem"
                      :style="
                        `background: linear-gradient(30deg, #0803032e 5%, ${get(
                          getCurrentSchool,
                          'primary_color'
                        )} 100%)`
                      "
                    >
                      {{ $t(`programTypeOptions.${program.type}`) }}
                    </b-badge>
                  </b-skeleton-wrapper>
                </div>
                <div class="ml-auto mt-auto">
                  <b-btn
                    @click.prevent="updateProgramStatus"
                    :variant="partnership.status ? 'danger' : 'primary'"
                    :disabled="isStatusUpdating"
                  >
                    {{ partnership.status ? 'Hide from portal' : 'Show on portal' }}
                  </b-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="containerClass">
        <div style="display: center">
          <div class="">
            <div class=" student-card mt-0 pt-0 mb-32pt">
              <div class="row">
                <div :class="get(program, 'video_link') ? 'col-md-6' : 'col-12'">
                  <div class="card border-left-0">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div>
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Features</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex">
                              <h6 v-if="program.duration_type" class="">
                                <i class="fas fa-calendar-week icon-16pt mr-8pt"></i>Program
                                {{ $t(`durationTypesEn.${program.duration_type}`) }}: {{ program.duration }}
                              </h6>
                              <h6 class="">
                                <i class="fas fa-stream icon-16pt mr-8pt"></i>Learn Method:
                                {{ $t(`programTypeOptions.${program.type}`) }}
                              </h6>

                              <h6 class="">
                                <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                                >{{ get(program, 'school.app_fee_name') || 'Application Fee' }}:
                                {{ $n(program.application_fee, 'currency', 'en-US') }}
                              </h6>
                              <h6 class="">
                                <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                                >{{ get(program, 'school.tuition_fee_name') || 'Tuition Fee' }}:
                                {{ $n(program.tuition_fee, 'currency', 'en-US') }}
                              </h6>

                              <h6 class="">
                                <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                                >{{ get(program, 'school.ttl_tuition_name') || 'Total Tuition ' }}:
                                {{ $n(totalTuition, 'currency', 'en-US') }}
                              </h6>
                            </div>
                          </div>

                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="get(program, 'video_link')"
                  :class="get(program, 'video_link') ? 'col-md-6' : ''"
                  class="clickable-item video-card mb-3"
                  style="border-left: none"
                  @click.prevent="handleVideoOpen"
                >
                  <video
                    width="100%"
                    height="222px"
                    autoplay
                    loop
                    muted
                    style="object-fit: cover; border-radius: 10px; box-shadow: 0 3px 3px #00000026"
                  >
                    <source :src="get(program, 'video_link')" type="video/mp4" />
                  </video>
                  <a class="play-btn clickable-item" @click.prevent="handleVideoOpen">
                    <md-icon style="font-size: 80px; color: ">play_arrow</md-icon>
                  </a>
                </div>
              </div>

              <div class="card border-left-0">
                <div class="card-body">
                  <div class="d-flex mb-1">
                    <div class="flex">
                      <div class="d-flex align-items-center mb-1">
                        <h5 class="card-title fw600">Description</h5>
                      </div>

                      <div class="d-flex">
                        <div class="flex program-description">
                          <div v-safe-html="get(program, 'overview', '')" />
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
    <promo-video-modal :showModal="videoModal" @close="hideVideoModal" :url="get(program, 'video_link')" />
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate, formatTimeAgo } from '../../../common/utils';
import { FmvAvatar } from 'fmv-avatar';
import { get, isEmpty } from 'lodash';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { USER_ROLE_TYPES, PARTNERSHIP_STATUS, USER_ROLES, GENDERS } from '@/common/constants';
import PromoVideoModal from '../../SchoolPublicLanding/Programs/DetailsTab/VideoModal/PromoVideoModal.vue';

export default {
  components: { MdIcon, FmvAvatar, PromoVideoModal },
  extends: Page,
  name: 'SchoolProfile',

  data() {
    return {
      DefaultAvatar,
      USER_ROLE_TYPES,
      USER_ROLES,

      title: 'School Profile',
      showAppStatusModal: false,

      isLoading: true,
      areStudentsLoading: true,
      stdFirstLoading: true,

      stdCountLoading: false,
      program: {},
      schoolId: null,
      schoolStudents: 0,

      totalStudents: 0,
      students: [],
      perPage: 10,
      currentPage: 1,

      studentSearchTerm: '',
      studentOrdering: 'user__first_name,user__last_name',

      windowWidth: window.innerWidth,

      showRequestModal: false,
      partnership: {
        status: false,
      },
      isPartnershipLoading: false,
      PARTNERSHIP_STATUS,
      openDetailsModal: false,

      GENDERS,

      isPartnershipResponding: false,
      openRequestPartnershipDetailModal: false,

      unlockedStatusesLoading: false,
      unlockedStatusesById: {},
      firstLoad: true,
      showModal: false,
      programs: [],
      videoModal: false,

      isStatusUpdating: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    totalTuition() {
      return get(this.program, 'tuition_fee', 0) + get(this.program, 'application_fee', 0);
    },

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'School Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('program', ['getProgramFromSlug']),
    ...mapActions('school', ['showAffiliatedPrg', 'hideAffiliatedPrg', 'programStatus']),

    // ...mapActions('student', ['getAllStudents', 'getBlurredStudents', 'getStudentsUnlockedStatuses']),
    // ...mapActions('fileDownload', ['downloadFile']),
    // ...mapActions('partnership', ['getPartnership', 'getStatus', 'acceptPartnershipPaymentSuccess']),
    get,
    isEmpty,
    formatFullDate,
    formatTimeAgo,
    handleVideoOpen() {
      this.videoModal = true;
    },
    hideVideoModal() {
      this.videoModal = false;
    },

    async fetchPartnership() {
      this.isPartnershipLoading = true;

      const response = await this.programStatus({ prg_ids: [this.program.id] });

      this.partnership = response.data.data[0];

      this.isPartnershipLoading = false;
    },

    async updateProgramStatus() {
      this.isStatusUpdating = true;
      try {
        if (this.partnership.status) {
          await this.hideAffiliatedPrg({ prg_id: this.program.id });
          this.makeToast({ variant: 'success', msg: 'Program hidden from enrollment site.' });
        } else {
          await this.showAffiliatedPrg({ prg_id: this.program.id });
          this.makeToast({ variant: 'success', msg: 'Program shown on enrollment site.' });
        }

        this.partnership.status = !this.partnership.status;
      } catch (err) {
        //
      }
      this.isStatusUpdating = false;
    },

    async fetchSchool() {
      this.isLoading = true;

      try {
        const resp = await this.getProgramFromSlug({ slug: this.$route.params.slug });
        this.program = resp;

        this.schoolId = this.program.id;
      } catch (err) {
        //
      }

      this.isLoading = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    await this.fetchSchool();

    // if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
    this.fetchPartnership();
    // }
    // this.fetchSchoolStdCounts();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
