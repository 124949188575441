<template>
  <div>
    <about-page />
  </div>
</template>

<script>
import AboutPage from '@/components/WebsiteConfig/AboutPage.vue';

export default {
  components: {
    AboutPage
  }
};
</script>
