<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit FAQ' : 'Add New FAQ'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-16pt"
    />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Question" label-for="question" label-class="form-label">
        <b-form-input
          id="question"
          placeholder="Enter Question"
          v-model.trim="question"
          :disabled="isFormLoading"
          :state="!$v.question.required && $v.question.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Answer" label-for="answer" label-class="form-label">
        <b-form-textarea
          class="form-control"
          rows="10"
          placeholder="Please write your answer here."
          v-model.trim="answer"
          :disabled="isFormLoading"
          :state="!$v.answer.required && $v.answer.$dirty ? false : null"
        ></b-form-textarea>
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { required } from 'vuelidate/lib/validators';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  extends: Page,
  components: { PageHeader },
  data() {
    return {
      title: this.$route.params.id ? 'Edit FAQ' : 'Add FAQ',
      question: '',
      answer: '',
      isLoading: false,
    };
  },
  validations() {
    return {
      question: { required },
      answer: { required },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return this.isLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'manage-programs' } },
        {
          text: 'Manage FAQs',
          to: { name: 'prg-faq-management-list', params: { programId: this.$route.params.programId } },
        },
        { text: this.$route.params.id ? 'Edit FAQ' : 'Add FAQ', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('program', ['createProgramFaq', 'updateProgramFaq', 'getProgramFaq']),
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            question: this.question,
            answer: this.answer,
            program: this.$route.params.programId,
            school: this.getLoggedInUser.linked_entity.id,
          };
          if (this.$route.params.id) {
            await this.updateProgramFaq({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: this.$t('FAQ Updated!') });
          } else {
            await this.createProgramFaq({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'FAQ Added!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: 'prg-faq-management-list',
                params: { programId: this.$route.params.programId },
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getProgramFaq(this.$route.params.id)).data;
        this.question = resp.question;
        this.answer = resp.answer;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'prg-faq-management-list',
        params: { programId: this.$route.params.programId },
      });
    }

    this.isLoading = false;
  },
};
</script>

<style>
</style>