<template>
  <div>
    <campus-page />
  </div>
</template>

<script>
import CampusPage from '@/components/WebsiteConfig/CampusPage.vue';

export default {
  components: {
    CampusPage,
  },
};
</script>
