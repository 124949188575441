<template>
  <b-modal ref="paymentPlanModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group
        v-if="isClassSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED"
        label="Date"
        label-for="date"
        label-class="form-label"
      >
        <date-picker
          v-model="paymentData.date"
          format="DD MMMM, YYYY"
          valueType="date"
          style="width: 100%"
          id="date"
          lang="en"
          placeholder="Select Due Date"
          :state="!$v.paymentData.date.required && $v.paymentData.date.$dirty ? false : null"
          :input-class="
            `mx-input ${!$v.paymentData.date.required && $v.paymentData.date.$dirty ? 'form-control is-invalid' : ''}`
          "
          :disabled="isLoading"
        ></date-picker>

        <b-form-invalid-feedback :state="!$v.paymentData.date.required && $v.paymentData.date.$dirty ? false : null">
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-else label="Days" label-for="days" label-class="form-label">
        <currency-input
          id="days"
          v-model="paymentData.days"
          class="form-control"
          placeholder="Enter days"
          :allow-negative="false"
          :precision="0"
          :value-range="{ min: 1 }"
          :currency="null"
          :state="!$v.paymentData.days.required && $v.paymentData.days.$dirty ? false : null"
        />
        <b-form-invalid-feedback :state="!$v.paymentData.days.required && $v.paymentData.days.$dirty ? false : null">
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Type" label-for="type" label-class="form-label">
        <div class="custom-select-icon" style="min-width: 130px">
          <b-form-select
            class="custom-select-icon__select"
            v-model="amountType"
            placeholder="Select Type"
            :options="PAYMENT_TYPE_OPTIONS"
            :disabled="!!type"
          >
            ></b-form-select
          >
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
        <b-form-invalid-feedback :state="!$v.amountType.required && $v.amountType.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="amountType"
        :label="`Payment ${amountType === 'percentage' ? 'Percentage' : 'Amount'}`"
        label-for="amount"
        label-class="form-label"
      >
        <currency-input
          id="amount"
          v-model="paymentData.amount"
          class="form-control"
          :class="{ 'is-invalid': !$v.paymentData.amount.required && $v.paymentData.$dirty }"
          :placeholder="`Enter payment ${amountType === 'percentage' ? 'percentage' : 'amount'}`"
          :allow-negative="false"
          :value-range="amountType === PAYMENT_TYPE.PERCENTAGE ? { min: 0, max: 100 } : {}"
          :currency="amountType === PAYMENT_TYPE.PERCENTAGE ? { prefix: '', suffix: '%' } : { prefix: '$', suffix: '' }"
        />
        <b-form-text v-if="type === PAYMENT_TYPE.PERCENTAGE">
          <b>Partial Tuition Fee:</b> {{ $n(computedTuitionFee, 'currency', 'en-US') }}
        </b-form-text>
        <b-form-invalid-feedback :state="!$v.paymentData.amount.required && $v.paymentData.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn
        variant="primary"
        style="width: 200px"
        type="submit"
        class="btn-normal d-flex ml-auto"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else> {{ payment ? 'Update' : 'Add' }} Payment Due</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { PAYMENT_TYPE, PAYMENT_TYPE_OPTIONS, CLASS_SCHEDULE_TYPES } from '@/common/constants';

export default {
  name: 'PaymentPlanModal',
  components: { DatePicker },

  props: {
    showModal: { type: Boolean, default: false },
    payment: { type: Object || null },
    paymentIndex: { type: Number || null },
    programTuition: { type: Number || null },
    type: { type: String || null },
    isClassSelfPaced: { type: String, default: '' },
  },

  data() {
    return {
      PAYMENT_TYPE,
      PAYMENT_TYPE_OPTIONS,
      paymentData: {
        date: '',
        days: '',
        amount: null,
      },
      amountType: null,
      modalTitle: '',
      isLoading: false,
      CLASS_SCHEDULE_TYPES,
    };
  },

  validations() {
    return {
      paymentData: {
        date: { required: requiredIf(() => this.isClassSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED) },
        days: { required: requiredIf(() => this.isClassSelfPaced !== CLASS_SCHEDULE_TYPES.SCHEDULED) },
        amount: { required },
      },
      amountType: { required },
    };
  },

  computed: {
    computedTuitionFee() {
      return this.programTuition * ((this.paymentData.amount || 0) / 100);
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = this.payment ? 'Edit Payment Plan' : 'Add Payment Plan';

        if (this.payment) {
          this.paymentData.date = new Date(this.payment.date);
          this.paymentData.amount = this.payment.amount;
          this.paymentData.days = this.payment.days_after;
        } else {
          this.paymentData = { date: '', amount: null, days: null };
        }
        this.amountType = this.type;
        this.$v.$reset();
        this.$refs.paymentPlanModal.show();
      }
    },
  },

  methods: {
    get,
    setType(value) {
      this.$emit('update:type', value);
    },
    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.paymentPlanModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.paymentPlanModal.hide();
      this.hideModal();
    },

    onSave() {
      this.isLoading = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.hideUpdateModal({
          payment: {
            date:
              this.isClassSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED
                ? moment(this.paymentData.date).format('YYYY-MM-DD')
                : null,

            days_after: this.isClassSelfPaced !== CLASS_SCHEDULE_TYPES.SCHEDULED ? this.paymentData.days : null,

            amount: this.paymentData.amount,
            amount_type: this.amountType,
          },
          index: this.paymentIndex,
          isUpdate: !!this.payment,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
