<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Location' : 'Add New Location'"
      :container-class="null"
      class="mb-16pt"
    />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Address" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="location.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="location.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="location.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="location.state"
            :reduce="(state) => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!location.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
          <!-- <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="location.state" required /> -->
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="location.country"
            :reduce="(country) => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!location.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>
      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'edit' : 'add' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { countries } from 'countries-list';
import { map } from 'lodash';
var UsaStates = require('usa-states').UsaStates;
import Page from '@/components/Page.vue';
import vSelect from 'vue-select';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  extends: Page,
  name: 'PostLocation',
  components: { vSelect, PageHeader },
  data() {
    return {
      isLoading: false,
      location: {
        zipcode: '',
        address: '',
        city: '',
        state: '',
        country: 'United States',
      },
      countryOptions: [],
      stateOptions: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
  },
  methods: {
    ...mapActions('location', ['createLocation', 'getLocation', 'updateLocation']),
    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          zipcode: this.location.zipcode,
          address: this.location.address,
          city: this.location.city,
          state: this.location.state,
          country: this.location.country,
          school: this.getLoggedInUser.linked_entity.id,
        };
        if (this.$route.params.id) {
          await this.updateLocation({
            id: this.$route.params.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Location Updated!' });
        } else {
          await this.createLocation({ ...data });
          this.makeToast({ variant: 'success', msg: 'Location Added!' });
        }
        setTimeout(() => this.$router.push({ name: 'class-location-list' }), 250);
      } catch (error) {
        this.makeToast('danger', this.$t('genErrorMsg'));
      }
      this.isLoading = false;
    },
    async fetchLocation() {
      this.isLoading = true;
      try {
        const res = await this.getLocation(this.$route.params.id);
        this.location.zipcode = res.data.zipcode;
        this.location.address = res.data.address;
        this.location.city = res.data.city;
        this.location.state = res.data.state;
        this.location.country = res.data.country;
      } catch (e) {
        this.makeToast('danger', this.$t('genErrorMsg'));
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.fetchLocation();
    }
  },
  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, (country) => ({
        value: country.name,
        text: country.name,
      }))
    );
  },
};
</script>

<style>
</style>