<template>
  <b-modal ref="canvasModal" hide-footer title="Add Canvas" centered lazy scrollable @hide="hideModal" size="lg">
    <div>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading">...</span>
              <b-img :src="image" class="img-fluid" width="40" alt="Image" v-else-if="image" />
              <i class="fas fa-shopping-bag fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'image')"
              :isUploading="isImgUploading"
              :isRequired="!image"
              placeholder="Select Image"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Text Placement" label-for="text_placement" label-class="form-label">
          <div class="row">
            <b-form-group label="x-axis" label-for="x-axis-text" label-class="form-label" class="col-md-6">
              <b-form-input type="number" min="0" id="x-axis-text" placeholder="0" v-model="xText" required>
              </b-form-input>
            </b-form-group>

            <b-form-group label="y-axis" label-for="y-axis-text" label-class="form-label" class="col-md-6">
              <b-form-input type="number" min="0" id="y-axis-text" placeholder="0" v-model="yText" required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group label="Font Size" label-for="font-size" label-class="form-label" class="col-md-6">
              <b-form-input
                type="number"
                min="0"
                id="font-size"
                placeholder="0"
                v-model="textFontSize"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Font Color" label-for="color" label-class="form-label" class="col-md-6">
              <v-swatches
                v-model.trim="colorText"
                shapes="circles"
                show-fallback
                :swatches="swatches"
                fallback-input-type="color"
                :fallback-ok-text="$t('generalMsgs.select')"
                fallback-input-class="py-0"
              ></v-swatches>
            </b-form-group>
          </div>
        </b-form-group>

        <b-form-group label="Logo Placement" label-for="logo_placement" label-class="form-label">
          <div class="row">
            <b-form-group label="x-axis" label-for="x-axis-logo" label-class="form-label" class="col-md-6">
              <b-form-input
                type="number"
                min="0"
                id="x-axis-logo"
                placeholder="0"
                v-model="xLogo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="y-axis" label-for="y-axis-logo" label-class="form-label" class="col-md-6">
              <b-form-input
                type="number"
                min="0"
                id="y-axis-logo"
                placeholder="0"
                v-model="yLogo"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group label="width" label-for="width-logo" label-class="form-label" class="col-md-6">
              <b-form-input
                type="number"
                min="0"
                id="width-logo"
                placeholder="0"
                v-model="widthLogo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="height" label-for="height-logo" label-class="form-label" class="col-md-6">
              <b-form-input
                type="number"
                min="0"
                id="height-logo"
                placeholder="0"
                v-model="heightLogo"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </b-form-group>

        <b-btn
          variant="primary"
          :disabled="isImgUploading || isLoading"
          style="width: 150px"
          type="submit"
          class="btn-normal"
        >
          <i v-if="isImgUploading || isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>{{ !isEmpty(canvas) ? $t('update') : $t('add') }}</span>
        </b-btn>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { get, isEmpty } from 'lodash';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  name: 'canvanModal',

  props: {
    showModal: { type: Boolean, default: false },
    canvas: { type: Object, default: () => {} },
    template: { type: Number, default: null },
  },
  components: { VSwatches, FmvAvatar, ImageUploader },
  data() {
    return {
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96'],
      ],
      image: '',
      colorText: '#222222',
      textFontSize: 0,
      xText: 0,
      yText: 0,
      xLogo: 0,
      yLogo: 0,
      widthLogo: 0,
      heightLogo: 0,
      isImgUploading: false,
      uploadFile: { image: null },
      uploadPercent: { image: 0 },
      uploadCancelTokenSource: { image: null },
      isLoading: false,
    };
  },

  watch: {
    async showModal(value) {
      if (value) {
        this.$refs.canvasModal.show();
        if (this.$route.params.id && !isEmpty(this.canvas)) {
          try {
            const res = await this.getTemplateCanvas(this.canvas.id);

            this.prefillCanvas(res.data);
          } catch (error) {
            //
          }
        } else if (!isEmpty(this.canvas)) {
          this.prefillCanvas(this.canvas);
        }
      }
    },
  },
  methods: {
    ...mapActions('myAI', [
      'getCanvasImageUploadPresignedUrl',
      'updateTemplateCanvas',
      'getTemplateCanvas',
      'createTemplateCanvas',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    get,
    isEmpty,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    hideModal() {
      this.resetCanvas();
      this.$emit('close');
    },
    hideModalManual() {
      this.resetCanvas();
      this.$refs.canvasModal.hide();
    },
    resetCanvas() {
      this.image = '';
      this.textFontSize = 0;
      this.colorText = '#222222';
      this.xText = 0;
      this.yText = 0;
      this.xLogo = 0;
      this.yLogo = 0;
      this.widthLogo = 0;
      this.heightLogo = 0;
    },
    prefillCanvas(canvas) {
      this.image = canvas.image;
      this.textFontSize = canvas.font_size;
      this.colorText = canvas.font_color;
      this.xLogo = canvas.logo_x_axis;
      this.yLogo = canvas.logo_y_axis;
      this.xText = canvas.text_x_axis;
      this.yText = canvas.text_y_axis;
      this.widthLogo = canvas.logo_width;
      this.heightLogo = canvas.logo_height;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getCanvasImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;

      const data = {
        ...(this.$route.params.id && { template: this.template }),
        image: this.image,
        font_size: this.textFontSize,
        font_color: this.colorText,
        text_x_axis: this.xText,
        text_y_axis: this.yText,
        logo_x_axis: this.xLogo,
        logo_y_axis: this.yLogo,
        logo_width: this.widthLogo,
        logo_height: this.heightLogo,
      };

      if (this.$route.params.id && !isEmpty(this.canvas)) {
        try {
          await this.updateTemplateCanvas({
            id: this.canvas.id,
            data,
          });
          this.$emit('canvasAdded');
        } catch (error) {
          //
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else if (this.$route.params.id && isEmpty(this.canvas)) {
        try {
          await this.createTemplateCanvas({
            ...data,
          });
          this.$emit('canvasAdded');
        } catch (error) {
          //
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        if (!isEmpty(this.canvas)) {
          this.$emit('updateCanvasData', { ...data, canvasId: this.canvas.canvasId });
        } else {
          this.$emit('canvasData', data);
        }
      }
      this.isLoading = false;
      this.hideModalManual();
    },
  },

  async mounted() {},
};
</script>
