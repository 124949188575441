<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('schoolMsgs.searchSchools')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'add-school' }" exact variant="primary" v-text="$t('schoolMsgs.addSchool')" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="schools"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(name)="data">
              <strong> {{ data.item.name }} </strong>
            </template>

            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.logo_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(owner)="data"> {{ data.item.owner.first_name }} {{ data.item.owner.last_name }} </template>

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>

            <template #cell(plan)="data">
              {{ data.item.sch_payment_plan ? $t(`school_payment_plans.${data.item.sch_payment_plan.title}`) : '-' }}
            </template>

            <template #cell(school_type)="data">
              {{ $t(`schoolTypes.${data.item.school_type}`) }}
            </template>

            <template #cell(consumed_tokens)="data">
              <span
                @click="
                  openTokensModal(
                    data.item.id,
                    data.item.consumed_tokens,
                    get(allContentTokens[data.item.id], 'school_tokens', 0)
                  )
                "
                class="d-flex justify-content-center text-primary"
                style="cursor: pointer"
              >
                {{ data.item.consumed_tokens }}
              </span>
            </template>

            <template #cell(content_tokens)="data">
              <span
                @click="
                  openTokensModal(
                    data.item.id,
                    data.item.consumed_tokens,
                    get(allContentTokens[data.item.id], 'school_tokens', 0)
                  )
                "
                class="d-flex justify-content-center text-primary"
                style="cursor: pointer"
              >
                {{ get(allContentTokens[data.item.id], 'school_tokens', 0) }}
              </span>
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="'border border-' + (data.item.is_active ? `success text-success` : `danger text-danger`)"
                variant="none"
                >{{ data.item.is_active ? $t('schoolMsgs.active') : $t('schoolMsgs.inactive') }}</b-badge
              >
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-school',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>

              <a
                href="#"
                class="svg-icon mr-2"
                @click.prevent="openAddMetaTagsModal(data.item.id, data.item.meta_tags)"
                v-b-popover.hover.top
                title="Add Meta Tags"
              >
                <md-icon class="text-info">format_list_bulleted</md-icon></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSchools"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
    <add-meta-tags-modal
      :show-modal="showAddMetaTagsModal"
      @close="hideAddMetaTagsModal"
      :index="index"
      :existingMetaTags="metaTags"
    />

    <view-tokens-modal
      :show-modal="showTokensModal"
      :consumedTokens="consumedTokens"
      :contentTokens="contentTokens"
      :schoolId="schoolId"
      @close="hideTokensModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import { get, debounce, keyBy } from 'lodash';
import Page from '@/components/Page.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Pagination from '../../../components/Ui/Pagination.vue';

import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import AddMetaTagsModal from './AddMetaTagsModal.vue';
import ViewTokensModal from './ViewTokensModal.vue';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar, AddMetaTagsModal, ViewTokensModal },
  extends: Page,

  data() {
    return {
      title: this.$t('schoolMsgs.manageSchools'),

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSchools: 0,
      schools: [],
      showAddMetaTagsModal: false,
      showTokensModal: false,
      index: null,
      schoolId: null,
      metaTags: [],
      searchTerm: '',
      consumedTokens: 0,
      allContentTokens: [],
      contentTokens: 0,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('schoolMsgs.schools'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'school_type', label: 'Type' },
        { key: 'owner', label: this.$t('schoolMsgs.owner') },
        { key: 'owner.email', label: this.$t('schoolMsgs.ownersEmail') },
        { key: 'phone', label: this.$t('schoolMsgs.phone') },
        { key: 'address', label: this.$t('schoolMsgs.address') },
        { key: 'plan', label: 'Payment Plan' },
        { key: 'consumed_tokens', label: 'Consumed Chat Tokens' },
        { key: 'content_tokens', label: 'Consumed Content Tokens' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('myAI', ['getAllSchoolsContentTokens']),
    get,
    async fetchSchools(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSchools({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.schools = response.data.results;
      this.currentPage = pageNum;
      this.totalSchools = response.data.count;
      this.fetchAllSchoolsContentTokens();
      this.isLoading = false;
    },
    async fetchAllSchoolsContentTokens() {
      const schoolIds = this.schools.map(school => school.id);

      const data = {
        schools: schoolIds,
      };

      try {
        const res = await this.getAllSchoolsContentTokens({
          data,
        });

        this.allContentTokens = keyBy(res.data, 'school');
      } catch (error) {
        //
      }
    },
    openAddMetaTagsModal(index, metaTags) {
      this.metaTags = metaTags;
      this.index = index;
      this.showAddMetaTagsModal = true;
    },
    hideAddMetaTagsModal() {
      this.showAddMetaTagsModal = false;
      this.fetchSchools(this.currentPage);
    },
    openTokensModal(id, consumedTokens, contentTokens) {
      this.schoolId = id;
      this.consumedTokens = consumedTokens;
      this.contentTokens = contentTokens;
      this.showTokensModal = true;
    },
    hideTokensModal() {
      this.showTokensModal = false;
    },
    onPageChange(pageNum) {
      this.fetchSchools(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSchools();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce(vm => {
      vm.fetchSchools();
    }, 500),
  },

  async mounted() {
    await this.fetchSchools();
  },
};
</script>
