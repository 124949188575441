<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <b-form-group
          :label="$t('schoolMsgs.school')"
          label-for="school"
          label-class="form-label"
          v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
        >
          <v-select
            id="school"
            class="form-control v-select-custom"
            label="name"
            v-model="school"
            :reduce="school => school.id"
            placeholder="Select School"
            :options="allSchools"
            :loading="areSchoolsLoading"
            @input="prefillLogo(school)"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <page-separator title="Manage Blogs" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Blog"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top>
                  <b-btn
                    :to="{ name: 'sa-add-blog', query: { ...(school && { school_id: school }) } }"
                    exact
                    variant="primary"
                    >Post a Blog</b-btn
                  >
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Blogs Table -->
            <b-table
              :fields="tableColumns"
              :items="blogs"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(image_url)="data">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                  <b-img
                    :src="data.item.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.image_url"
                  />
                  <i class="material-icons" v-else>event</i>
                </fmv-avatar>
              </template>
              <template #cell(title)="data">
                <strong> {{ data.item.title }} </strong>
              </template>

              <template #cell(status)="data">
                <b-badge
                  :class="'border border-' + (data.item.is_active ? `success text-success` : `danger text-danger`)"
                  variant="none"
                  >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>

              <template #cell(actions)="data">
                <a
                  href="#"
                  class="mr-1"
                  @click.prevent="toggleBlogStatus(data.index)"
                  v-b-popover.hover.left
                  :title="data.item.is_active ? 'Mark Inactive' : 'Mark Active'"
                >
                  <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                    data.item.is_active ? 'block' : 'check_circle_outline'
                  }}</md-icon>
                </a>
                <router-link
                  :to="{
                    name: 'sa-edit-blog',
                    params: { id: data.item.id },
                  }"
                  class="svg-icon mr-1"
                  v-b-popover.hover.left
                  :title="$t('generalMsgs.edit')"
                >
                  <md-icon class="text-info">edit</md-icon>
                </router-link>
                <a
                  href="#"
                  @click.prevent="confirmDeleteBlog(data.item.id)"
                  v-b-popover.hover.left
                  :title="$t('generalMsgs.remove')"
                >
                  <md-icon class="text-danger">delete</md-icon>
                </a>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalBlogs"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="scholarships-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    MdIcon,
    PageHeader,
    FmvAvatar,
    Pagination,
    vSelect,
    PageSeparator,
  },
  extends: Page,

  name: 'BlogList',

  data() {
    return {
      title: 'Blogs',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalBlogs: 0,
      blogs: [],
      ordering: '-is_active,-id',
      searchTerm: '',
      showModal: false,
      selectedBlog: null,
      USER_ROLES,
      SCHOOL_PAYMENT_PLANS,
      school: null,
      areSchoolsLoading: false,
      allSchools: [],
    };
  },

  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    tableColumns() {
      return [
        { key: 'image_url', label: '' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        {
          key: 'actions',
          thStyle: { minWidth: '80px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('blog', ['getAllBlogs', 'deleteBlog', 'updateBlogStatus']),
    get,

    prefillLogo(schoolId) {
      this.$router.replace({ name: 'sa-manage-blogs', query: { school_id: this.school } });
      this.fetchBlogs(1, {}, schoolId);
    },

    async fetchBlogs(pageNum = 1, params = {}, school_id = null) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllBlogs({
        ...(school_id && { school_id: school_id }),
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.blogs = response.data.results;
      this.currentPage = pageNum;
      this.totalBlogs = response.data.count;
      this.isLoading = false;
    },

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchBlogs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchBlogs();
    },

    onSearch() {
      this.debouncedSearchBlogs(this);
    },

    debouncedSearchBlogs: debounce(vm => {
      vm.fetchBlogs();
    }, 500),
    async toggleBlogStatus(index) {
      const currStatus = this.blogs[index].is_active;
      this.blogs[index].is_active = !currStatus;

      await this.updateBlogStatus({ id: this.blogs[index].id, data: { is_active: !currStatus } });
      this.makeToast({ variant: 'success', msg: `Blog marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    async confirmDeleteBlog(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected blog.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteBlog(id);
          const refreshPage = this.blogs.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchBlogs(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Blog Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.isLoading = true;

    if (this.$route.query.school_id) {
      this.school = parseInt(this.$route.query.school_id);
      this.fetchBlogs(1, {}, this.school);
    }

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchBlogs();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
