<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit FAQ' : 'Add FAQ'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form-group
      :label="$t('schoolMsgs.school')"
      label-for="school"
      label-class="form-label"
      v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
    >
      <v-select
        id="school"
        class="form-control v-select-custom"
        label="name"
        v-model="school"
        :reduce="(school) => school.id"
        placeholder="Select School"
        :options="allSchools"
        :loading="areSchoolsLoading"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <page-separator title="FAQ Info" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Question" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter question" v-model="faq.question" required />
      </b-form-group>

      <b-form-group label="Answer" label-for="description" label-class="form-label">
        <b-form-textarea id="description" placeholder="Enter answer" v-model="faq.answer" rows="3" />
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox class="ml-auto" v-model="faq.isEnabled" name="check-button" switch> Enabled? </b-form-checkbox>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

import { requiredIf } from 'vuelidate/lib/validators';
import Page from '../../../components/Page.vue';
import { SCHOOL_PAYMENT_PLANS, USER_ROLES } from '../../../common/constants';
import vSelect from 'vue-select';
export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    vSelect,
  },
  extends: Page,

  data() {
    return {
      USER_ROLES,
      SCHOOL_PAYMENT_PLANS,

      title: this.$route.params.id ? 'Edit FAQ' : 'Add FAQ',
      school: null,
      faq: {
        question: '',
        answer: '',
        isEnabled: true,
      },
      linkedWebsite: null,
      areSchoolsLoading: false,
      allSchools: [],

      isLoading: false,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Faqs', to: { name: 'faqs' } },
        {
          text: this.$route.params.id ? 'Edit FAQ' : 'Add FAQ',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading;
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools', 'getSchoolImageUploadPresignedUrl', 'getSchool']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('webConfig', ['createFaq', 'getFaq', 'updateFaq']),
    get,

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = {
            linked_website: this.linkedWebsite,
            question: this.faq.question,
            answer: this.faq.answer,
            is_enabled: this.faq.isEnabled,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
          };

          if (this.$route.params.id) {
            await this.updateFaq({
              id: this.$route.params.id,
              data,
              linked_website: this.linkedWebsite,
            });
            this.makeToast({ variant: 'success', msg: 'FAQ succesfully updated!' });
          } else {
            await this.createFaq({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'FAQ succesfully added!' });
          }
          setTimeout(() => this.$router.push({ name: 'faqs', query: { school_id: this.school } }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.$route.query.school_id) {
      this.school = parseInt(this.$route.query.school_id);
    }
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getFaq(this.$route.params.id)).data;
        this.linkedWebsite = resp.linked_website;
        this.faq.question = resp.question;
        this.faq.answer = resp.answer;
        this.faq.isEnabled = resp.is_enabled;
        this.school = resp.school;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'faqs' });
    }

    this.isLoading = false;
  },
};
</script>
