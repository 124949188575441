<template>
  <b-modal v-model="dialog" size="lg" centered hide-footer title="PREVIEW" @hide="hideModal">
    <b-card style="overflow: auto; border-radius: unset; height: fit-content">
      <b-img :src="photo" class="white--text align-end" style="height: auto; width: 100%; object-fit: cover"></b-img>
    </b-card>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
export default {
  props: {
    showModal: { type: Boolean, default: false },
    photo: { type: String, default: '' },
  },
  data() {
    return {
      windowWidth: null,
      dialog: false,
    };
  },
  watch: {
    showModal(value) {
      this.dialog = value;
    },
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 460;
    },
    isMobScreen() {
      return this.windowWidth <= 900;
    },
  },
  methods: {
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    hideModal() {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>
