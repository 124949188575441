<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :breadcrumb="breadcrumb"
      class="mb-32pt"
      info-text="Manage school locations"
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Location"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>
                <!-- TODO: Add filters here. (if needed) -->

                <b-btn
                  class="ml-2"
                  :to="{ name: 'add-class-location' }"
                  exact
                  variant="primary"
                  v-text="'Add Location'"
                />
              </div>
            </div>
          </div>

          <!-- emails Table -->
          <b-table
            :fields="tableColumns"
            :items="locations"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            ><template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-class-location',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </router-link>

              <a
                href="#"
                @click.prevent="openRemoveModal(data.item.id)"
                v-b-popover.hover.right
                :title="'Remove Location'"
                v-if="locations.length !== 1"
              >
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalLocations"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="email-table"
            />
          </div>
        </div>
      </div>
    </div>
    <remove-location-modal :show-modal="showRemoveModal" :id="id" @close="hideRemoveModal" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import RemoveLocationModal from './RemoveLocationModal.vue';
export default {
  components: { MdIcon, PageHeader, Pagination, RemoveLocationModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Locations',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalLocations: 0,
      locations: [],
      searchTerm: '',
      id: null,
      showRemoveModal: false,
    };
  },

  computed: {
    breadcrumb() {
      return [{ text: 'Locations', active: true }];
    },

    tableColumns() {
      return [
        { key: 'address', label: 'Address', tdClass: 'clickable-item' },
        { key: 'zipcode', label: 'Zipcode', tdClass: 'clickable-item' },
        { key: 'city', label: 'City', tdClass: 'clickable-item' },
        { key: 'state', label: 'State', tdClass: 'clickable-item' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('location', ['getAllLocations']),

    openRemoveModal(id) {
      this.id = id;
      this.showRemoveModal = true;
    },
    hideRemoveModal() {
      this.id = null;
      this.showRemoveModal = false;
      this.fetchLocations();
    },
    async fetchLocations(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllLocations({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.locations = response.data.results;
      this.currentPage = pageNum;
      this.totalLocations = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchLocations(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchLocations();
    },

    onSearch() {
      this.debouncedSearchLocations(this);
    },

    debouncedSearchLocations: debounce((vm) => {
      vm.fetchLocations();
    }, 500),
  },

  async mounted() {
    this.fetchLocations();
  },
};
</script>
