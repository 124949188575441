<template>
  <div>
    <tuition-page />
  </div>
</template>

<script>
import TuitionPage from '@/components/WebsiteConfig/TuitionPage.vue';

export default {
  components: {
    TuitionPage,
  },
};
</script>
