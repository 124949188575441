<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Location' : 'Add Location'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />
    <b-form-group
      :label="$t('schoolMsgs.school')"
      label-for="school"
      label-class="form-label"
      v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
    >
      <v-select
        id="school"
        class="form-control v-select-custom"
        label="name"
        v-model="school"
        :reduce="(school) => school.id"
        placeholder="Select School"
        :options="allSchools"
        :loading="areSchoolsLoading"
        @input="prefillData(school)"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <page-separator title="Location Info" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label-for="certified_before" label-class="form-label" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="certified_before"
          v-model="campus.show_image"
          :options="YES_NO_OPTIONS"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group class="row-align-items-center" v-if="campus.show_image">
        <label class="form-label">
          Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="campus.logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="campus.logoUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="(e) => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="!campus.logoUrl && campus.show_image"
            placeholder="Select campus image"
          />
        </b-media>
      </b-form-group>

      <b-form-group v-else>
        <label class="form-label">
          Map Link
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Enter google map's url here`"
            >info</i
          >
        </label>
        <div class="row ml-1 mb-2 text-primary">
          <b-icon class="mr-3 mt-2" scale="1.8" icon="map" aria-hidden="true"></b-icon>
          <fmv-input-group-merge class="col-11 no-padding" id="name" placeholder="" v-model="campus.map_link" prepend>
            {{ campus.mapUrl }}
          </fmv-input-group-merge>
        </div>
      </b-form-group>

      <b-form-group label="Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter campus name" v-model="campus.name" required />
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea id="description" placeholder="Enter description" v-model="campus.description" rows="3" />
      </b-form-group>

      <b-form-group label="Email" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          required
          placeholder="Enter campus contact email"
          v-model="campus.email"
          type="email"
        />
      </b-form-group>

      <b-form-group label="Phone" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="campus.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="campus.address" required />
      </b-form-group>

      <b-form-group
        label="Assosiated School"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="assosiatedSchool"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allAssosiatedSchools"
          :loading="areSchoolsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="External Link Title" label-for="website" label-class="form-label">
        <b-form-input id="website" placeholder="Enter Title" v-model="campus.enrollTitle" />
      </b-form-group>

      <b-form-group label="External Link" label-for="website" label-class="form-label">
        <b-form-input id="website" placeholder="Enter link" v-model="campus.websiteUrl" />
      </b-form-group>

      <b-form-group>
        <label for="desc" class="form-label">Meta Description</label>
        <md-icon
          style="font-size: 20px; color: grey"
          class="svg-icon mr-2"
          v-b-popover.hover.right
          title="Will be used for SEO purposes"
          >info</md-icon
        >
        <b-form-textarea id="desc" rows="3" maxlength="200" v-model="meta.description"></b-form-textarea>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<style lang="css"  scoped>
.no-padding >>> .input-group-text {
  padding-left: 2;
  padding-right: 0.1rem;
  color: gray;
}
</style>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import { countries } from 'countries-list';
import { get, map, replace } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvInputGroupMerge } from 'fmv-input-group-merge';
import { requiredIf } from 'vuelidate/lib/validators';
import Page from '../../../components/Page.vue';
import { SCHOOL_PAYMENT_PLANS, USER_ROLES } from '../../../common/constants';

var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    vSelect,
    FmvInputGroupMerge,
    ImageUploader,
  },
  extends: Page,

  data() {
    return {
      USER_ROLES,
      YES_NO_OPTIONS: [
        { value: true, text: 'Show Image' },
        { value: false, text: 'Embed Map' },
      ],
      SCHOOL_PAYMENT_PLANS,
      title: this.$route.params.id ? 'Edit Location' : 'Add Location',
      school: null,
      assosiatedSchool: null,
      locationSettings: true,
      campus: {
        name: '',
        email: '',
        phone: '',
        websiteUrl: '',
        enrollTitle: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        country: 'United States',
        logoUrl: null,
        description: '',
        map_link: null,
        mapUrl: 'https://www.google.com/maps/',
        show_image: true,
      },

      meta: {
        description: '',
      },
      basicConfig: {
        footerTwitter: null,
        footerInstagram: null,

        facebookUrl: 'https://www.facebook.com/',
        instagramUrl: 'https://www.instagram.com/',
        twitterUrl: 'https://twitter.com/',
      },
      areSchoolsLoading: false,
      allSchools: [],
      allAssosiatedSchools: [],
      countryOptions: [],
      stateOptions: [],
      isLoading: false,
      isLogoUploading: false,

      uploadFile: { logo: null },
      uploadPercent: { logo: 0 },
      uploadCancelTokenSource: { logo: null },
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Campuses', to: { name: 'campus-page' } },
        {
          text: this.$route.params.id ? 'Edit Location' : 'Add Location',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading;
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools', 'getSchoolImageUploadPresignedUrl', 'getSchool']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('webConfig', ['addCampusesConfig', 'getCampus', 'updateCampusesConfig']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    async prefillData(school) {
      this.fetchSchool(school);
    },
    async fetchSchool(school) {
      try {
        const res = await this.getSchool(school);
        this.campus.email = res.data.contact_email;
        this.campus.phone = res.data.phone;
      } catch {
        // TODO: handle error
      }
    },
    async fetchAllSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({});
      this.allAssosiatedSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = {
            meta_desc: this.meta.description,
            image: this.campus.logoUrl,
            name: this.campus.name,
            description: this.campus.description,
            email: this.campus.email,
            phone: this.campus.phone,
            enroll_link: this.campus.websiteUrl,

            address: this.campus.address,
            button_title: this.campus.enrollTitle,

            map_link: !this.campus.show_image ? 'https://www.google.com/maps/' + this.campus.map_link : null,
            show_image: this.campus.show_image,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
            associated_school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? this.assosiatedSchool : null,
          };

          if (this.$route.params.id) {
            await this.updateCampusesConfig({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Location succesfully updated!' });
          } else {
            await this.addCampusesConfig({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Location succesfully added!' });
          }
          setTimeout(() => this.$router.push({ name: 'campus-page' }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'logo') {
          this.campus.logoUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
    },
  },

  async mounted() {
    if (this.$route.query.school_id) {
      this.school = parseInt(this.$route.query.school_id);
      this.fetchSchool(this.$route.query.school_id);
    }
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
      this.fetchAllSchools();
    } else {
      this.campus.email = this.getLoggedInUser.linked_entity.contact_email;
      this.campus.phone = this.getLoggedInUser.linked_entity.phone;
    }
    this.isLoading = true;

    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, (country) => ({
        value: country.name,
        text: country.name,
      }))
    );

    try {
      if (this.$route.params.id) {
        const resp = (await this.getCampus(this.$route.params.id)).data;
        this.meta.description = resp.meta_desc;
        this.campus.name = resp.name;

        this.campus.email = resp.email;
        this.campus.phone = resp.phone;
        this.campus.websiteUrl = resp.enroll_link;

        this.campus.address = resp.address;

        this.campus.logoUrl = resp.image;
        this.campus.enrollTitle = resp.button_title;
        this.campus.map_link = replace(resp.map_link, this.campus.mapUrl, '');
        this.campus.description = resp.description;
        this.campus.show_image = resp.show_image;
        this.school = resp.school;
        this.assosiatedSchool = resp.associated_school;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'campus-page' });
    }

    this.isLoading = false;
  },
};
</script>
