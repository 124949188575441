<template>
  <div id="page-container">
    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0">
            Training Partners
            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="
                `List of all training partners associated with transition. Request to affiliate with an instituition to refer their programs and earn commission.`
              "
              >info</i
            >
          </h2>
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <small class="text-muted text-headings text-uppercase mr-1 d-sm-block" v-if="!isMobSmallScreen"
              >Sort by</small
            >
            <b-dropdown :disabled="getIsFilterLoading" variant="link text-black-70" right no-caret>
              <template #button-content>
                <span>{{ $t(`studentEmpSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
              </template>

              <b-dropdown-item :active="ordering === 'name'" @click="onSortChange({ sortBy: 'name' })"
                >Name</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
                >Location</b-dropdown-item
              >
              <b-dropdown-item
                :active="ordering === 'active_students'"
                @click="onSortChange({ sortBy: 'active_students' })"
                >Active Students</b-dropdown-item
              >
            </b-dropdown>
            <b-btn
              v-if="enableSidebarToggle !== false"
              v-fmv-toggle.school-filters
              size="sm"
              variant="white"
              class="mr-2"
            >
              <md-icon left>tune</md-icon>
              <span>Filters</span>
            </b-btn>
            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalSchools ? `${pageFromCount}-${pageToCount}` : 0} of ${totalSchools} institutions`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalSchools && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Training Partners Found</h4>

              <p class="text-muted">
                Couldn't find any schools. We are constantly onboarding new institutions so please check again later.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex mr-12pt">
                      <b-skeleton width="100%"></b-skeleton
                    ></a>
                    <!-- <a href="#" @click.prevent class="" data-placement="bottom">
                      <b-skeleton width="100%"></b-skeleton
                    ></a> -->
                  </div>
                  <div class="card-body flex text-center d-flex flex-column justify-content-center">
                    <a
                      href="#"
                      @click.prevent
                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                      style="border: 1px solid #dfe2e6"
                    >
                      <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                    </a>
                    <div class="flex">
                      <p class="text-70 measure-paragraph">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-md-6 col-lg-6 card-group-row__col" v-for="school in schools" :key="school.id">
              <div
                class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox clickable-item"
                @click.prevent="openEmpProfile(school.id)"
              >
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex mr-12pt">
                    {{ school.name }}
                    <i
                      class="fas fa-handshake ml-2 d-flex align-self-center text-primary"
                      v-if="get(partnerStatusesById, `${school.id}.status`) === 'accepted'"
                      v-b-popover.hover.top="`You're affiliated with this instituition.`"
                    ></i>
                    <i
                      class="fas fa-star ml-2 d-flex align-self-center text-primary mb-11pt"
                      v-if="sponsorshipsSchoolsId.includes(school.id)"
                      v-b-popover.hover.top="'This school is associated with a sponsorship'"
                    ></i>
                  </a>
                  <a href="#" @click.prevent class="" data-placement="bottom">{{ school.city }}, {{ school.state }}</a>
                </div>
                <div class="card-body flex text-center d-flex flex-column align-items-center justify-content-center">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt"
                    style="border: 1px solid #dfe2e6"
                  >
                    <img
                      class="avatar-img"
                      :src="school.logo_url || DefaultLogo"
                      width="160"
                      height="auto"
                      style="object-fit: contain !important"
                    />
                  </a>
                  <div class="flex">
                    <p class="text-70 measure-paragraph">
                      <span v-if="trim(school.bio)">{{
                        truncate(trim(school.bio), {
                          length: 240,
                          separator: /,? +/,
                        })
                      }}</span>
                      <i v-else>{{ school.name }} has not added any bio yet.</i>
                    </p>

                    <div
                      class="card-button"
                      v-if="
                        get(schoolStdCounts, `${school.id}.students_count`, 0) &&
                          get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER
                      "
                    >
                      <ul>
                        <li>
                          <a href="#" @click.prevent
                            ><i class="material-icons text-primary icon-16pt text-100 mr-2">school</i
                            >{{ get(schoolStdCounts, `${school.id}.students_count`) }}
                            {{
                              get(schoolStdCounts, `${school.id}.students_count`) === 1
                                ? 'Active Student'
                                : 'Active Students'
                            }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalSchools > schools.length"
          v-model="currentPage"
          :total-rows="totalSchools"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="schools-table"
        />
      </div>
    </div>
    <!-- <copy-url-modal :showModal="showCopyUrlModal" @close="hideCopyUrlModal" /> -->
  </div>
</template>

<script>
import { debounce, get, keyBy, map, trim, truncate, uniq } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import DefaultLogo from '@/assets/images/logos/logo_icon.png';
import { USER_ROLE_TYPES } from '@/common/constants';
import SchoolFilters from './SchoolFilters/SchoolFilters.vue';
import SchoolFiltersSidebar from './SchoolFilters/SchoolFiltersSidebar.vue';

export default {
  components: { Pagination, PageSeparator },
  extends: Page,
  name: 'Schools',

  data() {
    return {
      DefaultLogo,
      USER_ROLE_TYPES,

      title: 'Schools',

      isLoading: true,
      partnerStatusesLoading: true,

      perPage: 14,
      currentPage: 1,
      totalSchools: 0,
      pageFromCount: 0,
      pageToCount: 0,
      schools: [],
      partnerStatusesById: {},
      partnerSchoolsId: [],
      sponsorshipsSchoolsId: [],
      searchTerm: '',

      ordering: 'name',
      showCopyUrlModal: false,
      windowWidth: window.innerWidth,
      stdCountLoading: true,
      schoolStdCounts: {},
      filters: {
        zipcode: null,
        offered_programs__title: null,
        state: null,
      },
    };
  },

  computed: {
    ...mapGetters('school', ['getSchoolFilters', 'getSchoolsEmpFilters', 'getIsFilterLoading']),
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Schools', active: true },
      ];
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? SchoolFiltersSidebar : SchoolFilters;
    },
    subLayoutDrawerId() {
      return 'school-filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {
    getSchoolsEmpFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.zipcode = filterValues.zipCode;

        this.filters.offered_programs__title = filterValues.offered_programs__title;
        this.filters.state = filterValues.state;
        this.debouncedSearchSchools(this);
      },
    },
  },

  methods: {
    ...mapActions('school', ['getAllNationalSchools', 'partnerStatus', 'getSchoolStudentCounts']),
    ...mapActions('partnership', ['getPartnersStatuses']),
    get,
    trim,
    truncate,
    openCopyUrlModal() {
      this.showCopyUrlModal = true;
    },
    hideCopyUrlModal() {
      this.showCopyUrlModal = false;
    },
    async fetchSchoolStdCounts() {
      this.stdCountLoading = true;
      const schoolIds = uniq(this.schools.map(std => std.id));
      const queryData = {
        only_recently_active: false,
        schools: schoolIds,
      };
      const resp = await this.getSchoolStudentCounts({
        ...queryData,
      });
      this.schoolStdCounts = keyBy(resp, 'school_id');
      this.stdCountLoading = false;
    },

    async fetchSchools(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const commonParams = {
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,

        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...this.filters,
        ...params,
      };

      // if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
      const response = await this.getAllNationalSchools({
        ...commonParams,
      });
      this.schools = response.data.results;
      this.currentPage = pageNum;
      this.totalSchools = response.data.count;
      // this.fetchSchoolStdCounts();

      this.getSchoolsPartnerStatuses();

      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      // } else {
      //   const response = await this.getAllOrganizationSchools({
      //     ...commonParams,
      //   });

      //   this.schools = response.data.data.results;
      //   this.currentPage = pageNum;
      //   this.totalSchools = response.data.data.count;

      //   this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      //   this.pageToCount = this.pageFromCount + get(response.data.data, 'results.length', 0) - 1;

      //   this.partnerSchoolsId = response.data.partner_schools;
      //   this.sponsorshipsSchoolsId = response.data.scholarship_schools;
      // }
      this.$store.commit('school/updateIsFilterLoading', false);
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSchools(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSchools();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce(vm => {
      vm.fetchSchools();
    }, 500),

    openEmpProfile(id) {
      // if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
      //   this.$router.push({ name: 'emp-school-profile', params: { id } });
      // } else {
      this.$router.push({ name: 'national-partner-profile', params: { id } });
      // }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async getSchoolsPartnerStatuses() {
      this.partnerStatusesLoading = true;

      const statuses = (
        await this.partnerStatus({
          // partner_type: 'school',
          school_ids: map(this.schools, 'id'),
        })
      ).data.data;
      this.partnerStatusesById = keyBy(statuses, 'request_to_id');

      this.partnerStatusesLoading = false;
    },
  },
  created() {
    this.$store.commit('school/updateIsFilterLoading', true);
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchSchools(1, { state: this.getLoggedInUser.linked_entity.state });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
