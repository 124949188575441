<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search Cities" v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>
                <b-btn class="ml-2" :to="{ name: 'sa-add-city' }" exact variant="primary" v-text="'Add Best City'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allCities"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image_url)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.image_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar></div
            ></template>
            <template #cell(active)="data">
              <b-badge
                :class="`border border-${data.item.active ? 'success' : 'danger'} text-${
                  data.item.active ? 'success' : 'danger'
                }`"
                variant="none"
                >{{ data.item.active ? 'Active' : 'Inactive' }}</b-badge
              >
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'sa-edit-city',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <a href="#" @click.prevent="updateStatus(data.index)" v-b-popover.hover.right :title="'Update Status'">
                <md-icon :class="data.item.active ? 'text-danger' : 'text-success'">{{
                  data.item.active ? 'block' : 'check_circle_outline'
                }}</md-icon>
              </a>
              <a href="#" @click.prevent="removeCity(data.item.id)" v-b-popover.hover.right :title="'Delete City'">
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalCities"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Best Cities',
      areProgramsLoading: false,
      program: null,
      allPrograms: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalCities: 0,
      searchTerm: '',
      allCities: [],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Cities', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'image_url', label: '' },
        { key: 'state.name', label: 'State' },
        { key: 'name', label: 'City' },
        { key: 'active', label: 'Status' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('location', ['getAllCities', 'deleteCity', 'updateCityStatus']),
    onFilterApplied() {
      this.fetchCities();
    },
    async updateStatus(index) {
      this.isLoading = true;
      const currStatus = this.allCities[index].active;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to mark the status ${currStatus ? 'Inactive' : 'Active'}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateCityStatus({ id: this.allCities[index].id, data: { active: !currStatus } });
          this.fetchCities();
          this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async fetchCities(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllCities({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allCities = response.data.results;
      this.currentPage = pageNum;
      this.totalCities = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchCities(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchCities();
    },

    onSearch() {
      this.debouncedSearchCities(this);
    },

    debouncedSearchCities: debounce((vm) => {
      vm.fetchCities();
    }, 500),

    async removeCity(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected city.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCity(id);
          this.fetchCities();
          this.makeToast({ variant: 'success', msg: 'City deleted successfully!' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.fetchCities();
  },
};
</script>
