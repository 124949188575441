<template>
  <b-modal
    ref="reqAffiliationModal"
    hide-footer
    title="Affiliation Request"
    centered
    lazy
    size="md"
    scrollable
    @hide="hideModal"
  >
    <p>
      <img :src="get(request, 'request_from.logo_url')" height="30px" />
      <b>{{ get(request, 'request_from.name') }}</b> has requested affiliation to refer your programs to their students.
    </p>

    <!-- <p>{{ get(request, 'message') }}</p> -->
    <!-- message with background quotation style-->
    <p class="bg-light p-2 rounded">
      <md-icon class="text-primary">format_quote</md-icon>
      {{ get(request, 'message') }}
    </p>
    <div class="d-flex justify-content-end">
      <b-btn variant="danger mr-2" @click.prevent="rejectReq"><md-icon class="mr-1">block</md-icon> Decline</b-btn>
      <b-btn variant="success" @click.prevent="acceptReq"
        ><md-icon class="mr-1">check_circle_outline</md-icon> Accept</b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'ReqAffiliationModal',

  props: {
    showModal: { type: Boolean, default: false },
    request: { type: Object, default: () => {} },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;

        this.$refs.reqAffiliationModal.show();
      }
    },
  },

  methods: {
    get,

    acceptReq() {
      this.$emit('accept', this.request);
      this.hideModalManual();
    },

    rejectReq() {
      this.$emit('reject', this.request);
      this.hideModalManual();
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.reqAffiliationModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
