<template>
  <div>
    <gallery-config />
  </div>
</template>

<script>
import GalleryConfig from '@/components/WebsiteConfig/GalleryConfig.vue';

export default {
  components: {
    GalleryConfig,
  },
};
</script>