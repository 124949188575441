<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <b-table
            :fields="tableColumns"
            :items="partnerships"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(menu)="data">
              <div v-if="data.item.status === 'requested'">
                <b-btn @click.prevent="openReqModal(data.item)" variant="info" size="sm" class="d-block w-100">
                  <md-icon>reply_all</md-icon> Respond
                </b-btn>
              </div>
              <div class="text-center" v-else>
                <!-- <b-dropdown :boundary="'main'" no-flip variant="light" size="sm" no-caret text="options">
                  <template #button-content>
                    <i class="material-icons  card-course__icon-favorite">more_vert</i>
                    <span class="sr-only">Menu</span> Options
                  </template> -->

                <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                  <template #button-content>
                    <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                    <span class="sr-only">Menu</span>
                  </template>

                  <div v-if="data.item.status === 'requested'">
                    <b-dropdown-item @click.prevent="acceptReq(data.item)">
                      <md-icon class="text-success">check_circle_outline</md-icon>
                      Accept
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="['requested'].includes(data.item.status)"
                      @click.prevent="rejectReq(data.item)"
                    >
                      <md-icon class="text-danger">block</md-icon>
                      Reject
                    </b-dropdown-item>
                  </div>

                  <b-dropdown-item @click.prevent="acceptReq(data.item)" v-else-if="data.item.status === 'revoked'">
                    <md-icon class="text-success">check_circle_outline</md-icon>
                    Re-establish
                  </b-dropdown-item>
                  <b-dropdown-item v-else @click.prevent="rejectReq(data.item)">
                    <md-icon class="text-danger">block</md-icon>
                    Dissolve
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #cell(request_from)="data">
              {{ get(data.item.request_from, 'name') }}
            </template>

            <template #cell(request_to)="data">
              {{ get(data.item.request_to, 'name') }}
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  `border border-${
                    data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                  } text-${
                    data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                  }`
                "
                variant="none"
                >{{
                  data.item.partner_revoke_at ? 'dissolved' : $t(`partnershipStatusTypes.${data.item.status}`)
                }}</b-badge
              >
            </template>

            <template #cell(partner_revoke_at)="data">
              {{ get(data.item, 'partner_revoke_at') ? formatDateTime(get(data.item, 'partner_revoke_at')) : '' }}
            </template>

            <template #cell(accepted_at)="data">
              {{ get(data.item, 'accepted_at') ? formatDateTime(get(data.item, 'accepted_at')) : '' }}
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPartnerships"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>

    <aff-request-resp-modal
      :show-modal="showAffReqModal"
      :request="request"
      @close="hideReqModal"
      @accept="acceptReq"
      @reject="rejectReq"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import AffRequestRespModal from './AffRequestRespModal.vue';

import { DEFAULT_PAGE_SIZE, USER_ROLES, USER_ROLE_TYPES, SCHOOL_TYPES } from '@/common/constants';
import { formatDateTime } from '@/common/utils';

export default {
  components: { AffRequestRespModal, PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Affiliations',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPartnerships: 0,
      partnerships: [],
      searchTerm: '',
      USER_ROLE_TYPES,
      USER_ROLES,
      SCHOOL_TYPES,

      partnershipStatuses: [
        { text: 'Accepted', value: 'request_accepted' },
        { text: 'Pending', value: 'requested' },
        { text: 'To be Dissolved', value: 'revoked' },
      ],
      request: null,
      showAffReqModal: false,
      // showAffReqModal: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Affiliations', active: true },
      ];
    },

    tableColumns() {
      return [
        ...(get(this.getLoggedInUser, 'linked_entity.school_type') === SCHOOL_TYPES.NATIONAL
          ? [
              {
                key: 'menu',
                label: '',
                tdClass: 'text-left',
              },
              { key: 'request_from', label: 'Requested By' },
            ]
          : []),

        ...(get(this.getLoggedInUser, 'linked_entity.school_type') === SCHOOL_TYPES.LOCAL
          ? [{ key: 'request_to', label: 'Requested To' }]
          : []),

        { key: 'status', label: this.$t('generalMsgs.status') },

        {
          key: 'created_at',
          label: 'Requested At',
          formatter: value => {
            return formatDateTime(value);
          },
        },
        {
          key: 'accepted_at',
          label: 'Accepted At',
          // formatter: value => {
          //   return formatDateTime(value);
          // },
        },
        {
          key: 'partner_revoke_at',
          label: 'Revoked On',
          // formatter: value => {
          //   return formatDateTime(value);
          // },
        },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getMyAffiliations', 'respondAffiliationReq']),
    formatDateTime,
    get,

    async acceptReq(item) {
      try {
        await this.respondAffiliationReq({ id: item.id, data: { status: 'accepted' } });
        this.makeToast({ variant: 'success', msg: 'Affiliation request accepted!' });
        this.fetchPartnerships();
      } catch (error) {
        //
      }
    },

    async rejectReq(item) {
      try {
        await this.respondAffiliationReq({ id: item.id, data: { status: 'revoked' } });
        this.makeToast({ variant: 'info', msg: 'Affiliation request rejected!' });
        this.fetchPartnerships();
      } catch (error) {
        //
      }
    },

    openReqModal(item) {
      this.showAffReqModal = true;
      this.request = item;
    },

    hideReqModal() {
      this.showAffReqModal = false;
      this.request = null;
    },

    async fetchPartnerships(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getMyAffiliations({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { partnership_status: this.searchTerm }),
        ...params,
      });

      this.partnerships = response.data.results;
      this.currentPage = pageNum;
      this.totalPartnerships = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPartnerships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPartnerships();
    },

    onSearch() {
      this.debouncedSearchUsers(this);
    },

    debouncedSearchUsers: debounce(vm => {
      vm.fetchPartnerships();
    }, 500),
  },

  async mounted() {
    this.fetchPartnerships();
  },
};
</script>
