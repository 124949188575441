<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search Users" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'add-user' }" exact variant="primary" v-text="'Add User'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="users"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(avatar_url)="data">
              <user-avatar slot="aside" size="md" :user="data.item"> </user-avatar>
            </template>

            <template #cell(name)="data">
              {{ get(data.item, 'first_name') }} {{ get(data.item, 'last_name') }}
            </template>
            <template #cell(role)="data"> {{ $t(`roleType.${get(data.item, 'role')}`) }} </template>
            <template #cell(entity)="data">
              <span class="d-flex align-items-end">
                <md-icon
                  class="text-info mr-2 clickable-item"
                  v-b-popover.hover.left
                  :title="get(data.item.linked_entity, 'type') === USER_ROLE_TYPES.SCHOOL ? 'School' : ''"
                  >{{
                    get(data.item.linked_entity, 'type') === USER_ROLE_TYPES.SUPER_ADMIN
                      ? ''
                      : get(data.item.linked_entity, 'type') === USER_ROLE_TYPES.SCHOOL
                      ? 'account_balance'
                      : ''
                  }}</md-icon
                >{{ get(data.item.linked_entity, 'name') || get(data.item.linked_entity, 'title') }}</span
              >
            </template>
            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' + (get(data.item, 'is_active') ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ get(data.item, 'is_active') ? 'Active' : 'Inactive' }}</b-badge
              >
            </template>
            <template #cell(last_login)="data">
              {{ get(data.item, 'last_login') ? formatDateTime(get(data.item, 'last_login')) : '' }}
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="editUser(data.item)"
                class="mr-2"
                v-b-popover.hover.left
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </a>
              <a
                href="#"
                class="mr-1"
                @click.prevent="toggleActiveStatus(data.index)"
                v-b-popover.hover.left
                :title="get(data.item, 'is_active') ? 'Mark Inactive' : 'Mark Active'"
                v-if="get(data.item, 'id') !== get(getLoggedInUser, 'id')"
              >
                <md-icon :class="get(data.item, 'is_active') ? 'text-danger' : 'text-success'">{{
                  get(data.item, 'is_active') ? 'block' : 'check_circle_outline'
                }}</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES, USER_ROLE_TYPES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, UserAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Users',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalUsers: 0,
      users: [],

      searchTerm: '',
      USER_ROLE_TYPES,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Users', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'avatar_url', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'email', label: this.$t('schoolMsgs.ownersEmail') },
        { key: 'role', label: 'Role' },
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? [{ key: 'entity', label: 'Entity' }] : []),
        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        {
          key: 'date_joined',
          label: 'Joined On',
          formatter: (value) => {
            return formatDateTime(value);
          },
        },
        {
          key: 'last_login',
          label: 'Last Loggedin',
        },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('user', ['getAllUsers', 'deleteUser', 'updateActive', 'getAllEntityUsers', 'updateEntityActive']),
    formatDateTime,
    get,
    editUser(row) {
      this.$router.push({
        name: 'edit-user',
        params: {
          id: get(row, 'id'),
          backTo: true,
        },
      });
    },

    async toggleActiveStatus(index) {
      const currStatus = this.users[index].is_active;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to ${currStatus ? 'deactivate' : 'activate'} the selected account.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: currStatus ? 'danger' : 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.users[index].is_active = !currStatus;
          if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) {
            await this.updateEntityActive({ id: this.users[index].id, data: { is_active: !currStatus } });
          } else await this.updateActive({ id: this.users[index].id, data: { is_active: !currStatus } });

          this.makeToast({ variant: 'success', msg: `User account ${currStatus ? 'deactivated' : 'activated'}` });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchUsers(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;
      if ([USER_ROLES.SCHOOL_OWNER].includes(this.getLoggedInUser.role)) {
        response = await this.getAllEntityUsers({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          ...params,
        });
      } else {
        response = await this.getAllUsers({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          ...params,
        });
      }
      this.users = response.data.results;
      this.currentPage = pageNum;
      this.totalUsers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchUsers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchUsers();
    },

    onSearch() {
      this.debouncedSearchUsers(this);
    },

    debouncedSearchUsers: debounce((vm) => {
      vm.fetchUsers();
    }, 500),
  },

  async mounted() {
    this.fetchUsers();
  },
};
</script>
