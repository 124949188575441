<template>
  <div class="bg-light">
    <b-modal
      ref="postLocationModal"
      hide-footer
      title="Add Registered Student"
      centered
      lazy
      @hide="hideModal"
      size="lg"
    >
      <div>
        <b-alert show variant="light">
          Fill the below form to register a student who has already been admitted in your institution. This process
          won't involve any interaction with the student for filling out any details and no online payment will be taken
          from the student on registration. For complete registration of new students
          <span @click.prevent="hideAndShowModal" class="text-primary clickable-item">
            <b> initiate a registration here. </b>
          </span>
        </b-alert>
      </div>
      <b-form class="col-md-12 px-0 page-section pt-0 pb-2" @submit.prevent="onSubmit">
        <!-- User Details -->
        <div class="row">
          <b-form-group label="First Name" label-for="firstname" label-class="form-label" class="col-md-6">
            <b-form-input
              id="firstname"
              :placeholder="$t('userMsgs.firstName')"
              v-model.trim="user.firstName"
              :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.firstName.required"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Last Name" label-for="lastname" label-class="form-label" class="col-md-6">
            <b-form-input
              id="lastname"
              :placeholder="$t('userMsgs.lastName')"
              v-model.trim="user.lastName"
              :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.lastName.required">This field is required.</b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group label="Email Address" label-for="email" label-class="form-label">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            type="email"
            v-model.trim="user.email"
            :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="!$v.user.email.email">
            Invalid email. Please make sure that it's in correct format i.e. test@example.com.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Phone" label-for="phone" label-class="form-label">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-model="user.phone"
            v-mask="'(###) ### - ####'"
            :state="(!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="!$v.user.phone.required"> This field is required. </b-form-invalid-feedback>
          <b-form-invalid-feedback v-else>
            Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Birth Date" label-for="gradDate" label-class="form-label">
          <date-picker
            v-model="student.birthday"
            type="date"
            format="MM/DD/YYYY"
            value-type="YYYY-MM-DD"
            style="width: 100%"
            id="birthday"
            lang="en"
            placeholder="MM/DD/YYYY"
            :input-class="`mx-input ${
              !$v.student.birthday.required && $v.student.birthday.$dirty ? 'form-control is-invalid' : ''
            }`"
          ></date-picker>

          <b-form-invalid-feedback :state="!$v.student.birthday.required && $v.student.birthday.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Gender" label-for="gender" label-class="form-label">
          <v-select
            id="gender"
            class="form-control v-select-custom"
            label="text"
            v-model="user.gender"
            :reduce="(item) => item.value"
            placeholder="Select gender"
            :options="genderOptions"
            :class="!$v.user.gender.required && $v.user.gender.$dirty ? 'form-control is-invalid' : ''"
          >
          </v-select>
          <b-form-invalid-feedback :state="!$v.user.gender.required && $v.user.gender.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Payment Status" label-for="payment_status" label-class="form-label">
          <v-select
            id="payment_status"
            class="form-control v-select-custom"
            label="text"
            v-model="payment_status"
            placeholder="Select status"
            :options="STD_REG_FORM_MANUALLY_PAY_OPTIONS"
            :class="!$v.payment_status.required && $v.payment_status.$dirty ? 'form-control is-invalid' : ''"
            :clearable="false"
          >
          </v-select>
          <b-form-invalid-feedback :state="!$v.payment_status.required && $v.payment_status.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Registered On" label-for="End Time" label-class="form-label">
          <date-picker
            v-model="dateTime"
            type="datetime"
            lang="en"
            value-type="YYYY-MM-DDTHH:mm"
            format="YYYY-MM-DD HH:mm"
            :minute-step="15"
            style="width: 100%"
            :placeholder="'Select registration time'"
            :input-class="`mx-input ${!$v.dateTime.required && $v.dateTime.$dirty ? 'form-control is-invalid' : ''}`"
          ></date-picker>
          <b-form-invalid-feedback :state="!$v.dateTime.required && $v.dateTime.$dirty ? false : null">
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-btn
          variant="primary"
          :disabled="isLoading"
          style="width: 150px"
          type="submit"
          class="btn-normal"
          @hide="hideModal"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>add</span>
        </b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import generator from 'generate-password';
import DatePicker from 'vue2-datepicker';
import { required, email } from 'vuelidate/lib/validators';

import { PHONE_FORMAT_REGEX, GENDER_OPTIONS, STD_REG_FORM_MANUALLY_PAY_OPTIONS } from '../../../../common/constants';
import { formatToAPIDate, titleize } from '../../../../common/utils';

import { formatToAPIDateTime } from '../../../../common/dateTimeUtils';
import vSelect from 'vue-select';
export default {
  props: {
    showModal: Boolean,
    selectedClass: { type: Object, default: () => ({}) },
  },
  components: {
    DatePicker,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: '',
      },
      // paid_amount: null,
      dateTime: '',
      student: {
        birthday: null,
      },
      gender: null,
      genderOptions: GENDER_OPTIONS,
      payment_status: '',
      STD_REG_FORM_MANUALLY_PAY_OPTIONS,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  validations() {
    const val = {
      student: {
        birthday: { required },
      },
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
        gender: { required },
      },

      dateTime: {
        required,
      },
      payment_status: { required },
    };

    return val;
  },
  methods: {
    ...mapActions('student', ['registerStudentManually']),
    hideModal() {
      this.$emit('close');
    },
    hideModalManual() {
      this.$refs.postLocationModal.hide();
      this.hideModal();
    },
    hideAndShowModal() {
      this.hideModalManual();
      this.$emit('closeAndShowModal', this.selectedClass);
    },
    hideUpdateModal(student_info) {
      this.$refs.postLocationModal.hide();
      this.$emit('closeUpdate', student_info);
    },
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            student: {
              birthday: this.student.birthday ? formatToAPIDate(moment(this.student.birthday)) : null,
              user: {
                email: this.user.email,
                first_name: titleize(this.user.firstName),
                last_name: titleize(this.user.lastName),
                password: generator.generate({ length: 10, numbers: true, strict: true }),
                phone: this.user.phone,
                gender: this.user.gender,
              },
            },
            created_at: formatToAPIDateTime(this.dateTime, this.getLoggedInUser.linked_entity.time_zone),
            class_enrolled: this.selectedClass.id,
            program: this.selectedClass.program.id,
            payment_status: this.payment_status.value,
          };

          const res = (await this.registerStudentManually({ ...data })).data.data;
          setTimeout(() =>
            this.makeToast({
              variant: 'success',
              msg: 'Student registered Successfully',
            })
          );
          this.hideUpdateModal(res);
        } catch (err) {
          if (err.response.status === 400) {
            this.makeToast({
              variant: 'danger',
              msg: err.response.data.message,
              autoHideDelay: 5000,
            });
          } else {
            this.makeToast({
              variant: 'danger',
              msg: 'Cannot register you in this class. Please contact administrator.',
              autoHideDelay: 5000,
            });
          }
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },
  },
  async mounted() {
    this.$refs.postLocationModal.show();
  },
};
</script>

<style>
</style>
