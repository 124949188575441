<template>
  <div :class="containerClass">
    <page-header
      title="Configure Custom Pages"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Have full control over additional pages – add or remove as needed.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator title="Manage Custom Pages" />

      <div class="d-flex flex-wrap justify-content-end">
        <b-btn
          variant="primary"
          :to="{ name: 'add-custom-page', query: { ...(school && { school_id: school }) } }"
          style="width: 150px"
          type="submit"
          class="btn-normal mb-2"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Add Page</span>
        </b-btn>
      </div>

      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <b-skeleton-table :rows="2" :columns="3" :table-props="{ hover: true }"></b-skeleton-table>
        </template>

        <div v-if="totalCustomPages">
          <!-- Custom Pages Table -->
          <b-table
            :fields="tableColumns"
            :items="customPages"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image_url)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.image_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.image_url" />
                <i class="material-icons" v-else>event</i>
              </fmv-avatar>
            </template>

            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-custom-page',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-1"
                v-b-popover.hover.left
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <a
                href="#"
                @click.prevent="confirmDeleteCustomPage(data.item.id)"
                v-b-popover.hover.left
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalCustomPages"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="scholarships-table"
            />
          </div>
        </div>

        <b-card
          v-else-if="
            (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && school) ||
            get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
          "
          class="text-center"
        >
          <i> There isn't any custom pages added. Please add a page if you have any. </i>
        </b-card>
      </b-skeleton-wrapper>
    </b-form>
  </div>
</template>

<style lang="css" scoped>
</style>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { get, isEmpty } from 'lodash';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { mapGetters, mapActions } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import Pagination from '@/components/Ui/Pagination.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS, DEFAULT_PAGE_SIZE } from '@/common/constants';
import vSelect from 'vue-select';
export default {
  components: {
    PageHeader,
    FmvAvatar,
    Pagination,
    vSelect,
    PageSeparator,
  },
  extends: Page,
  data() {
    return {
      customPages: [],

      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Custom Pages',
      linkedWebsite: null,
      school: null,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      totalCustomPages: null,
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    tableColumns() {
      return [
        { key: 'image_url', label: '' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'navbar_title', label: 'Nav Title', tdClass: 'clickable-item' },

        {
          key: 'actions',
          label: '',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.areSchoolsLoading;
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getCustomPages', 'deleteCustomPage']),
    get,
    isEmpty,

    prefillLogo(schoolId) {
      this.$router.replace({ name: 'manage-custom-pages', query: { school_id: this.school } });
      this.fetchCustomPages(schoolId);
    },

    onPageChange(pageNum) {
      this.fetchCustomPages(pageNum);
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchCustomPages(school_id = null) {
      this.isLoading = true;
      let resp;
      if (school_id) {
        resp = await this.getCustomPages({
          school_id: school_id,
        });
      } else {
        resp = await this.getCustomPages({});
      }

      this.customPages = resp;
      this.totalCustomPages = this.customPages.length;

      this.isLoading = false;
    },
    async confirmDeleteCustomPage(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to permanently delete the selected custom page. If you want to inactivate the page you can change the enable status from navbar.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCustomPage(id);

          this.fetchCustomPages(
            get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? this.$route.query.school_id : null
          );
          this.makeToast({ variant: 'success', msg: 'Page Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchCustomPages();
    },
  },

  async mounted() {
    this.isLoading = true;

    if (this.$route.query.school_id) {
      this.school = parseInt(this.$route.query.school_id);

      this.fetchCustomPages(this.school);
    }

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchCustomPages();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
