<template>
  <div :class="containerClass">
    <div class="backResult text-50">
      <a @click.prevent="goBack" style="text-decoration: underline" class="clickable-item">
        <span class="material-icons icon-18pt text-80">navigate_before</span>
      </a>
    </div>
    <page-header
      title="Initiated Registrations"
      :container-class="null"
      class="mb-16pt"
      :info-text="'New initiated registrations.'"
    />

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-lg-auto">
            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search here" v-model="searchTerm" @input="onSearch" />

              <b-btn variant="flush" type="submit">
                <md-icon>search</md-icon>
              </b-btn>
            </form>
          </div>
          <div class="col-lg d-flex flex-wrap justify-content-end mb-3">
            <b-btn
              style="width: 120px"
              class="btn-normal"
              variant="primary"
              @click.prevent="resendAll()"
              v-if="totalData"
            >
              <i v-if="isResendingAll" class="fas fa-circle-notch fa-spin"></i> <span v-else>Resend all</span></b-btn
            >

            <div class="col-sm-4 col-md-4 mb-2 px-1">
              <v-select
                label="id"
                class="form-control v-select-custom"
                v-model="classId"
                :reduce="item => item.id"
                placeholder="Filter by class id"
                :options="allClasses"
                @input="fetchData()"
              >
                <template slot="option" slot-scope="option"> #{{ option.id }} </template>
                <template slot="selected-option" slot-scope="option"> #{{ option.id }} </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <b-table
        :fields="tableColumns"
        :items="initiateData"
        :busy="isLoading"
        head-variant="light"
        class="table-nowrap"
        hover
        responsive
        no-local-sorting
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(menu)="data">
          <div>
            <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
              <template #button-content>
                <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                <span class="sr-only">Menu</span>
              </template>

              <b-dropdown-item @click.prevent="resend(data.item.id)">
                <md-icon class="text-info">email</md-icon> Resent Email
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="copyUrl(data.item.id)">
                <md-icon class="text-info">content_copy</md-icon> Copy Link
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="deleteReg(data.item.id)">
                <md-icon class="text-info">block</md-icon> Close Registration
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(id)="data"> #{{ data.item.class_enrolled }} </template>
      </b-table>

      <i v-if="!totalData && !isLoading" class="d-flex justify-content-center py-4">No registeration initiated yet!</i>

      <div class="card-footer">
        <pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" @change="onPageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import vSelect from 'vue-select';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import Pagination from '@/components/Ui/Pagination.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { formatDateSimple, formatFullDate, formatDateTime } from '@/common/utils';

export default {
  extends: Page,
  components: { Pagination, PageHeader, vSelect },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      totalData: 0,
      initiateData: [],
      perPage: DEFAULT_PAGE_SIZE,
      isResendingAll: false,
      isResending: false,
      isCopied: false,
      classId: null,
      allClasses: [],
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    tableColumns() {
      return [
        {
          key: 'menu'
        },
        { key: 'id', label: 'Class ID' },
        { key: 'std_name', label: this.$t('studentMsgs.name') },
        { key: 'email', label: 'Email' },
        { key: 'created_at', label: 'Initiated at', formatter: value => (value ? formatDateTime(value) : value) }
      ];
    }
  },

  methods: {
    ...mapActions('student', ['allInitiateStudents', 'resendAllInitiates', 'deleteInitiate']),
    ...mapActions('classes', ['getAllClasses']),

    formatDateSimple,
    formatFullDate,

    goBack() {
      this.$router.go(-1);
    },

    onSearch() {
      this.debouncedSearch(this);
    },

    debouncedSearch: debounce(vm => {
      vm.fetchData();
    }, 500),

    async copyUrl(id) {
      try {
        const urlLink = `${this.getLoggedInUser.linked_entity.subdomain.subdomain_url}/registration?regId=${id}`;
        await navigator.clipboard.writeText(urlLink);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);

        this.makeToast({ variant: 'success', msg: 'Link copied.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async resendAll() {
      this.isResendingAll = true;
      if (this.totalData) {
        try {
          await this.resendAllInitiates({
            class_enrolled: this.classId
          });
          this.fetchData();
          this.makeToast({ variant: 'success', msg: 'Email resent to all initiated re.' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'No invites to resend' });
      }
      this.isResendingAll = false;
    },
    async resend(id) {
      this.isResending = true;
      try {
        await this.resendAllInitiates({ regId: id });
        this.fetchData();

        this.makeToast({ variant: 'success', msg: 'Resent registration email.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async deleteReg(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          'Please confirm that you want to delete the initiated registration.',
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteInitiate(id);
          this.fetchData();
          this.makeToast({ variant: 'success', msg: 'Deleted' });
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async fetchData(pageNum = 1, params = {}) {
      this.isLoading = true;
      const response = await this.allInitiateStudents({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.classId && { class_enrolled: this.classId }),
        ...(this.searchTerm && { search: this.searchTerm }),

        ...params
      });
      this.initiateData = response.data.results;
      this.currentPage = pageNum;
      this.totalData = response.data.count;
      this.isLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchData(pageNum);
    },
    async fetchClassData() {
      const resp = (await this.getAllClasses()).data;
      this.allClasses = resp;
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.classId = this.$route.params.id;
    }
    await this.fetchClassData();
    this.fetchData();
  }
};
</script>

<style></style>
