<template>
  <div :class="containerClass">
    <page-header
      title="Configure About Page Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Take control of your site's About page to accurately represent your organization.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Top Banner -->

      <page-separator>
        <b-link v-b-toggle="'top-banner-section'" class="accordion__toggle p-0">
          <span class="flex">Banner</span>
          <md-icon class="accordion__toggle-icon">
            {{ topBannerVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="top-banner-section"
        accordion="topBannerAccordionId"
        :visible="topBannerVisible"
        class="accordion__menu"
        @hide="topBannerVisible = false"
        @show="topBannerVisible = true"
      >
        <div>
          <b-form-group
            label="Banner Image"
            label-for="banner_image"
            label-class="form-label"
            class="row-align-items-center"
          >
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isImgUploading">...</span>
                <b-img
                  :src="homepageConfig.banner_img_url"
                  class="img-fluid"
                  width="40"
                  alt="Image"
                  v-else-if="homepageConfig.banner_img_url"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <image-uploader
                @image="setImage"
                :isUploading="isImgUploading"
                :isRequired="false"
                placeholder="Select Banner Image"
              />
            </b-media>
          </b-form-group>

          <b-form-group label="Title" label-for="banner-title" label-class="form-label">
            <b-form-input id="banner-title" placeholder="Enter Title" v-model="homepageConfig.title" required />
          </b-form-group>

          <b-form-group label="Description" label-for="banner-desc" label-class="form-label">
            <b-form-textarea id="banner-desc" rows="6" v-model="homepageConfig.description" required></b-form-textarea>
          </b-form-group>
        </div>
      </b-collapse>

      <!-- Features -->

      <page-separator>
        <b-link v-b-toggle="'programsAndClasses-section'" class="accordion__toggle p-0">
          <span class="flex">Features</span>
          <md-icon class="accordion__toggle-icon">
            {{ programsAndClassesVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="programsAndClasses-section"
        accordion="programsAndClassesAccordionId"
        :visible="programsAndClassesVisible"
        class="accordion__menu"
        @hide="programsAndClassesVisible = false"
        @show="programsAndClassesVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="about" v-model="aboutSection" switch> Enable this section </b-form-checkbox>
        </b-form-group>
        <div v-if="aboutSection">
          <div v-for="(feature, index) in features" :key="index">
            <page-separator :title="`Feature #${+index + 1}`" />

            <b-form-group label="Title" label-for="feature_title" label-class="form-label">
              <b-form-input id="feature_title" placeholder="Enter Feature Title" v-model="feature.name" required />
            </b-form-group>

            <b-form-group label="Description" label-for="feature_desc" label-class="form-label">
              <b-form-textarea id="feature_desc" rows="6" v-model="feature.desc" required></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </b-collapse>

      <!-- Team -->

      <page-separator>
        <b-link v-b-toggle="'team-section'" class="accordion__toggle p-0">
          <span class="flex">Team</span>
          <md-icon class="accordion__toggle-icon">
            {{ featuresVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="team-section"
        accordion="featuresAccordionId"
        :visible="featuresVisible"
        class="accordion__menu"
        @hide="featuresVisible = false"
        @show="featuresVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="team" v-model="teamSection" switch> Enable this section </b-form-checkbox>
        </b-form-group>
        <div v-if="teamSection">
          <b-form-group label="Title" label-for="team-title" label-class="form-label">
            <b-form-input id="team-title" placeholder="Team" v-model.trim="homepageConfig.team_title" />
          </b-form-group>

          <b-form-group label="Description" label-for="team_desc" label-class="form-label">
            <b-form-textarea id="team_desc" rows="6" v-model="homepageConfig.team_desc" required></b-form-textarea>
          </b-form-group>

          <div v-for="(fe, index) in $v.team.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-end">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addFeat(index)"> Add Member </a>
              <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeFeat(index)" v-if="team.length > 1">
                Remove Member
              </a>
            </div>

            <div>
              <page-separator :title="`Member #${+index + 1}`" />

              <b-form-group
                label="Image"
                label-for="image_url"
                label-class="form-label"
                class="row-align-items-center col-md-12"
              >
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="featuresFile[index].isImgUploading">...</span>
                    <b-img
                      :src="fe.image_url.$model"
                      class="img-fluid"
                      width="40"
                      alt="Image"
                      v-else-if="fe.image_url.$model"
                    />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>

                  <image-uploader
                    @image="(e) => setFeaturesImage(e, index)"
                    :isUploading="featuresFile[index].isImgUploading"
                    :isRequired="!fe.image_url.$model"
                    placeholder="Select Member Image"
                  />
                </b-media>
              </b-form-group>

              <b-form-group label="Name" label-for="member-title" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="member-title"
                  placeholder="Member"
                  v-model.trim="fe.title.$model"
                  :state="!fe.title.required && fe.$dirty ? false : null"
                />
                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Designation" label-for="designation" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="designation"
                  placeholder="Designation"
                  v-model.trim="fe.description.$model"
                  :state="!fe.description.required && fe.$dirty ? false : null"
                />

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<style lang="css" scoped>
</style>

<script>
import {
  get,
  isEmpty,
  // map
} from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    vSelect,
    PageSeparator,
    ImageUploader,
  },
  extends: Page,
  data() {
    return {
      topBannerVisible: true,
      statsVisible: true,
      expertiseVisible: true,
      featuresVisible: true,
      programsAndClassesVisible: true,
      testimonialsVisible: true,
      bottomVisible: true,
      SCHOOL_PAYMENT_PLANS,
      title: 'Configure About Page',
      isAdded: false,
      school: null,
      dragging: false,
      aboutSection: true,
      teamSection: true,
      homepageConfig: {
        title: '',
        description: '',
        banner_img_url: '',
        ctaBtn: true,
        ctaTitle: '',
        ctaLinkStatus: null,
        ctaLink: null,
        attrib_type: [],
        prog_title: '',
        prog_desc: '',
        class_title: '',
        class_desc: '',
        testimonial_title: '',
        testimonial_desc: '',
        bottom_title: '',
        bottom_desc: '',
        bottom_img_url: '',
        expertise_title: '',
        expertise_desc: '',
        stats_title: '',
        stats_desc: '',
        team_title: '',
        team_desc: '',
        bottom_short_title: '',
        ctaBottomBtn: true,
        ctaBottomTitle: '',
        ctaBottomLinkStatus: null,
        ctaBottomLink: null,
      },
      options: [
        { item: 'stats', name: 'Stats' },
        { item: 'feature', name: 'Feature' },
      ],
      linkedWebsite: null,
      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,
      isBottomImgUploading: false,

      uploadFile: { banner_image: null, bottom_img: null },
      uploadPercent: { banner_image: 0, bottom_img: 0 },
      uploadCancelTokenSource: { banner_image: null, bottom_img: 0 },
      USER_ROLES,
      USER_ROLE_TYPES,
      tags: [{ title: '' }],
      stats: [
        {
          count: 0,
          title: '',
        },
      ],
      team: [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ],
      features: [
        {
          name: 'Mission',
          desc: '',
        },
        {
          name: 'Vision',
          desc: '',
        },
        {
          name: 'Value',
          desc: '',
        },
      ],
      featuresFile: [{ image_url: null, isImgUploading: false }],
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },

      team: {
        $each: {
          description: {
            required: requiredIf(() => this.teamSection),
          },
          title: {
            required: requiredIf(() => this.teamSection),
          },
          image_url: {
            required: requiredIf(() => this.teamSection),
          },
        },
      },
      features: {
        required: requiredIf(() => this.aboutSection),
        $each: {
          name: {
            required: requiredIf(() => this.aboutSection),
          },
          desc: { required: requiredIf(() => this.aboutSection) },
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading || this.isFooterImgLoading;
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getAboutPage', 'createAboutConfig', 'getLinkedImageUploadPresignedUrl']),
    get,
    isEmpty,

    setImage(file) {
      this.uploadFile['banner_image'] = file;
      this.uploadImage('banner_image');
    },

    setFeaturesImage(file, index) {
      this.featuresFile[index].image_url = file;
      this.uploadImage('image_url', index);
    },

    addFeat(index) {
      this.team.splice(index + 1, 0, {
        title: '',
        description: '',
        image_url: '',
      });
      this.featuresFile.splice(index + 1, 0, {
        image_url: null,
        isImgUploading: false,
      });
    },

    removeFeat(index) {
      this.team.splice(index, 1);
      this.featuresFile.splice(index, 1);
    },
    resetData() {
      this.linkedWebsite = null;
      this.homepageConfig.title = '';
      this.homepageConfig.description = '';
      this.homepageConfig.banner_img_url = '';

      this.homepageConfig.team_title = '';
      this.homepageConfig.team_desc = '';

      this.aboutSection = true;
      this.teamSection = true;

      this.featuresFile = [{ image_url: null, isImgUploading: false }];
      this.team = [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ];

      this.features = [
        {
          name: 'Mission',
          desc: '',
        },
        {
          name: 'Vision',
          desc: '',
        },
        {
          name: 'Value',
          desc: '',
        },
      ];
    },
    prefillLogo(schoolId) {
      this.resetData();
      this.fetchBasicConfig(schoolId);
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchBasicConfig(school_id = null) {
      let resp;
      if (school_id) {
        resp = await this.getAboutPage({
          school_id: school_id,
        });
      } else {
        resp = await this.getAboutPage({});
      }

      this.linkedWebsite = resp.linked_website;
      this.homepageConfig.title = resp.banner_title;
      this.homepageConfig.description = resp.banner_desc;
      this.homepageConfig.banner_img_url = resp.banner_img;

      this.homepageConfig.team_title = resp.team_title;
      this.homepageConfig.team_desc = resp.team_desc;

      this.aboutSection = resp.about_section;

      this.teamSection = resp.team_section;

      this.team = get(resp, 'team.length')
        ? get(resp, 'team')
        : [
            {
              title: '',
              description: '',
              image_url: '',
            },
          ];
      if (get(resp, 'team')) {
        this.featuresFile = this.team.map(() => ({ image_url: null, isImgUploading: false }));
      }
      this.features = get(resp, 'features.length') ? get(resp, 'features') : this.features;
      // [
      //     {
      //       name: '',
      //       desc: '',
      //     },
      //   ];
    },
    async uploadImage(uploadType, index) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      if (uploadType === 'banner_image') {
        this.isImgUploading = true;
      } else if (uploadType === 'bottom_img') this.isBottomImgUploading = true;
      else this.featuresFile[index].isImgUploading = true;
      try {
        const urlResp = await this.getLinkedImageUploadPresignedUrl({
          file_name:
            uploadType === 'image_url' ? this.featuresFile[index].image_url.name : this.uploadFile[uploadType].name,
          content_type:
            uploadType === 'image_url' ? this.featuresFile[index].image_url.type : this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: uploadType === 'image_url' ? this.featuresFile[index].image_url : this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'banner_image') {
          this.homepageConfig.banner_img_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'image_url') {
          this.team[index].image_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'bottom_img') {
          this.homepageConfig.bottom_img_url = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'banner_image') this.isImgUploading = false;
      else if (uploadType === 'bottom_img') this.isBottomImgUploading = false;
      else this.featuresFile[index].isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            linked_website: this.linkedWebsite,
            banner_title: this.homepageConfig.title,
            banner_desc: this.homepageConfig.description,
            banner_img: this.homepageConfig.banner_img_url,

            about_section: this.aboutSection,
            team_section: this.teamSection,

            features: this.features || [],

            team_title: this.homepageConfig.team_title,
            team_desc: this.homepageConfig.team_desc,
            team: this.team || [],

            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
          };
          await this.createAboutConfig({
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
