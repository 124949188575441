var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.title,"breadcrumb":_vm.breadcrumb,"container-class":_vm.containerClass,"info-text":_vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.SCHOOL
        ? "Efficiently handle and review student refund requests."
        : ''}}),_c('div',{staticClass:"page-section"},[_c('div',{class:_vm.containerClass},[_c('page-separator',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"col-lg-auto"},[_c('form',{staticClass:"search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-input',{staticClass:"w-lg-auto",attrs:{"placeholder":"Search Refunds"},on:{"input":_vm.onSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-btn',{attrs:{"variant":"flush","type":"submit"}},[_c('md-icon',[_vm._v("search")])],1)],1)]),_c('div',{staticClass:"col-lg d-flex flex-wrap justify-content-end"},[_c('div',{staticClass:"col-lg-6 p-0"},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"refund","label":"text","reduce":function (filter) { return filter.value; },"placeholder":"Select Status","options":_vm.partnershipStatuses},on:{"input":function($event){return _vm.fetchRefundReqs()}},scopedSlots:_vm._u([{key:"search",fn:function(ref){
        var attributes = ref.attributes;
        var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)])])]),_c('b-table',{staticClass:"table-nowrap clickable-item",attrs:{"fields":_vm.tableColumns,"items":_vm.refundReqs,"busy":_vm.isLoading,"head-variant":"light","hover":"","responsive":"","no-local-sorting":""},on:{"sort-changed":_vm.onSortChange,"row-clicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"cell(logo)",fn:function(data){return [_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":""},slot:"aside"},[(data.item.school.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":data.item.school.logo_url,"width":"40","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1)]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$n(data.item.amount, 'currency', 'en-US'))+" ")]}},{key:"cell(review_date)",fn:function(data){return [_c('strong',[_vm._v(" "+_vm._s(data.item.review_date ? _vm.formatFullDate(data.item.review_date) : '')+" ")])]}},{key:"cell(created_at)",fn:function(data){return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatFullDate(data.item.created_at))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{class:'border border-' +
                (data.item.status === _vm.REFUND_STATUSES.APPROVED
                  ? "success text-success"
                  : data.item.status === _vm.REFUND_STATUSES.PENDING
                  ? 'warning text-warning'
                  : data.item.status === _vm.REFUND_STATUSES.DECLINED
                  ? "danger text-danger"
                  : 'black'),attrs:{"variant":"none"}},[_vm._v(_vm._s(data.item.status === _vm.REFUND_STATUSES.APPROVED ? 'Approved' : data.item.status === _vm.REFUND_STATUSES.PENDING ? 'Pending' : data.item.status === _vm.REFUND_STATUSES.CANCELLED ? 'Cancelled' : 'Declined'))])]}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(actions)",fn:function(data){return [(data.item.status === _vm.REFUND_STATUSES.PENDING)?_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":'Cancel Request'},on:{"click":function($event){$event.preventDefault();return _vm.cancelReq(data.item.id)}}},[_c('i',{staticClass:"material-icons mr-1 text-danger"},[_vm._v("block")])]):_vm._e()]}}])}),_c('div',{staticClass:"card-footer"},[_c('pagination',{attrs:{"total-rows":_vm.totalRefundReqs,"per-page":_vm.perPage,"aria-controls":"refundReqs-table"},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }