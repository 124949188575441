<template>
  <div :class="containerClass">
    <page-header
      title="Configure Gallery Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Curate and edit the images displayed on your site's gallery.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(sch) => sch.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Photo Gallery -->
      <page-separator title="Photo Gallery Upload" />

      <b-form-group label="" label-for="review" label-class="form-label">
        <input
          id="imageupinput"
          type="file"
          name="file"
          accept="image/*"
          @change="upload"
          class="d-none"
          ref="uploader"
        />

        <b-button
          class="upload-button"
          @click.prevent="$refs.uploader.click()"
          style="width: 80px"
          v-if="
            (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && school) ||
            get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
          "
        >
          <i v-if="isPictureLoading" class="fas fa-circle-notch fa-spin"></i> <span v-else>Upload</span>
        </b-button>

        <div class="d-flex flex-wrap mt-2" v-if="photoGallery.length">
          <div style="position: relative" class="mr-2 mb-2" v-for="photo of photoGallery" :key="photo.id">
            <b-img
              :src="photo.image_url"
              class="img-fluid"
              style="
                height: 13rem;
                width: 13rem;
                color: white;
                border-radius: 0.5rem;
                border: solid gray 0.1px !important;
                object-fit: cover;
                min-width: 5rem;
                cursor: pointer;
              "
              alt="Logo"
              @click.prevent="openPhotoModal(photo.image_url)"
            />

            <a
              class="clickable-item"
              @click.prevent="removeVisual(photo.id)"
              style="color: #2a2a2a; position: absolute; top: 0; right: 2px"
              ><i class="fas fa-times-circle"></i
            ></a>
          </div>
        </div>
        <b-card
          v-else-if="
            (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && school) ||
            get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
          "
          class="text-center mt-3"
        >
          <i> There isn't any images added. Please add image if you have any. </i>
        </b-card>
        <div v-if="photoGallery.length < totalCount && school" class="d-flex justify-content-center">
          <b-btn @click="loadMore">Load more</b-btn>
        </div>
      </b-form-group>
    </b-form>
    <photo-modal :show-modal="showModal" :photo="photoUrl" @close="hideModal" />
  </div>
</template>

<style lang="css" scoped>
@import './style.css';
</style>

<script>
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import PhotoModal from './PhotoModal.vue';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
export default {
  components: {
    PageHeader,
    FmvAvatar,
    PhotoModal,
    vSelect,
    PageSeparator,
  },
  extends: Page,
  data() {
    return {
      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Gallery Settings',
      showModal: false,
      dialog: false,
      photoUrl: null,
      school: null,

      photoGallery: [],
      isPictureLoading: false,

      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,

      uploadFile: { gallery: null },
      uploadPercent: { gallery: 0 },
      uploadCancelTokenSource: { gallery: null },
      USER_ROLES,
      USER_ROLE_TYPES,
      currentPage: 1,
      perPage: 28,
      totalCount: 0,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading;
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', [
      'deletePhotoGallery',
      'getAllGalleryPhotos',
      'createPhotoGallery',
      'getLinkedImageUploadPresignedUrl',
    ]),
    get,
    hideModal() {
      this.showModal = false;
    },
    openPhotoModal(photo) {
      this.showModal = true;
      this.dialog = true;
      this.photoUrl = photo;
    },
    isEmpty,

    async removeVisual(index) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected image.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deletePhotoGallery({
            image_id: index,
          });
          this.fetchGalleryConfig({ pageNum: 1, limit: this.currentPage * this.perPage });
          setTimeout(() => {
            this.photoGallery = this.photoGallery?.filter((photo) => !(photo.id === index));
            this.makeToast({ variant: 'success', msg: 'Image successfully deleted!' });
          }, [1000]);
        }
      } catch (e) {
        // TODO: handle error
      }
    },
    upload(e) {
      const target = e.target;
      if (!target.files || !target.files.length) return;
      this.uploadFile['gallery'] = target.files[0];
      this.uploadImage('gallery', target.files[0]);
    },

    prefillLogo(school_id) {
      this.photoGallery = [];
      this.fetchGalleryConfig({ school_id });
    },

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    loadMore() {
      this.fetchGalleryConfig({ pageNum: this.currentPage + 1 });
    },
    async fetchGalleryConfig({ pageNum = 1, limit = null }) {
      const resp = await this.getAllGalleryPhotos({
        ...(this.school && { school_id: this.school }),

        limit: limit || this.perPage,
        offset: (pageNum - 1) * this.perPage,
      });

      if (!limit) {
        this.currentPage = pageNum;
        this.photoGallery = this.photoGallery.concat(resp.results.images);
      } else {
        this.photoGallery = resp.results.images;
      }
      this.totalCount = resp.count;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isPictureLoading = uploadType === 'gallery';

      try {
        const urlResp = await this.getLinkedImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'gallery',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'gallery') {
          this.isLoading = true;

          this.$v.$touch();
          if (!this.$v.$invalid) {
            try {
              await this.createPhotoGallery({
                school_id:
                  get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                    ? this.school
                    : this.getLoggedInUser.linked_entity.id,
                images: [urlResp.upload_url.split('?')[0]],
              });

              // this.photoGallery.splice(0, 0, res.data.data[0]);
              this.fetchGalleryConfig({ pageNum: 1, limit: this.currentPage * this.perPage });
            } catch (e) {
              // TODO: handle error
            }
          }
          this.isLoading = false;
        }
      } catch (error) {
        // TODO handle if needed
      }
      this.uploadFile[uploadType] = null;

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'gallery') this.isPictureLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    } else if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }

    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchGalleryConfig({});
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
