<template>
  <b-modal ref="classSchModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group label="Start Date - End Date" label-for="Date" label-class="form-label" v-if="!classSch">
        <date-picker
          v-model="dateSch"
          format="DD MMMM, YYYY"
          valueType="date"
          style="width: 100%"
          id="date"
          lang="en"
          placeholder="Select Start Date - End Date Range"
          :state="!$v.dateSch.required && $v.dateSch.$dirty ? false : null"
          :input-class="`mx-input ${!$v.dateSch.required && $v.dateSch.$dirty ? 'form-control is-invalid' : ''}`"
          :disabled="isLoading"
          range
          :disabled-date="disableSpecificDates"
        ></date-picker>
        <b-form-text>
          <b>Note:</b> Dates that have already been added in the schedule or those that are out of class' date range
          cannot be selected.
        </b-form-text>
        <b-form-invalid-feedback :state="!$v.dateSch.required && $v.dateSch.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Date" label-for="date" label-class="form-label" v-else>
        <date-picker
          v-model="classSchData.date"
          format="DD MMMM, YYYY"
          valueType="date"
          style="width: 100%"
          id="date"
          lang="en"
          placeholder="Select Class Date"
          :state="!$v.classSchData.date.required && $v.classSchData.date.$dirty ? false : null"
          :input-class="`mx-input ${
            !$v.classSchData.date.required && $v.classSchData.date.$dirty ? 'form-control is-invalid' : ''
          }`"
          :disabled="isLoading"
          :disabled-date="disableSpecificDates"
        ></date-picker>
        <b-form-text>
          <b>Note:</b> Dates that have already been added in the schedule or those that are out of class' date range
          cannot be selected.
        </b-form-text>
        <b-form-invalid-feedback :state="!$v.classSchData.date.required && $v.classSchData.date.$dirty ? false : null">
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Days to Exclude" label-for="day" label-class="form-label" v-if="!this.classSch">
        <v-select
          id="day"
          class="form-control v-select-custom"
          label="text"
          style="width: 100%"
          v-model="day"
          :reduce="(item) => item.value"
          :options="dayOptions"
          multiple
          placeholder="Select days to exclude from schedule"
        >
        </v-select>
      </b-form-group>
      <b-form-group label="Type" label-for="certified_before" label-class="form-label" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="certified_before"
          v-model="classSchData.is_self_paced"
          :options="typeOptions"
          :aria-describedby="ariaDescribedby"
          @input="changeType"
        ></b-form-radio-group>
      </b-form-group>
      <div class="row" v-if="!classSchData.is_self_paced">
        <b-form-group label="Start Time" label-for="Start Time" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="classSchData.start_time"
            lang="en"
            type="time"
            value-type="HH:mm"
            format="hh:mm a"
            :minute-step="15"
            style="width: 100%"
            :placeholder="'Select Starting Time'"
            :input-class="`mx-input ${
              !$v.classSchData.start_time.required && $v.classSchData.start_time.$dirty ? 'form-control is-invalid' : ''
            }`"
          ></date-picker>
          <b-form-invalid-feedback
            :state="!$v.classSchData.start_time.required && $v.classSchData.start_time.$dirty ? false : null"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="End Time" label-for="End Time" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="classSchData.end_time"
            type="time"
            lang="en"
            value-type="HH:mm"
            format="hh:mm a"
            :minute-step="15"
            style="width: 100%"
            :placeholder="'Select Ending Time'"
            :input-class="`mx-input ${
              (!$v.classSchData.end_time.required || !$v.classSchData.end_time.afterStartTime) &&
              $v.classSchData.end_time.$dirty
                ? 'form-control is-invalid'
                : ''
            }`"
          ></date-picker>
          <b-form-invalid-feedback
            :state="!$v.classSchData.end_time.required && $v.classSchData.end_time.$dirty ? false : null"
          >
            This field is required.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            :state="!$v.classSchData.end_time.afterStartTime && $v.classSchData.end_time.$dirty ? false : null"
          >
            End time must be after start time.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-btn
        variant="primary"
        style="width: 200px"
        type="submit"
        class="btn-normal d-flex ml-auto"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else> {{ classSch ? 'Update' : 'Add' }} Schedule</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';
import { requiredIf } from 'vuelidate/lib/validators';
//import { mapActions } from 'vuex';

import { formatToAPIDate } from '../../../common/utils';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
export default {
  name: 'ClassSchModal',

  components: { DatePicker, vSelect },

  props: {
    showModal: { type: Boolean, default: false },
    classSch: { type: Object || null },
    classSchIndex: { type: Number || null },
    classSchArr: { type: Array || [] },
    startDate: { type: String || null },
    endDate: { type: String || null },
  },

  data() {
    return {
      dateSch: '',
      schArr: [],
      day: [],
      type: '',
      dayOptions: [
        { text: 'Sunday', value: 0 },
        { text: 'Monday', value: 1 },
        { text: 'Tuesday', value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 },
        { text: 'Saturday', value: 6 },
      ],
      classSchData: {
        date: '',
        start_time: '',
        end_time: '',
        is_self_paced: false,
      },

      modalTitle: '',
      maxDateAllowed: undefined,
      typeOptions: [
        { value: false, text: 'Scheduled' },
        { value: true, text: 'Self-Paced' },
      ],
      isLoading: false,
    };
  },

  validations() {
    return {
      dateSch: { required: requiredIf(() => !this.classSch) },
      classSchData: {
        date: { required: requiredIf(() => this.classSch) },
        start_time: { required: requiredIf(() => !get(this.classSchData, 'is_self_paced')) },
        end_time: {
          required: requiredIf(() => !get(this.classSchData, 'is_self_paced')),
          afterStartTime: (value) =>
            !this.classSchData.start_time ||
            !value ||
            moment(value, 'HH:mm').isAfter(moment(this.classSchData.start_time, 'HH:mm')),
        },
      },
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = this.classSch ? 'Edit Class Schedule' : 'Add Custom Schedule';
        this.maxDateAllowed = new Date();
        this.schArr = [];
        if (this.classSch) {
          this.classSchData.date = moment(this.classSch.date, 'YYYY-MM-DD').toDate();
          this.classSchData.start_time = this.classSch.start_time;
          this.classSchData.end_time = this.classSch.end_time;
          this.classSchData.is_self_paced = this.classSch.is_self_paced;
        } else {
          this.dateSch = null;
          this.day = [];
          this.classSchData = {
            date: '',
            start_time: '',
            end_time: '',
            is_self_paced: false,
          };
        }
        this.$v.$reset();
        this.$refs.classSchModal.show();
      }
    },
  },

  methods: {
    get,
    disableSpecificDates(date) {
      return (
        moment(date).isBefore(this.startDate) ||
        moment(date).isAfter(moment(this.endDate)) ||
        (this.classSchArr.includes(formatToAPIDate(date)) &&
          (!this.classSch || formatToAPIDate(date) !== this.classSch.date))
      );
    },
    changeType(value) {
      if (value) {
        this.classSchData.start_time = '';
        this.classSchData.end_time = '';
      }
    },
    populateSchedules() {
      if (this.classSch) return;
      let currentDate = moment(this.dateSch[0]);
      const endDate = moment(this.dateSch[1]);
      while (currentDate <= endDate) {
        if (!this.day.includes(moment(currentDate).day()) && !this.classSchArr.includes(formatToAPIDate(currentDate))) {
          this.schArr.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            ...(!this.classSchData.is_self_paced && {
              start_time: this.classSchData.start_time,
              end_time: this.classSchData.end_time,
            }),
            is_self_paced: this.classSchData.is_self_paced,
          });
        }

        currentDate = moment(currentDate).add(1, 'days');
      }
    },
    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.classSchModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.classSchModal.hide();
      this.hideModal();
    },

    onSave() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.populateSchedules();
        this.hideUpdateModal({
          classSch: this.classSch
            ? {
                date: moment(this.classSchData.date).format('YYYY-MM-DD'),
                ...(!this.classSchData.is_self_paced && {
                  start_time: this.classSchData.start_time,
                  end_time: this.classSchData.end_time,
                }),
                is_self_paced: this.classSchData.is_self_paced,
              }
            : this.schArr,
          index: this.classSchIndex,
          isUpdate: !!this.classSch,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
