<template>
  <div :class="containerClass">
    <page-header :title="'Manage Program FAQs'" :breadcrumb="breadcrumb" :container-class="null" class="mb-16pt" />

    <div class="col-md-12 px-0 page-section pt-0 mb-lg-12pt">
      <page-separator title="" />

      <div class="d-flex justify-content-end mb-3">
        <router-link
          :to="{ name: 'add-prg-faq', params: { programId: this.$route.params.programId } }"
          class="btn btn-primary"
        >
          Add FAQ
        </router-link>
      </div>

      <b-skeleton-wrapper :loading="areFaqsLoading">
        <template #loading>
          <div v-for="item in [1, 2, 3]" :key="item">
            <div class="card card-lg mb-2">
              <div class="card-body d-flex flex-column">
                <div class="d-flex">
                  <i class="fas fa-bars icon-16pt mr-12pt"></i>
                  <b-skeleton class="w-100"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row" v-if="allFaqs.length">
          <draggable class="col-sm-12 draggable-cursor" @start="dragging = true" @end="dargEnd" :list="allFaqs">
            <div v-for="faq in allFaqs" :key="faq.id">
              <div class="card card-lg mb-2">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="icon-align d-flex align-items-center">
                      <div class="mr-12pt">
                        <i class="fas fa-bars icon-16pt"></i>
                      </div>
                      <span>{{ faq.question }}</span>
                    </div>

                    <div class="ml-1" style="min-width: 50px">
                      <router-link
                        :to="{ name: 'edit-prg-faq', params: { id: faq.id, programId: $route.params.programId } }"
                        v-b-popover.hover.left
                        :title="'Edit'"
                        ><i class="material-icons icon-18pt">edit</i></router-link
                      >
                      <a href="#" @click.prevent="removeFaq(faq.id)" v-b-popover.hover.right :title="'Remove'">
                        <i class="material-icons icon-18pt">close</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>

        <div v-else>
          <i>There aren't any FAQs added for this program.</i>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { map } from 'lodash';
import Page from '@/components/Page.vue';
import draggable from 'vuedraggable';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  extends: Page,
  components: {
    draggable,
    PageSeparator,
    PageHeader,
  },
  data() {
    return {
      title: 'Manage Program FAQs',

      allFaqs: [],
      dragging: false,
      areFaqsLoading: false,
    };
  },
  validations() {
    return {};
  },
  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'manage-programs' } },
        ...(this.$route.params.name ? [{ text: this.$route.params.name, active: true }] : []),
        { text: 'Manage FAQs', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('program', ['getAllProgramFaqs', 'deleteProgramFaq', 'updatePrgFaqsOrder']),
    dragOver() {
      document.documentElement.style.cursor = '-webkit-grabbing';
    },

    async fetchFaqs() {
      this.areFaqsLoading = true;
      try {
        const resp = await this.getAllProgramFaqs({
          program: this.$route.params.programId,
        });
        this.allFaqs = resp.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areFaqsLoading = false;
    },

    addFaq() {
      this.$router.push({ name: 'add-prg-faq', params: { programId: this.$route.params.programId } });
    },

    async dargEnd() {
      this.dragging = false;
      try {
        const data = {
          program_faqs: map(this.allFaqs, (faq, index) => ({
            id: faq.id,
            order_num: index + 1,
          })),
        };
        await this.updatePrgFaqsOrder({
          data,
        });
        this.makeToast({ variant: 'success', msg: 'Order Updated!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async removeFaq(id) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to remove the selected faq for current section.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.deleteProgramFaq(id);
        this.fetchFaqs();
        this.makeToast({ variant: 'success', msg: 'FAQ Deleted!' });
      }
    },
  },
  async mounted() {
    this.fetchFaqs();
  },
};
</script>
