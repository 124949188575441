<template>
  <div>
    <b-modal ref="removeLocationModal" hide-footer title="Delete Location" centered lazy @hide="hideModal" size="md">
      <span style="font-size: 15px">Are you sure you want to continue?</span>
      <p class="text-black-70 mt-2">
        Note : A location cannot be removed until a replacement is selected for all classes that contain the location
        you wish to delete.
      </p>
      <b-form-group>
        <v-select
          :options="allLocations"
          class="form-control v-select-custom"
          v-model="selectedLocation"
          :reduce="(lc) => lc.id"
          placeholder="Enter Location"
          :loading="isLoading"
          :filter-by="filterLocations"
        >
          <template slot="option" slot-scope="option">
            {{ get(option, 'address') }}, {{ get(option, 'city') }},

            {{ get(option, 'state') }}
            {{ get(option, 'zipcode') }},
            {{ get(option, 'country') }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.address }}
            {{ get(option, 'address') }}, {{ get(option, 'city') }},

            {{ get(option, 'state') }}
            {{ get(option, 'zipcode') }},
            {{ get(option, 'country') }}
          </template>
        </v-select>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-btn
          variant="primary"
          :disabled="!selectedLocation"
          style="width: 100px"
          @click.prevent="replaceLocation"
          class="btn-normal mr-2"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>continue</span>
        </b-btn>
        <b-btn variant="danger" style="width: 100px" @click.prevent="hideModal" class="btn-normal"> Cancel </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import vSelect from 'vue-select';
export default {
  props: {
    id: { type: Number, default: null },
    showModal: { type: Boolean, default: false },
  },
  name: 'RemoveLocationModal',
  components: { vSelect },
  data() {
    return {
      isLoading: false,
      selectedLocation: null,
      allLocations: [],
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.removeLocationModal.show();
        this.fetchLocations();
      }
    },
  },
  methods: {
    ...mapActions('location', ['replaceDeleteLocation', 'getAllLocations']),
    get,
    hideModal() {
      this.selectedLocation = null;
      this.$refs.removeLocationModal.hide();
      this.$emit('close');
    },
    hideModalManual() {
      this.hideModal();
    },
    async replaceLocation() {
      this.isLoading = true;
      try {
        const data = {
          update_id: this.selectedLocation,
          delete_id: this.id,
        };
        await this.replaceDeleteLocation(data);
        setTimeout(() => this.makeToast({ variant: 'success', msg: 'Location deleted and replaced successfully!' }));
        this.hideModal();
      } catch (e) {
        this.makeToast('danger', this.$t('genErrorMsg'));
      }
      this.isLoading = false;
    },
    filterLocations(option, label, search) {
      let searchLower = search.toLowerCase();

      return (
        get(option, 'address', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'city', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'state', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'zipcode', '').toLowerCase().indexOf(searchLower) > -1 ||
        get(option, 'country', '').toLowerCase().indexOf(searchLower) > -1
      );
    },
    async fetchLocations() {
      this.isLoading = true;
      try {
        const response = await this.getAllLocations({});
        this.allLocations = response.data.filter((e) => e.id !== this.id);
      } catch (e) {
        this.makeToast('danger', this.$t('genErrorMsg'));
      }

      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>