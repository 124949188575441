<template>
  <div>
    <page-header
      :title="title"
      :breadcrumb="breadcrumb"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Efficiently handle and review student refund requests.`
          : ''
      "
    />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search Refunds" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 p-0">
                  <v-select
                    id="refund"
                    class="form-control v-select-custom"
                    label="text"
                    v-model="filter"
                    :reduce="(filter) => filter.value"
                    placeholder="Select Status"
                    :options="partnershipStatuses"
                    @input="fetchRefundReqs()"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="refundReqs"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap clickable-item"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="onRowClick"
          >
            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img
                  :src="data.item.school.logo_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.school.logo_url"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(amount)="data"> {{ $n(data.item.amount, 'currency', 'en-US') }} </template>

            <template #cell(review_date)="data">
              <strong> {{ data.item.review_date ? formatFullDate(data.item.review_date) : '' }} </strong>
            </template>

            <template #cell(created_at)="data">
              <strong> {{ formatFullDate(data.item.created_at) }} </strong>
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.status === REFUND_STATUSES.APPROVED
                    ? `success text-success`
                    : data.item.status === REFUND_STATUSES.PENDING
                    ? 'warning text-warning'
                    : data.item.status === REFUND_STATUSES.DECLINED
                    ? `danger text-danger`
                    : 'black')
                "
                variant="none"
                >{{
                  data.item.status === REFUND_STATUSES.APPROVED
                    ? 'Approved'
                    : data.item.status === REFUND_STATUSES.PENDING
                    ? 'Pending'
                    : data.item.status === REFUND_STATUSES.CANCELLED
                    ? 'Cancelled'
                    : 'Declined'
                }}</b-badge
              >
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                v-if="data.item.status === REFUND_STATUSES.PENDING"
                v-b-popover.hover.right
                :title="'Cancel Request'"
                @click.prevent="cancelReq(data.item.id)"
              >
                <i class="material-icons mr-1 text-danger">block</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalRefundReqs"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="refundReqs-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, debounce } from 'lodash';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate } from '@/common/utils';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, REFUND_STATUSES, USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import vSelect from 'vue-select';
export default {
  components: { PageHeader, PageSeparator, Pagination, FmvAvatar, vSelect },
  extends: Page,

  data() {
    return {
      title: 'Refund Requests',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalRefundReqs: 0,
      refundReqs: [],
      searchTerm: '',
      filter: '',
      REFUND_STATUSES,
      partnershipStatuses: [
        { text: 'Approved', value: 'approved' },
        { text: 'Pending', value: 'pending' },
        { text: 'Declined', value: 'declined' },
        { text: 'Cancelled', value: 'cancelled' },
      ],
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Refund Requests', active: true },
      ];
    },

    tableColumns() {
      return [
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
          ? [
              { key: 'logo', label: '' },
              { key: 'school.name', label: 'School' },
            ]
          : []),
        { key: 'student_name', label: 'Student' },
        { key: 'amount', label: 'Amount' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'Requested On' },
        { key: 'review_date', label: 'Reviewed On' },
        ...(get(this.getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN ? [{ key: 'actions', label: '' }] : []),
      ];
    },
  },

  methods: {
    ...mapActions('refunds', ['getAllRefundsReqs', 'cancelRefundReq']),
    get,
    formatFullDate,
    async cancelReq(id) {
      try {
        await this.cancelRefundReq(id);
        this.fetchRefundReqs();
      } catch (error) {
        //
      }
    },
    async fetchRefundReqs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllRefundsReqs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.filter && { status: this.filter }),
        ...params,
      });

      this.refundReqs = response.data.results;
      this.currentPage = pageNum;
      this.totalRefundReqs = response.data.count;

      this.isLoading = false;
    },
    onRowClick(item) {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
        this.$router.push({
          name: 'sa-refund-details',
          params: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: 'refund-details',
          params: {
            id: item.id,
          },
        });
      }
    },
    onPageChange(pageNum) {
      this.fetchRefundReqs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchRefundReqs();
    },

    onSearch() {
      this.debouncedRefundReqs(this);
    },

    debouncedRefundReqs: debounce((vm) => {
      vm.fetchRefundReqs();
    }, 500),
  },

  async mounted() {
    await this.fetchRefundReqs();
  },
};
</script>
