<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Instructor' : 'Add New Instructor'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="instructor.image" class="img-fluid" width="40" alt="Image" v-else-if="instructor.image" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'image')"
            :isUploading="isImgUploading"
            :isRequired="!instructor.image"
            placeholder="Select Image"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group label="First Name" label-for="firstName" label-class="form-label" class="col-md-6">
          <b-form-input
            id="firstName"
            placeholder="Enter First Name"
            v-model="instructor.firstName"
            :state="!$v.instructor.firstName.required && $v.instructor.firstName.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Last Name" label-for="lastName" label-class="form-label" class="col-md-6">
          <b-form-input
            id="firstName"
            placeholder="Enter Last Name"
            v-model="instructor.lastName"
            :state="!$v.instructor.lastName.required && $v.instructor.lastName.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="instructor.phone"
          v-mask="'(###) ### - ####'"
          :state="errorStates.phone"
          required
        />
        <b-form-invalid-feedback>
          Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="'Email'" label-for="email" label-class="form-label">
        <b-form-input
          id="name"
          :placeholder="'Email'"
          v-model="instructor.email"
          :state="(!$v.instructor.email.required || !$v.instructor.email.email) && $v.instructor.$dirty ? false : null"
        />

        <b-form-invalid-feedback v-if="!$v.instructor.email.email">
          Invalid email. Please make sure that it's in correct format i.e. test@example.com.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="instructor.bio"
          placeholder="Enter Bio."
        />
        <b-form-invalid-feedback :state="!$v.instructor.bio.required && $v.instructor.$dirty ? false : null">
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <page-separator title="Instructor's Attachments " />
      <b-form-group label="Attachment 1" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center" v-if="!instructor.attachment_1">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isAttach1Loading">...</span>
            <b-img
              :src="instructor.attachment_1"
              class="img-fluid"
              width="40"
              alt="Attach1"
              v-else-if="instructor.attachment_1"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="attach1"
            placeholder="Select Attachment"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.attachment_1"
            @input="uploadImage('attachment_1')"
            :disabled="isAttach1Loading"
            accept="image/*"
          />
        </b-media>
        <span v-else>
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: instructor.attachment_1,
                    removeTimestamp: true
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(instructor.attachment_1, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_1')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="Attachment 2" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center" v-if="!instructor.attachment_2">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isAttach2Loading">...</span>
            <b-img
              :src="instructor.attachment_2"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="instructor.attachment_2"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="attach2"
            placeholder="Select Attachment "
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.attachment_2"
            @input="uploadImage('attachment_2')"
            :disabled="isAttach2Loading"
            accept="image/*"
          />
        </b-media>
        <span v-else>
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: instructor.attachment_2,
                    removeTimestamp: true
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(instructor.attachment_2, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_2')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="Attachment 3" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center" v-if="!instructor.attachment_3">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isAttach3Loading">...</span>
            <b-img
              :src="instructor.attachment_3"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="instructor.attachment_3"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="attach3"
            placeholder="Select Attachment"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.attachment_3"
            @input="uploadImage('attachment_3')"
            :disabled="isAttach3Loading"
            accept="image/*"
          />
        </b-media>
        <span v-else>
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: instructor.attachment_3,
                    removeTimestamp: true
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(instructor.attachment_3, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_3')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="Attachment 4" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center" v-if="!instructor.attachment_4">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isAttach4Loading">...</span>
            <b-img
              :src="instructor.attachment_4"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="instructor.attachment_4"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="attach4"
            placeholder="Select Attachment"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.attachment_4"
            @input="uploadImage('attachment_4')"
            :disabled="isAttach4Loading"
            accept="image/*"
          />
        </b-media>
        <span v-else>
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: instructor.attachment_4,
                    removeTimestamp: true
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(instructor.attachment_4, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_4')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <!-- Owner Details -->

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Editor from '@tinymce/tinymce-vue';
import { FmvAvatar } from 'fmv-avatar';
import { get } from 'lodash';
import Page from '../../../components/Page.vue';
import { USER_ROLES } from '../../../common/constants';
import { parseFileNameFromUrl } from '../../../common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { required, email } from 'vuelidate/lib/validators';
import 'vue-swatches/dist/vue-swatches.css';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    Editor,
    FmvAvatar,
    PageSeparator,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Instructor' : 'Add Instructor',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      instructor: {
        image: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        bio: '',
        attachment_1: '',
        attachment_2: '',
        attachment_3: '',
        attachment_4: ''
      },
      errorStates: {
        phone: null
      },
      isLoading: false,
      isImgUploading: false,
      isAttach1Loading: false,
      isAttach2Loading: false,
      isAttach3Loading: false,
      isAttach4Loading: false,
      uploadFile: { image: null, attachment_1: null, attachment_2: null, attachment_3: null, attachment_4: null },
      uploadPercent: { image: 0, attachment_1: 0, attachment_2: 0, attachment_3: 0, attachment_4: 0 },
      uploadCancelTokenSource: {
        image: null,
        attachment_1: null,
        attachment_2: null,
        attachment_3: null,
        attachment_4: null
      },
      USER_ROLES
    };
  },
  validations() {
    return {
      instructor: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: { required },
        bio: {}
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Instructor',
          to: {
            name: 'instructor-management-list'
          }
        },
        {
          text: this.$route.params.id ? 'Edit Instructor' : 'Add Instructor',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isImgUploading;
    }
  },

  methods: {
    ...mapActions('instructor', [
      'getInstructorImageUploadPresignedUrl',
      'getInstructor',
      'createInstructor',
      'updateInstructor'
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    parseFileNameFromUrl,
    removeAttach(fileType) {
      this.uploadFile[fileType] = null;
      this.instructor[fileType] = null;
    },
    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left'
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left'
      });
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';
      this.isAttach1Loading = uploadType === 'attachment_1';
      this.isAttach2Loading = uploadType === 'attachment_2';
      this.isAttach3Loading = uploadType === 'attachment_3';
      this.isAttach4Loading = uploadType === 'attachment_4';
      try {
        const urlResp = await this.getInstructorImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images'
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'image') {
          this.instructor.image = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'attachment_1') {
          this.instructor.attachment_1 = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'attachment_2') {
          this.instructor.attachment_2 = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'attachment_3') {
          this.instructor.attachment_3 = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'attachment_4') {
          this.instructor.attachment_4 = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
      else if (uploadType === 'attachment_1') {
        this.isAttach1Loading = false;
      } else if (uploadType === 'attachment_2') {
        this.isAttach2Loading = false;
      } else if (uploadType === 'attachment_3') {
        this.isAttach3Loading = false;
      } else if (uploadType === 'attachment_4') {
        this.isAttach4Loading = false;
      }
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            first_name: this.instructor.firstName,
            last_name: this.instructor.lastName,
            email: this.instructor.email,
            phone: this.instructor.phone,
            photo: this.instructor.image,
            bio: this.instructor.bio,
            school: this.getLoggedInUser.linked_entity.id,
            attachment_1: this.instructor.attachment_1,
            attachment_2: this.instructor.attachment_2,
            attachment_3: this.instructor.attachment_3,
            attachment_4: this.instructor.attachment_4
          };
          if (this.$route.params.id) {
            await this.updateInstructor({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: this.$t('Instructor Updated!') });
          } else {
            await this.createInstructor({
              ...data
            });
            this.makeToast({ variant: 'success', msg: 'Instructor Added!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: 'instructor-management-list'
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }

    try {
      if (this.$route.params.id) {
        const resp = (await this.getInstructor(this.$route.params.id)).data;

        this.instructor.firstName = resp.first_name;
        this.instructor.lastName = resp.last_name;
        this.instructor.email = resp.email;
        this.instructor.phone = resp.phone;
        this.instructor.image = resp.photo;
        this.instructor.bio = resp.bio;
        this.instructor.attachment_1 = resp.attachment_1;
        this.instructor.attachment_2 = resp.attachment_2;
        this.instructor.attachment_3 = resp.attachment_3;
        this.instructor.attachment_4 = resp.attachment_4;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'instructor-management-list'
      });
    }

    this.isLoading = false;
  }
};
</script>
